import { bootEntryFormContainer } from '@/modules/entry-form/EntryFormProvider';
import { bootFirebase } from '@/domain/services/Rt/Firebase';
import { mountVueComponent } from '@/lib/mount-vue-component';
import { useSubmittableTokens } from '@/domain/services/Rt/SubmittableTokens';

export default async function () {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { Submittable } = bootEntryFormContainer();
	try {
		useSubmittableTokens.reset();
		await bootFirebase(useSubmittableTokens.data, () => Submittable.isCollaborative() !== true);

		Submittable.toggleCollaborative(true);
	} catch (error) {
		Submittable.toggleCollaborative(false);
		console.error(error);
	}

	import('./EntryForm').then((entryForm) => {
		mountVueComponent('entry-form', entryForm);

		import('@/lib/components/Collaboration/CollaboratorsListModal.vue').then((collaboratorsListModal) => {
			mountVueComponent('collaborators-list-modal', collaboratorsListModal);
		});

		import('@/lib/components/Collaboration/CollaboratorsInviteModal.vue').then((collaboratorsInviteModal) => {
			mountVueComponent('collaborators-invite-modal', collaboratorsInviteModal);
		});

		import('./Configuration/ConfigurationModeSwitch').then((configurationModeSwitch) => {
			mountVueComponent('configuration-mode-switch', configurationModeSwitch);
		});

		import('./Configuration/ConfigurationModeBreadcrumbs').then((configurationModeBreadcrumbs) => {
			mountVueComponent('configuration-mode-breadcrumbs', configurationModeBreadcrumbs);
		});

		import('@/modules/entry-form/Countdown').then((countdown) => {
			mountVueComponent('countdown', countdown);
		});
	});
}
