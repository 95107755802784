
import { defineComponent } from 'vue';
import { userFaceController, Props, View } from '@/lib/components/User/UserFace.controller';
import { useController } from '@/domain/services/Composer';
import { UserFaceSize } from '@/lib/components/User/UserFace.types';

export default defineComponent<Props, View>({
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
		color: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: UserFaceSize.MEDIUM,
		},
		text: {
			type: String,
			default: null,
		},
	},

	setup: useController(userFaceController, 'userFaceController'),
});
