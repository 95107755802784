import { bootManageCollaboratorsContainer } from '@/lib/components/ListActions/ManageCollaboratorsProvider';
import GrantList from './components/GrantList';
import store from '@/lib/store';
import Vue from 'vue';

export default function () {
	bootManageCollaboratorsContainer();

	const grantList = new Vue({
		el: '#grant-list',
		name: 'GrantApp',
		components: {
			'grant-list': GrantList,
		},
		store,
	});
	$(document).one('pjax:end', () => {
		grantList.$destroy();
	});
}
