import { isFunction } from '@/domain/utils/TypePredicates';
import { User } from '@/domain/models/User';
import { belongsToUser, isExpired, isLock, Lock } from '@/domain/services/Collaboration/LockableLogic';

type LockableData = {
	value: unknown;
	lock: Lock;
};

const mutableDatSourceMapper = {
	extractData: (lockable: LockableData | null) => lockable?.value || null,
	prepareData: (lockable: LockableData, value: LockableData['value']) => ({ ...lockable, value }),
	rawProps: ['lock'] as (keyof LockableData)[],
};

/**
 * Transaction is used on every value write to firebase
 * it checks if record is locked and if so ignores write
 * value otherwise
 *
 * It accepts raw value or nested transaction function
 *   the latter is used to support composite values
 *   with separately mutable parts:

	type Record = {
		propA: string;
		propB: number;
	};

	const setPropA = (value: Record['propA']) =>
 		mutableService.set((data: Record) => ({...data, propA: value}));

	const setPropB = (value: Record['propB']) =>
 		mutableService.set((data: Record) => ({...data, propB: value}));

 *   look at useCategoryService to get more insight
 */
const transactionForUser =
	<T>(consumer: User) =>
	(valueOrTransaction: T) =>
	(data: LockableData | undefined) => {
		const newData = isFunction<(d: unknown) => unknown>(valueOrTransaction)
			? valueOrTransaction(data?.value)
			: valueOrTransaction;

		if (data === undefined || data === null || !isLock(data?.lock)) {
			return newData;
		}

		if (belongsToUser(consumer)(data.lock)) {
			return newData;
		}

		if (isExpired(data.lock)) {
			return newData;
		}

		return data;
	};

export { transactionForUser, LockableData, mutableDatSourceMapper };
