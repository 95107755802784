<template>
	<div>
		<search-field
			v-if="field.autocomplete"
			:key="`sf-${getId()}-${dKey}`"
			:id="getId()"
			ref="searchField"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			:initial-items="field.options"
			:initial-id="searchFieldId"
			:initial-value="searchFieldValue"
			value-property="text"
			:disabled="disabledOrCollaborationLocked"
			@selected="updateSearchValue"
			@focus="onFocus"
			@blur="onBlur"
		/>
		<select-field
			v-else
			:id="getId()"
			ref="optionSelector"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			:items="field.options"
			:value="getValue"
			:empty-option="emptyOption"
			value-property="text"
			:disabled="disabledOrCollaborationLocked"
			:required="field.required"
			@selected="updateSelectValue"
			@focus="onFocus"
			@blur="onBlur"
		/>
	</div>
</template>

<script>
import Field from './Field.vue';
import { SearchField, SelectField } from 'vue-bootstrap';

export default {
	components: {
		SearchField,
		SelectField,
	},
	extends: Field,
	props: {
		emptyOption: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		getValue() {
			if (this.value) {
				return this.value;
			}

			if (!this.emptyOption && this.field.options[0]['id']) {
				return this.field.options[0]['id'];
			}

			return null;
		},
		searchFieldId() {
			if (this.value) {
				return this.value;
			}

			return null;
		},
		searchFieldValue() {
			if (this.value) {
				const option = this.field.options.find(
					// eslint-disable-next-line eqeqeq
					(option) => option.id == this.value
				);
				return option ? option.text : null;
			}

			return null;
		},
	},
	methods: {
		updateSearchValue(item) {
			this.onInput(item.id);
			this.unlockMe();
		},
		updateSelectValue(name, selected) {
			this.onInput(selected);
			this.unlockMe();
		},
		unlockMe() {
			/**
			 * Because of updateSearchValue and updateSelectValue
			 * are also called on component mount we need to ignore the first call
			 * to only unlock the field after the user has interacted with it.
			 *
			 * The below code is to avoid the first call sending unlock to firestore.
			 * ( it changes unlockMe implementation )
 			 */
			this.unlockMe = () => this.fieldService.locks.set(false);
		},
		onBlur() {
			this.fieldService.locks.set(false);
		},
		onFocus() {
			this.fieldService.locks.set(true);
		},
	},
};
</script>
