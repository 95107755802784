import videoPlayerErrorHandler from '@/domain/services/VideoPlayer/VideoPlayerErrorHandler';
import { VideoPlayerOptions } from '@/domain/services/VideoPlayer/VideoPlayerTypes';
import { addVideoQualitySelector, instantiatePlayer, Player } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import { onStart, onStop } from '@/domain/services/VideoPlayer/VideoPlayerTracker';

const init = (videoId: string, options: VideoPlayerOptions): Player =>
	addVideoQualitySelector(
		instantiatePlayer(videoId, options, function (this: Player) {
			const error = this.error();

			if (error) {
				videoPlayerErrorHandler(this, error as MediaError);

				return;
			}

			this.on('play', () => onStart(this));

			this.on('pause', () => onStop(this));

			this.on('dispose', () => onStop(this));

			this.on('error', () => {
				const error = this.error();

				if (error) {
					videoPlayerErrorHandler(this, error as MediaError);
				}

				onStop(this);
			});
		})
	);

export default init;
