<template>
  <div class="form-group">
    <div
      v-for="radioValue in radioValues"
      :key="id + '-' + radioValue"
      class="radio styled"
    >
      <input
        :id="id + '-' + radioValue"
        :name="name"
        type="radio"
        :checked="value === radioValue"
        :value="radioValue"
        @change="e => $emit('input', radioValue)"
      />
      <label :for="id + '-' + radioValue">
        {{ labels[radioValue] }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    radioValues: {
      type: Array,
      required: true
    },
    labels: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
