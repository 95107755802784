import { createDao } from '@/domain/utils/DataAccessor';
import { vueData } from '@/domain/services/VueData';

/**
 * Gives access to data passed from the backend with
 * VueData::registerConfig([ an array of laravel config keys]
 *
 * Strict typed DAO usage:

 const { someGroup, someOtherField } = useConfigDao<{
	 someGroup: {
		 someFieldA: string;
		 someFieldB: number;
	 };
	 someOtherField: boolean;
 }>();

 */
const useConfigDao = <T>(nested = true) => createDao<T>(vueData.config, nested);

export { useConfigDao };
