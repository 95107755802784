<template>
	<input
		:id="getId()"
		:value="value"
		class="form-control"
		type="email"
		:disabled="disabledOrCollaborationLocked"
		:aria-required="field.required ? 'true' : 'false'"
		:aria-invalid="hasError"
		:aria-describedby="ariaDescribedby"
		@input="onInput($event.target.value)"
		@blur="onBlur"
		@focus="onFocus"
	/>
</template>

<script>
import Field from './Field.vue';

export default {
	extends: Field,
	methods: {
		onBlur() {
			this.validate();
			this.fieldService.locks.set(false);
		},
		onFocus() {
			this.fieldService.locks.set(true);
		},
	},
};
</script>
