<template>
	<select-field
		:id="getId()"
		class="countries"
		:value="value"
		:items="countries"
		:empty-option="false"
		:disabled="disabledOrCollaborationLocked"
		:aria-required="field.required ? 'true' : 'false'"
		:aria-invalid="hasError"
		:aria-describedby="ariaDescribedby"
		@selected="valueChanged"
		@focus="onFocus"
		@blur="onBlur"
	></select-field>
</template>

<script>
import Field from './Field.vue';
import { mapState } from 'vuex';
import { SelectField } from 'vue-bootstrap';

export default {
	components: { SelectField },
	extends: Field,
	computed: {
		...mapState('global', ['countries']),
	},
	methods: {
		valueChanged(name, newValue) {
			this.onInput(newValue);
			this.valueChanged = this.valueFurtherChanged;
		},
		valueFurtherChanged(name, newValue) {
			this.onInput(newValue);
			this.fieldService.locks.set(false);
		},
		onBlur() {
			this.fieldService.locks.set(false);
		},
		onFocus() {
			this.fieldService.locks.set(true);
		},
	},
};
</script>
