import { Consumer } from '@/domain/models/Consumer';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const vuexStore = {
	namespaced: true,
	state,
	getters,
	mutations,
};

type State = {
	authentication: {
		consumer: Consumer;
	};
};

/* eslint-disable @typescript-eslint/naming-convention */
type Getters = {
	'authentication/consumer': Consumer;
};

type Mutations = (name: 'authentication/updateConsumerImage', image: Consumer['img']) => void;

type AuthenticationStore = {
	state: State;
	getters: Getters;
	commit: Mutations;
};

export { vuexStore as default, AuthenticationStore, State };
