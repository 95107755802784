import { useVariablesDao } from '@/domain/dao/Variables';

type SubmittableTokens = {
	submittableToken: string;
	authToken: string;
};

const useSubmittableTokens = {
	_data: useVariablesDao<SubmittableTokens>(),
	get data() {
		return this._data;
	},
	set data(value: SubmittableTokens) {
		this._data = value;
	},
	reset() {
		this._data = useVariablesDao<SubmittableTokens>();
	},
};

export { useSubmittableTokens, SubmittableTokens };
