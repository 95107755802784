import { bootManageCollaboratorsContainer } from '@/lib/components/ListActions/ManageCollaboratorsProvider';
import FormsGalleryList from '@/modules/entries/components/FormsGalleryList';
import { mountVueComponent } from '@/lib/mount-vue-component';
import MyEntryList from './components/MyEntryList';
import Vue from 'vue';

export default function () {
	bootManageCollaboratorsContainer();

	const myEntryListDOM = document.getElementById('my-entry-list');

	const store = require('@/lib/store/index').default;
	if (myEntryListDOM) {
		const myEntryList = new Vue({
			el: '#my-entry-list',
			components: {
				'my-entry-list': MyEntryList,
				FormsGalleryList,
			},
			store,
		});

		$(document).one('pjax:end', () => {
			myEntryList.$destroy();
		});
	}

	// CollapsibleBox - <collapsible-box />
	import('@/lib/components/Shared/CollapsibleBox').then((collapsibleBoxElement) => {
		mountVueComponent('collapsible-box', collapsibleBoxElement);
	});
}
