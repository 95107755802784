import { featureEnabled } from '@/domain/dao/Features';
import { getSubmittableUrl } from '@/lib/utils';

/**
 * Repository of routes for the entry-form-api module
 *
 * Keyed by type of form (entrant / manager) and then by the route name.
 * All of the routes should be defined here.
 */
const routesRepo = {
	entrant: {
		preview: (entry, tabSlug, submittable) => {
			const url = getSubmittableUrl(submittable);
			return `/${url}/entrant/${entry}/preview?tabSlug=${tabSlug}`;
		},
		start: (submittable) => `/${submittable}/entrant/start`,
		save: (entry, submittable) => `/${submittable}/entrant/entry/${entry}/autosave`,
		submit: (submittableSlug, submittable) => {
			const url = getSubmittableUrl(submittable);
			return submittableSlug
				? `/${submittable}/entrant/${url}/${submittableSlug}/submit`
				: `/${submittable}/entrant/${url}/submit`;
		},
		edit: (entry, submittable) => `/${submittable}/entrant/${entry}/edit`,
		index: () => '/entry/entrant',
		complete: (payload) => {
			const submittable = getSubmittableUrl(payload.submittable);
			return `/${submittable}/entrant/complete/${payload.submittableSlug}`;
		},
		pay: (entry) => `/entry-form/entrant/${entry}/pay`,
		cart: () => '/cart',
		blankPdf: (categorySlug, chapterSlug) => `/entry/entrant/${categorySlug}/blank-pdf/${chapterSlug}`,
		categories: (seasonId, chapterId, formId, trashed, active) =>
			`/entry-form/entrant/categories/${seasonId}/${chapterId}?trashed=${trashed}&active=${active}&formId=${formId}`,
		submitEligibility: (entry) =>
			entry ? `/entry-form/entrant/entry/${entry}/eligibility` : '/entry-form/entrant/entry/eligibility',
		eligibility: (payload) => `/entry/entrant/eligibility/${payload.entrySlug}/${payload.contentBlock}`,
		grantReportIndex: () => '/grant-report/entrant',
	},
	manager: {
		preview: (entry, tabSlug, submittable) => {
			const url = getSubmittableUrl(submittable);
			return `/${url}/manager/${entry}/preview?tabSlug=${tabSlug}`;
		},
		start: (submittable) => `/${submittable}/manager/start`,
		save: (entry, submittable) => `/${submittable}/manager/entry/${entry}/autosave`,
		submit: (submittableSlug, submittable) => {
			const url = getSubmittableUrl(submittable);
			return submittableSlug
				? `/${submittable}/manager/${url}/${submittableSlug}/submit`
				: `/${submittable}/manager/${url}/submit`;
		},
		edit: (entry, submittable) => `/${submittable}/manager/${entry}/edit`,
		index: () => '/entry/manager',
		complete: (payload) => `/${payload.submittable}/manager/complete/${payload.submittableSlug}`,
		categories: (seasonId, chapterId, formId, trashed, active) =>
			`/entry-form/manager/categories/${seasonId}/${chapterId}?trashed=${trashed}&active=${active}&formId=${formId}`,
		submitEligibility: (entry) =>
			entry ? `/entry-form/manager/entry/${entry}/eligibility` : '/entry-form/manager/entry/eligibility',
		eligibility: (payload) => `/entry/manager/eligibility/${payload.entrySlug}/${payload.contentBlock}`,
		blankPdf: (categorySlug, chapterSlug) => `/entry/manager/${categorySlug}/blank-pdf/${chapterSlug}`,
		grantReportIndex: () => '/grant-report/manager/',
	},
};

/**
 * routes - routes object selected according to routeSet
 * saveUrl - an URL for saving a form
 * submitUrl - an URL for submitting a form
 * previewUrl - an URL for the entry preview
 * editUrl - an URL for entry edit with tab slug
 * isFormDirty - returns true if there's unsaved data
 */
export default {
	hasValidatorMessage: (state) => {
		if (state.errorBag && typeof state.errorBag === 'object') {
			return state.errorBag.filter((error) => Array.isArray(error.message)).length > 0;
		}

		return false;
	},

	genericErrorMessage: (state) => {
		if (state.errorBag && typeof state.errorBag === 'object') {
			if (state.errorBag[1] && state.errorBag[1].message && state.errorBag[1].message[1] === 'error') {
				return state.errorBag[1].message[0];
			}
		}

		return false;
	},

	tabErrorMessages: (state) => (tabType, tabId) =>
		state.errorBag
			.filter(
				(e) =>
					// eslint-disable-next-line eqeqeq
					e.type === 'Tab' && e.baseField === undefined && e.tab === tabType && (e.tabId === undefined || e.tabId == tabId)
			)
			.map((e) => e.message[0]),

	routes: (state) => routesRepo[state.routeSet],

	startUrl: (state, getters) => getters.routes.start(),

	payUrl: (state, getters, rootState, rootGetters) => {
		const entrySlug = rootGetters['entryForm/entrySlug'];
		const submittable = rootGetters['entryForm/submittable'];
		return entrySlug ? getters.routes.pay(entrySlug) : getters.routes.start(submittable);
	},

	saveUrl: (state, getters, rootState, rootGetters) => {
		const entrySlug = rootGetters['entryForm/entrySlug'];
		const submittable = rootGetters['entryForm/submittable'];
		return entrySlug ? getters.routes.save(entrySlug, submittable) : getters.routes.start(submittable);
	},

	submitUrl: (state, getters, rootState, rootGetters) => {
		const submittableSlug = rootGetters['entryForm/entrySlug'];
		const submittable = rootGetters['entryForm/submittable'];
		return getters.routes.submit(submittableSlug, submittable);
	},

	previewUrl: (state, getters, rootState, rootGetters) => {
		const entrySlug = rootGetters['entryForm/entrySlug'];
		const tabSlug = rootGetters['entryForm/selectedTab'].slug || '';
		const submittable = rootGetters['entryForm/submittable'];
		return entrySlug ? getters.routes.preview(entrySlug, tabSlug, submittable) : getters.routes.index();
	},

	blankPdfUrl: (state, getters, rootState, rootGetters) => {
		const category = rootGetters['entryForm/selectedCategory'];
		const chapter = rootGetters['entryForm/selectedChapter'];
		return category ? getters.routes.blankPdf(category.slug, chapter.slug) : null;
	},

	editUrl: (state, getters, rootState, rootGetters) => {
		const entrySlug = rootGetters['entryForm/entrySlug'];
		const submittable = rootGetters['entryForm/submittable'];
		return entrySlug ? getters.routes.edit(entrySlug, submittable) : getters.routes.start(submittable);
	},

	submitEligibilityUrl: (state, getters, rootState, rootGetters) => {
		const entrySlug = rootGetters['entryForm/entrySlug'];
		return getters.routes.submitEligibility(entrySlug);
	},

	isFormDirty: (state, getters, rootState, rootGetters) => rootGetters['entryForm/isDirty'],

	hasEntry: (state, getters, rootState, rootGetters) => rootGetters['entryForm/entrySlug'] !== null,

	isLoaded: (state, getters, rootState, rootGetters) => rootGetters['entryForm/isLoaded'],

	isSaveable: (state, getters, rootState, rootGetters) => rootGetters['entryForm/isSaveable'],

	attachmentsLoaded: (state, getters, rootState) => rootState['entryForm'].attachmentsLoaded,

	isSubmittable: (state, getters, rootState, rootGetters) => rootGetters['entryForm/isSubmittable'],

	contributorsLoaded: (state, getters, rootState) => rootState['entryForm'].contributorsLoaded,

	refereesLoaded: (state, getters, rootState) => rootState['entryForm'].refereesLoaded,

	hasContributors: (state, getters, rootState) =>
		rootState['entryForm'].tabs.filter((tab) => tab.type === 'Contributors').length > 0,

	hasReferees: (state, getters, rootState) =>
		rootState['entryForm'].tabs.filter((tab) => tab.type === 'Referees').length > 0,

	configurationMode: (state, getters, rootState) => rootState['entryFormConfiguration'].configurationMode,

	isFormCollaborativeOrUpdatable: (state, getters, rootState, rootGetters) => {
		if (!getters.isSubmittable) {
			return false;
		}

		const form = rootGetters['entryForm/currentForm'];

		return (
			(featureEnabled('collaboration') && form.collaborative) ||
			(featureEnabled('api_submission_updates') && form.allowApiUpdates)
		);
	},
};
