import { Collaborator } from '@/domain/models/Collaborator';
import { PostingEndpoint } from '@/domain/services/Api/Endpoint';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const routes = useRoutesDao<{
	collaborators: {
		reinvite: (p: { collaborator: string }) => string;
	};
}>();

const useApi = (collaborator: Collaborator) => ({
	reinviteUser: () =>
		PostingEndpoint<null, Collaborator[]>(routes.collaborators.reinvite as RoutesGetter)({
			collaborator: collaborator.slug,
		})(null),
});

type ReinviteApi = ReturnType<typeof useApi>;

export { useApi, ReinviteApi };
