
import { Collaborator } from '@/domain/models/Collaborator';
import { CollaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { Dropdown } from 'vue-bootstrap';
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { collaboratorActionsController, Props, View } from './CollaboratorActions.controller';
export default defineComponent<Props, View>({
	components: {
		Dropdown,
	},

	props: {
		canManage: {
			type: Boolean,
			default: false,
		},
		collaborator: {
			type: Object as Collaborator,
			required: true,
		},
		service: {
			type: Object as CollaboratorsService,
			default: null,
		},
	},

	setup: useController(collaboratorActionsController, 'collaboratorActionsController') as () => View,
});
