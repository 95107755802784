<template>
  <div class="removable-list">
    <transition-group name="list" tag="div">
      <div v-for="item in items" :key="item.id" class="item">
        <popover
          :content="item.title"
          trigger="hover"
          :placement="valuePopoverPlacement"
          popover-wrap-class="title"
          :delay-hover="popoverDelay"
        >
          <span>
            {{ item.name ? item.name : item.title }}
            <span class="extra-property">
              <a @click.prevent="remove(item)">
                <span class="af-icons af-icons-close-circle remove not-draggable pull-right"></span>
              </a>
            </span>
          </span>
        </popover>
        <input v-if="itemName" type="hidden" :name="itemName" :value="item[valueProperty]" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { Popover } from 'vue-bootstrap';

export default {
  components: {
    Popover
  },
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
    itemName: {
      type: String,
      default: null
    },
    popoverDelay: {
      type: Number,
      default: 500
    },
    valueProperty: {
      type: String,
      default: 'value'
    },
    valuePopoverPlacement: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      items: []
    };
  },
  watch: {
    initialItems() {
      this.items = JSON.parse(JSON.stringify(this.initialItems));
    }
  },
  created() {
    this.items = JSON.parse(JSON.stringify(this.initialItems));
  },
  methods: {
    getIndex(item) {
      return this.items.findIndex(i => i.id === item.id);
    },
    remove(item) {
      const index = this.getIndex(item);

      if (index >= 0) {
        this.items.splice(index, 1);
      }

      this.$emit('removed', item.id);
      this.$emit('change', this.items);
    }
  }
};
</script>

<style scoped>
.extra-property {
  margin-left: 5px;
}

.list-enter-active {
  transition: all 0.3s;
}

.list-leave-active {
  transition: all 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
