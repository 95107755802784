<template>
	<localised-datepicker
		:id="getId()"
		:key="`ld-${getId()}-${dKey}`"
		:value="value"
		:include-timezone="!!field.includeTimezone"
		:preselect-current-date="!!field.preselectCurrentDate"
		:mode="mode"
		:highlight-today="!!field.preselectCurrentDate"
		:disabled="disabledOrCollaborationLocked"
		:unlock-signal="fieldService.unlockSignal"
		@update:value="onInput"
		@changed="dateOrTimeChanged"
		@toggle="onToggle"
		@focus="onFocus"
		@close="onClose"
		@escape="onClose"
		@blur="onBlur"
		v-on="$listeners"
	/>
</template>

<script>
import LocalisedDatepicker from '../Shared/LocalisedDatepicker.vue';
import Field from './Field.vue';

export default {
	components: { LocalisedDatepicker },
	extends: Field,
	inject: ['lang'],
	props: {
		field: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			required: true,
			validator: (prop) => ['date', 'datetime', 'time'].indexOf(prop) !== -1,
		},
		value: {
			type: [String, Object],
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onToggle(state) {
			this.fieldService.locks.set(state);
		},
		dateOrTimeChanged(event) {
			this.datetime = event;
			this.$emit('changed', event);
		},
		onFocus() {
			this.fieldService.locks.set(true);
		},
		onBlur() {
			if (this.mode === 'date') {
				this.unlockMe();
			}
		},
		onClose() {
			this.unlockMe();
		},
		unlockMe() {
			this.fieldService.locks.set(false);
		},
	},
};
</script>
