const { mountVueComponent } = require('@/lib/mount-vue-component.js');

/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoThumbnails = require('../../lib/video-thumbnails.js');
var isMobile = require('@/lib/navigation-is-mobile');
const { registerAutosaveCommentListeners } = require('@/lib/autosaver-comment');
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});
	}

	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards:not(.vertical-gallery)', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();
	videoThumbnails.setup('preview-container.video');

	/**
	 * Auto save comments
	 */
	const voteButton = document.querySelector('button.control.vote-button');
	const unvoteButton = document.querySelector('button.action-button.btn-unvote');
	registerAutosaveCommentListeners([voteButton, unvoteButton]);

	import('@/lib/components/Video/VideoPlayer').then((videoPlayer) => {
		mountVueComponent('video-player', videoPlayer);
	});

	import('@/lib/components/Video/VideoModal.vue').then((videoModal) => {
		mountVueComponent('video-modal', videoModal);
	});

	/** Comments */
	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});

	/** Help Icon */
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});
};
