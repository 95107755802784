import { Collaborator, CollaboratorPrivilege } from '@/domain/models/Collaborator';
import { DeletingEndpoint, GettingEndpoint, PostingEndpoint } from '@/domain/services/Api/Endpoint';

const collaborators: Record<string, Collaborator[]> = {};

const resourceId = (formSlug: string, submittableSlug: string) => `${formSlug}-${submittableSlug}`;

const cache = (formSlug: string, submittableSlug: string) => ({
	get: () => collaborators[resourceId(formSlug, submittableSlug)] || null,
	set: (data: Collaborator[]) => (collaborators[resourceId(formSlug, submittableSlug)] = data),
	clear: () => delete collaborators[resourceId(formSlug, submittableSlug)],
});

const getCollaborators =
	(formSlug: string, submittableSlug: string) =>
	async (fresh = false): Promise<Collaborator[]> => {
		const cached = cache(formSlug, submittableSlug);
		if (fresh || cached.get() === null) {
			try {
				const data = await GettingEndpoint<{ collaborators: Collaborator[] }>(
					`/collaborators/${formSlug}/${submittableSlug}`
				)();
				cached.set(data.collaborators);
			} catch {
				cached.set([]);
			}
		}

		return Promise.resolve<Collaborator[]>(cached.get());
	};

type InviteCollaboratorsForm = {
	emails: string[];
	privilege: CollaboratorPrivilege;
	message: string;
};

const inviteCollaborators = (formSlug: string, submittableSlug: string) => async (form: InviteCollaboratorsForm) => {
	const cached = cache(formSlug, submittableSlug);
	const data = await PostingEndpoint<
		InviteCollaboratorsForm,
		{
			collaborators: Collaborator[];
		}
	>(`/collaborators/${formSlug}/${submittableSlug}/invite`)()(form);

	cached.set(data.collaborators);
	return Promise.resolve<Collaborator[]>(cached.get());
};

const removeCollaborator = (formSlug: string, submittableSlug: string) => async (collaboratorSlug: string) => {
	const cached = cache(formSlug, submittableSlug);
	const data = await DeletingEndpoint<{
		collaborators: Collaborator[];
	}>(`/collaborators/${collaboratorSlug}`)();

	cached.set(data.collaborators);
	return Promise.resolve<Collaborator[]>(cached.get());
};

const setOwner = (formSlug: string, submittableSlug: string) => async (collaboratorUserSlug: string) => {
	const cached = cache(formSlug, submittableSlug);
	const data = await PostingEndpoint<
		null,
		{
			collaborators: Collaborator[];
		}
	>(`/collaborators/${formSlug}/${submittableSlug}/transfer-ownership/${collaboratorUserSlug}`)()();

	cached.set(data.collaborators);
	return Promise.resolve<Collaborator[]>(cached.get());
};

const getSubmittableTokens = (formSlug: string, submittableSlug: string) => async () => {
	const data = await GettingEndpoint<{ data: string[] }>(`/entry/entrant/${formSlug}/${submittableSlug}/tokens`)();

	return Promise.resolve<any>(data);
};

const clearCache = (formSlug: string, submittableSlug: string) => () => {
	const cached = cache(formSlug, submittableSlug);
	cached.clear();
};

const useApi = (formSlug: string, submittableSlug: string) => ({
	getCollaborators: getCollaborators(formSlug, submittableSlug),
	inviteCollaborators: inviteCollaborators(formSlug, submittableSlug),
	removeCollaborator: removeCollaborator(formSlug, submittableSlug),
	setOwner: setOwner(formSlug, submittableSlug),
	getSubmittableTokens: getSubmittableTokens(formSlug, submittableSlug),
	clearCache: clearCache(formSlug, submittableSlug),
});

export { useApi, type InviteCollaboratorsForm };
