<template>
	<div>
		<img :src="poster" class="video-placeholder" alt="placeholder" :style="styleObject" />
		<video
			ref="videoPlayer"
			:height="videoPlayerHeight"
			:poster="poster"
			class="video-js vjs-default-skin vjs-big-play-centered"
			crossorigin="anonymous"
		>
			<track v-if="caption" kind="captions" :src="caption" label="Captions" default />
		</video>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import options from '@/domain/services/VideoPlayer/VideoPlayerOptions';
import init from '@/domain/services/VideoPlayer/VideoPlayerInit';
import beforeRequest from '@/domain/services/VideoPlayer/VideoPlayerBeforeRequest';

export default defineComponent({
	props: {
		source: {
			type: String,
			required: true,
		},
		poster: {
			type: String,
			default: '',
		},
		caption: {
			type: String,
			default: null,
		},
		aspectRatio: {
			type: String,
			default: null,
		},
		videoPlayerHeight: {
			type: Number,
			default: null,
		},
		videoPlayerOptions: {
			type: Object,
			default: () => ({}),
		},
		styleObject: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			player: null,
			isPlaying: false,
		};
	},
	computed: {},
	watch: {
		caption: {
			handler(caption) {
				// Disable all text tracks when the caption is deleted.
				if (!caption && this.player) {
					const textTracks = this.player.textTracks();

					for (let i = 0; i < textTracks.length; i++) {
						const track = textTracks[i];
						track.mode = 'disabled';
					}
				}
			},
			deep: true,
		},
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
	mounted() {
		this.initializePlayer();
	},
	methods: {
		initializePlayer() {
			if (!this.player) {
				this.player = init(this.$refs.videoPlayer, { ...options, ...this.videoPlayerOptions });

				this.player.on('xhr-hooks-ready', () => {
					this.player.tech().vhs.xhr.onRequest(beforeRequest);
				});

				this.player.src({ src: this.source, type: 'application/x-mpegURL' });

				if (this.aspectRatio) {
					this.player.aspectRatio(this.aspectRatio);
				}

				this.player.on(['waiting', 'pause'], () => (this.isPlaying = false));
				this.player.on('playing', () => (this.isPlaying = true));
			}

			if (this.caption) {
				this.addCaptionTrack();
			}
		},
		pause() {
			// Pausing a video which is loading can have negative consequences (DOMException).
			// If the video is already playing pause immediately, otherwise bind to the
			// 'playing' event and pause when the player is ready.
			if (this.isPlaying) {
				this.player.pause();
			} else {
				this.player.one('playing', () => this.player.pause());
			}
		},
		addCaptionTrack() {
			const source = this.caption;

			if (!source) {
				return;
			}

			this.player.ready(() => {
				const newTrack = {
					src: source,
					kind: 'captions',
					label: 'Captions',
					default: true,
					mode: 'showing',
				};

				this.player.addRemoteTextTrack(newTrack, true);
			});
		},
	},
});
</script>

<style scoped>
/* Use the .video-placeholder img to set the video's aspect ratio */
.video-placeholder {
	max-width: 100%;
	max-height: calc(100vh - 200px);
	z-index: -1;
	position: relative;
}
.video-placeholder + .video-js {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
</style>
