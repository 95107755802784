import { PuttingEndpoint } from '@/domain/services/Api/Endpoint';
import { Collaborator, CollaboratorPrivilege } from '@/domain/models/Collaborator';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const routes = useRoutesDao<{
	collaborators: {
		privilege: {
			update: (p: { collaborator: string }) => string;
		};
	};
}>();

const useApi = (collaborator: Collaborator) => ({
	updatePrivilege: (privilege: CollaboratorPrivilege) =>
		PuttingEndpoint<
			{
				privilege: CollaboratorPrivilege;
			},
			{
				collaborators: Collaborator[];
			}
		>(routes.collaborators.privilege.update as RoutesGetter)({ collaborator: collaborator.slug })({ privilege }),
});

type PrivilegeSelectorApi = ReturnType<typeof useApi>;

export { useApi, PrivilegeSelectorApi };
