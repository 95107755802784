<template>
	<title-with-menu class="menu" :items="items" v-on="$listeners">
		<slot />
	</title-with-menu>
</template>

<script>
import { defineComponent } from 'vue';
import TitleWithMenu from '@/lib/components/Shared/TitleWithMenu';
import { actionMenuController } from '@/modules/document-templates/components/Uploader/ActionMenu.controller';

export default defineComponent({
	name: 'ActionMenu',
	components: { TitleWithMenu },
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		downloadLink: {
			type: String,
			default: null,
		},
	},
	setup: actionMenuController,
});
</script>

<style scoped>
.menu {
	font-size: 14px;
}
</style>
