import { createDao } from '@/domain/utils/DataAccessor';
import { vueData } from '@/domain/services/VueData';

/**
 * Gives access to custom data passed from the backend with
 * VueData::register<custom variable name>(variable-value)
 *
 * Strict typed DAO usage:

 const { variableA, variableB } = useConfigDao<{
    variableA: string;
    variableB: number;
 }>();

 */

const useVariablesDao = <T>(nested = true) => createDao<T>(vueData.variables, nested);

export { useVariablesDao };
