import { consumerService } from '@/domain/services/ConsumerService';
import store from '@/lib/store';
import { useServiceProvider } from '@/domain/services/Container';

const { boot, use } = useServiceProvider<{
	Consumer: () => ReturnType<typeof consumerService>;
}>({
	Consumer: () => consumerService(store),
});

export { use as useProfileContainer, boot as bootProfileContainer };
