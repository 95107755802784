import { useRtConfig } from '@/domain/services/Rt/Config';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { connectAuthEmulator, getAuth, signInWithCustomToken } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { deleteApp, FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { Encrypter, jsonEncrypter, transparentEncrypter } from '@/domain/services/Encrypter';
import { SubmittableTokens, useSubmittableTokens } from '@/domain/services/Rt/SubmittableTokens';

let firebaseDescriptor:
	| {
			firebase: FirebaseApp;
			emulated: boolean;
			isReadonly: () => boolean;
			getEncrypter: () => Encrypter<unknown>;
			getFirestore: (reinitialize?: boolean) => Firestore;
	  }
	| undefined;

let firestore: Firestore | undefined;

let firebase: FirebaseApp | undefined;

const makeFirestore = (firebase: FirebaseApp, emulated: boolean, reinitialize: boolean) => {
	if (reinitialize) {
		firestore = undefined;
	}

	if (firestore === undefined) {
		const config = useRtConfig();
		firestore = config.firebase?.firestoreDB
			? getFirestore(firebase, config.firebase.firestoreDB)
			: getFirestore(firebase);

		if (emulated && config?.firebase?.emulators?.firestore) {
			console.warn('FIREBASE EMULATOR USED');
			connectFirestoreEmulator(
				firestore,
				config.firebase.emulators.firestore.host,
				config.firebase.emulators.firestore.port
			);
		}
	}

	return firestore;
};

const makeAuth = async (firebase: FirebaseApp, emulated: boolean, authToken: string) => {
	const auth = getAuth(firebase);

	if (emulated) {
		const host = useRtConfig()?.firebase?.emulators?.auth?.host;

		if (!host) {
			throw new Error('Firebase authentication host is not defined!');
		}

		connectAuthEmulator(auth, host);
	}

	await signInWithCustomToken(auth, authToken);
};

const makeEncrypter = (encrypt: boolean) => {
	const encrypter = encrypt
		? jsonEncrypter(useSubmittableTokens.data.submittableToken)
		: transparentEncrypter(useSubmittableTokens.data.submittableToken);

	if (encrypter === undefined) {
		throw new Error('Encrypter is not booted!');
	}

	return encrypter;
};

const bootFirebase = async (submittableTokens?: SubmittableTokens, isReadonly = () => false) => {
	collaborationUIBus.emit(CollaborationUISignals.RELOAD, undefined);

	await Promise.all(getApps().map((app) => deleteApp(app)));

	const { services, firebase: firebaseConfig } = useRtConfig();

	if (submittableTokens) {
		firestore = undefined;
		useSubmittableTokens.data = submittableTokens;
	}

	if (!services?.firebase || Object.values(services.firebase).every((value) => value === undefined)) {
		throw new Error('Firebase boot failed!');
	}

	const emulated = firebaseConfig?.emulators?.useEmulator === true;

	const firebaseApp = await initializeApp(services.firebase);
	firebase = firebaseApp;
	if (submittableTokens?.authToken) {
		await makeAuth(firebase, emulated, submittableTokens.authToken);
	}

	firebaseDescriptor = {
		emulated,
		firebase,
		isReadonly,
		getEncrypter: () => makeEncrypter(firebaseConfig?.encrypt === true),
		getFirestore: (reinitialize = false) => makeFirestore(firebaseApp, emulated, reinitialize),
	};

	return firebaseDescriptor;
};

const getFirebase = () => {
	if (firebaseDescriptor !== undefined) {
		return firebaseDescriptor;
	}

	throw new Error('Firebase is not booted!');
};

export { getFirebase, bootFirebase };
