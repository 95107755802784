
import { defineComponent } from 'vue';
import { Dropdown } from 'vue-bootstrap';
import { useController } from '@/domain/services/Composer';
import { privilegeSelectorController, Props, View } from '@/lib/components/Collaboration/PrivilegeSelector.controller';
import { Collaborator } from '@/domain/models/Collaborator';

export default defineComponent<Props, View>({
	components: {
		Dropdown,
	},

	props: {
		canManage: {
			type: Boolean,
			default: false,
		},
		collaborator: {
			type: Object as Collaborator,
			required: true,
		},
	},

	setup: useController(privilegeSelectorController, 'privilegeSelectorController') as View,
});
