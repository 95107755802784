<template>
	<div class="mtm mbs action-overflow-wrapper">
		<dropdown
			button-class="dropdown-toggle"
			container-class="action-overflow"
			:aria-label="computedAriaLabel"
			menu-class="dropdown"
			:disabled="disabled"
		>
			<template slot="label">
				<i class="af-icons af-icons-action-overflow" />
			</template>

			<template slot-scope="menu" slot:default>
				<li v-for="item in menuItems" :key="`item-${item.id}`">
					<a v-if="item.disabled" disabled>{{ item.label }}</a>
					<template v-else>
						<a
							v-if="item.url"
							:href="item.url"
							:class="item.class"
							target="_blank"
							rel="noopener noreferrer"
							@click="menu.close()"
							>{{ item.label }}
						</a>
						<a
							v-else
							@click.prevent="
								$emit(`menu:${item.id}`);
								menu.close();
							"
							>{{ item.label }}
						</a>
					</template>
				</li>
			</template>
		</dropdown>
		<span><slot>title</slot></span>
	</div>
</template>

<script>
import { Dropdown } from 'vue-bootstrap';
import { toCamelCase } from '@/lib/string.js';

export default {
	components: {
		Dropdown,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		ariaLabel: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedAriaLabel() {
			return this.ariaLabel || `Menu for ${this.title}`;
		},
		menuItems() {
			return this.items.map((item) => ({
				id: item.id || toCamelCase(item.label),
				label: item.label,
				url: item.url || null,
				class: item.class || null,
				ariaLabel: item.ariaLablel || item.label,
				disabled: item.disabled === true,
			}));
		},
	},
	methods: {
		clicked(key) {
			this.$emit(`menu:${key}`);
			this.$emit('keyup.esc');
		},
	},
};
</script>
