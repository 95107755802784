import $ from 'jquery';
import buildFormData from '@/lib/formdata';
import { setUrlParameter } from '@/lib/url';
import Vue from 'vue';
import { goto, parseError } from './helpers';

const messages = {
	saving: 'entries.autosaver.saving',
	saved: 'entries.autosaver.success',
	error: 'entries.autosaver.error',
};

const ajax = {
	get: (url) => Vue.prototype.$http.get(url),
	post: (url, data) => Vue.prototype.$http.post(url, data),
	postAsForm: (url, data) =>
		Vue.prototype.$http.post(url, buildFormData(data), {
			headers: { 'X-CLEAR-BREADCRUMB': true },
		}),
	putAsForm: (url, data) =>
		Vue.prototype.$http.post(url, buildFormData({ ...data, _method: 'PUT' }), {
			headers: { 'X-CLEAR-BREADCRUMB': true },
		}),
};

export default {
	previewEntry({ getters, dispatch }) {
		dispatch('save').then((r) => {
			if (r) {
				goto(getters.previewUrl);
			}
		});
	},

	saveAndPay({ getters, dispatch, rootGetters }) {
		const data = rootGetters['entryForm/data'];
		return dispatch('ajaxRequest', {
			method: 'postAsForm',
			uri: getters.payUrl,
			data: data,
			forceDirty: true,
		});
	},

	submitForm({ dispatch, rootGetters, getters }) {
		const data = rootGetters['entryForm/data'];
		return dispatch('ajaxRequest', {
			method: 'putAsForm',
			uri: getters.submitUrl,
			data: data,
			forceDirty: true,
		});
	},

	submit({ state, getters, dispatch, rootGetters }) {
		if (!state.autosaveEnabled) {
			return dispatch('submitForm');
		}

		return dispatch('ajaxRequest', {
			method: 'putAsForm',
			uri: getters.saveUrl,
			data: rootGetters['entryForm/data'],
			showSavingMessages: true,
		}).then(() => dispatch('submitForm'));
	},

	save({ state, getters, dispatch, rootGetters }) {
		if (!state.autosaveEnabled) {
			return Promise.resolve(true);
		}

		const data = rootGetters['entryForm/data'];
		return dispatch('ajaxRequest', {
			method: getters.hasEntry ? 'putAsForm' : 'postAsForm',
			uri: getters.saveUrl,
			data: data,
			showSavingMessages: true,
		}).then((r) => {
			if (r) {
				if (getters.hasEntry && getters.hasContributors && !getters.contributorsLoaded) {
					dispatch('entryForm/loadContributors', null, { root: true });
				}

				if (getters.hasEntry && getters.hasReferees && !getters.refereesLoaded) {
					dispatch('entryForm/loadReferees', null, { root: true });
				}
			}

			// The entry has been saved, so we can now remove the 'Details' tab ApiErrors
			dispatch('entryForm/updateDetailsTabApiErrors', null, { root: true });

			return r;
		});
	},

	saveAndNext({ state, dispatch, getters, rootGetters }) {
		const nextTabSlug = rootGetters['entryForm/nextTabSlug'];
		const isNewEntry = !rootGetters['entryForm/entrySlug'];

		return dispatch('save').then((r) => {
			if (r) {
				if (isNewEntry && getters.isFormCollaborativeOrUpdatable) {
					goto(getters.editUrl + '?tabSlug=' + nextTabSlug);
					return;
				}

				window.history.replaceState({ slug: null }, null, getters.editUrl);
				dispatch('setUriFromTabSlug', nextTabSlug);
				dispatch('entryForm/selectTabBySlug', nextTabSlug, { root: true });
				document.getElementById('entry-form').scrollIntoView();
			}
		});
	},

	saveAndClose({ getters, dispatch, rootState }) {
		return dispatch('save').then((r) => {
			if (r) {
				const currentBreadcrumb = rootState.global.currentBreadcrumb;
				if (
					currentBreadcrumb &&
					(currentBreadcrumb.endsWith('/preview') || currentBreadcrumb === window.location.href.split('?')[0])
				) {
					goto(getters.routes.index());
					return;
				}

				goto(currentBreadcrumb || getters.routes.index());
			}
		});
	},

	close({ getters }) {
		return goto(getters.routes.index());
	},

	autosave({ dispatch, getters }) {
		if (getters.hasEntry) dispatch('save');
	},

	/**
	 * Make an Ajax request
	 *
	 * * it sets Ajax busy flag
	 * * it interprets response operation (redirect or switch)
	 * * it updates the form with response data
	 * * it eventually updates the errorBag
	 *
	 * @param state
	 * @param commit
	 * @param getters
	 * @param dispatch
	 * @param method - a method name, an existing key from 'ajax = { ... }'
	 * @param uri - request URI
	 * @param data - data to pass with the request
	 * @param forceDirty - make the request even if the form isn't dirty
	 * @param showSavingMessages
	 */
	ajaxRequest({ state, commit, getters, dispatch }, { method, uri, data, forceDirty, showSavingMessages }) {
		if (
			!getters.isLoaded ||
			!getters.isSubmittable ||
			state.ajaxInProgress ||
			getters.configurationMode ||
			(!getters.isFormDirty && forceDirty !== true)
		) {
			return Promise.resolve({});
		}

		commit('setMessageType', '');

		if (showSavingMessages === true) {
			commit('setMessage', messages.saving);
		}

		commit('setAjaxInProgress', true);
		return ajax[method || 'get'](uri, data || null).then(
			(response) => {
				dispatch('updateForm', response.data);
				commit('setAjaxInProgress', false);
				commit('setMessageType', 'success');
				if (showSavingMessages === true) {
					commit('setMessage', messages.saved);
				}

				new Promise((resolve) => setTimeout(resolve, 5000)).then(() => commit('setMessage'));
				commit('entryForm/setFormEdited', false, { root: true });
				if (response.data.redirect) {
					goto(getters.routes[response.data.redirect](response.data));
					return false;
				}

				return response.data;
			},
			(error) => {
				const errorBag = [];
				const extraData = {};
				const extraDataKey = /error-response-extra-data-(.*)/i;
				let keyData;

				// Handle 403 Forbidden error
				// Handle 419 Authentication Timeout error
				if (error.response && error.response.data && [403, 419].includes(error.response.status)) {
					commit(
						'entryForm/storeStatus',
						{
							status: 'error',
							message: error.response.data.message || messages.error,
						},
						{ root: true }
					);
					commit('setAjaxInProgress', false);
					commit('setMessageType', 'error');
					commit('setMessage', messages.error);
					return null;
				}

				// Handle validation errors
				if (error.response && error.response.data) {
					Object.keys(error.response.data).forEach((key) => {
						if ((keyData = extraDataKey.exec(key))) {
							extraData[keyData[1]] = error.response.data[key][0];
						} else {
							errorBag[key] = error.response.data[key];
						}
					});
					let apiErrors = Object.entries((errorBag.trace ? [[errorBag.message]] : errorBag) || {}).map((entry) =>
						parseError(entry[0], entry[1])
					);
					commit('setErrorBag', apiErrors);
					commit('entryForm/storeApiErrors', apiErrors, { root: true });
					dispatch('entryForm/focusErrorsTab', null, { root: true });
					dispatch('updateForm', extraData);
				} else if (error.isAxiosError || error.trace) {
					commit('setErrorBag', [[error.message]]);
				} else {
					commit('setErrorBag', {});
				}

				commit('setAjaxInProgress', false);
				commit('setMessageType', 'error');
				commit('setMessage', messages.error);

				$(window).scrollTop(0);
				return null;
			}
		);
	},

	/**
	 * Update the form
	 *
	 * @param entry - entry data if received
	 * @param updatedAt - request timestamp
	 * @param contributors - saved contributors ids if received
	 *
	 * @TODO: add attachments managment here
	 */
	updateForm({ commit, rootGetters, getters, dispatch }, { entry, updatedAt, contributors, tabs, referees }) {
		if (entry) {
			commit('entryForm/storeEntry', entry, { root: true });
			const tabSlug = rootGetters['entryForm/selectedTab'].slug || '';
			window.history.replaceState({ slug: tabSlug }, null, setUrlParameter(getters.editUrl, 'tabSlug', tabSlug));
			if (!rootGetters['entryForm/allTabsLoaded']) {
				dispatch('entryForm/loadPendingTabs', null, { root: true });
			}
		}

		if (updatedAt) commit('entryForm/setTimestamp', updatedAt, { root: true });
		if (contributors) commit('entryForm/updateContributors', contributors, { root: true });
		if (tabs) commit('entryForm/updateTabs', tabs, { root: true });
		if (referees) commit('entryForm/updateReferees', referees, { root: true });
	},

	setUriFromTab({ rootGetters, dispatch }, tabId) {
		const tab = rootGetters['entryForm/tabById'](tabId);
		dispatch('setUriFromTabSlug', tab ? tab.slug : null);
	},

	setUriFromTabSlug(context, tabSlug) {
		window.history.pushState({ slug: tabSlug }, null, setUrlParameter(window.location.href, 'tabSlug', tabSlug));
	},

	/**
	 * Reset store state
	 */
	resetStoreState({ commit }) {
		commit('resetState');
	},

	submitEligibility({ getters, dispatch, rootGetters, commit }) {
		const eligibilityTab = rootGetters['entryForm/lastEligibilityTab']
			? rootGetters['entryForm/lastEligibilityTab']
			: rootGetters['entryForm/eligibilityTabs'].slice(-1)[0];
		const data = { ...rootGetters['entryForm/data'], eligibilityTabSlug: eligibilityTab.slug };
		return dispatch('ajaxRequest', {
			method: 'postAsForm',
			uri: getters.submitEligibilityUrl,
			data: data,
			forceDirty: true,
		}).then((r) => {
			if (r) {
				if (getters.hasEntry && getters.hasAttachments && !getters.attachmentsLoaded) {
					dispatch('entryForm/loadAttachments', null, { root: true });
				}

				if (getters.hasEntry && getters.hasContributors && !getters.contributorsLoaded) {
					dispatch('entryForm/loadContributors', null, { root: true });
				}

				if (getters.hasEntry && getters.hasReferees && !getters.refereesLoaded) {
					dispatch('entryForm/loadReferees', null, { root: true });
				}

				if (r.eligibilityContentBlock) {
					commit('entryForm/setEligibilityContentBlock', r.eligibilityContentBlock, { root: true });
				}

				commit('entryForm/setEntryEligibility', r.isEligible, { root: true });
				if (r.tabs) {
					if (!rootGetters['entryForm/allTabsLoaded']) {
						dispatch('entryForm/loadPendingTabs', null, { root: true });
					}

					const nextTabSlug = rootGetters['entryForm/nextTabSlug'];
					window.history.replaceState({ slug: null }, null, getters.editUrl);
					dispatch('setUriFromTabSlug', nextTabSlug);
					dispatch('entryForm/selectTabBySlug', nextTabSlug, { root: true });
					document.getElementById('entry-form').scrollIntoView();
				}
			}

			return r;
		});
	},

	autosavePromise({ getters, dispatch, rootGetters }) {
		const data = rootGetters['entryForm/data'];
		return dispatch('ajaxRequest', {
			method: 'putAsForm',
			uri: getters.saveUrl,
			data: data,
			showSavingMessages: true,
		});
	},
};
