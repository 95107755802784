/**
 * Used to run a function only when the value changes.
 */
const runUnique = <T>(fn: (val: T) => void, startWith?: T) => {
	let lastValue = startWith;
	return (val: T) => {
		if (val !== lastValue) {
			lastValue = val;
			return fn(val);
		}
	};
};

export { runUnique };
