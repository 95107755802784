import { collaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { defaultCollaboratorAccess } from '@/domain/models/Collaborator';
import { isNotNull } from '@/domain/utils/TypePredicates';
import { useCollaborativeSubmittable } from '@/modules/entry-form/Collaboration/services/CollaborativeSubmittable';
import { useContainer } from '@/domain/services/Container';
import { emptyEndpoint, UpdateFieldEndpoint } from '@/modules/entry-form/Collaboration/services/Api';
import {
	emptyMutableServiceFactory,
	mutableServiceFactory,
	muteFrom,
	signature,
} from '@/domain/services/Collaboration/Mutable';

const emptyFieldService = () => ({
	...emptyMutableServiceFactory(false),
	myAccess: () => Promise.resolve(defaultCollaboratorAccess),
});

const useFieldService = (lockableId: string, endpoint: UpdateFieldEndpoint<unknown> | null = null) => {
	const { consumer, submittableSlug, formSlug, isCollaborative, isUpdatable } = useCollaborativeSubmittable();
	const { onBeforeAppUnload } = useContainer();

	if (!isCollaborative && !isUpdatable) {
		return emptyFieldService();
	}

	const protectedEndpoint = isNotNull<UpdateFieldEndpoint<unknown>>(endpoint)
		? (data: unknown) =>
				collaborators
					.myAccess()
					.then((access) => (access.canEdit ? (endpoint as UpdateFieldEndpoint<unknown>)(data) : emptyEndpoint(data)))
		: null;

	const service = mutableServiceFactory(submittableSlug, formSlug)(
		lockableId,
		protectedEndpoint,
		signature(consumer?.slug)
	);
	const collaborators = collaboratorsService(false)(formSlug || '', submittableSlug || '');

	onBeforeAppUnload(service.destroy);

	return {
		...service,
		values: muteFrom(service.values, consumer?.slug),
		myAccess: collaborators.myAccess,
	};
};

export { useFieldService, emptyFieldService };
