import { Callable } from '@/domain/utils/Types';
import hash from 'object-hash';

/**
 * used as immutable flag when you need to subsequently check if the value has changed
 * const myChangeFlag = isChanged(123);
 * myChangeFlag(123); // false
 * myChangeFlag(124); // true
 * myChangeFlag(124); // false
 * myChangeFlag(125); // true
 * etc.
 */
const isChanged = <T = unknown>(initialValue?: T) => {
	let cache = hash([initialValue]);

	return (val: T) => {
		const value = hash([val]);
		const isCached = value === cache;
		cache = value;
		return isCached;
	};
};

const ignoreRepeatedCalls = <F extends Callable>(callable: F): F => {
	let fingerprint: string | null = null;

	const wrappedFunction = (...args: Parameters<F>): void => {
		const fp = hash(args);
		if (fp === fingerprint) {
			return;
		}

		fingerprint = fp;
		callable(...args);
	};

	return wrappedFunction as unknown as F;
};

export { isChanged, ignoreRepeatedCalls };
