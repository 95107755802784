import $ from 'jquery';
import { mountVueComponent } from '@/lib/mount-vue-component';
import ResourceList from '../../lib/components/ResourceList';
import store from '@/lib/store';
import Vue from 'vue';

export default function () {
	import('@/lib/components/User/UserFace.vue').then((userFace) => {
		mountVueComponent('user-face', userFace);
	});

	const leaderboardProgress = new Vue({
		el: '#leaderboard-progress',
		name: 'LeaderboardProgressApp',
		components: {
			'leaderboard-progress': ResourceList,
		},
		store,
	});
	$(document).one('pjax:end', () => {
		leaderboardProgress.$destroy();
	});
}
