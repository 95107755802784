import { Api } from '@/legacy/OldApi';
import { headersFactory } from '@/domain/services/Api/Headers';
import { isFunction } from '@/domain/utils/TypePredicates';
import { DataError, DataRequestMethod, DataResponse, dataSource } from '@/domain/services/Api/DataSource';
import { RoutesGetter, RoutesParam } from '@/domain/dao/Routes';

// eslint-disable-next-line @typescript-eslint/naming-convention
const Endpoint =
	<TRequest, TResponse>(method: DataRequestMethod, url: string, headers: Record<string, string> = {}) =>
	async (data?: TRequest) => {
		const listener = Api.getListener() as {
			successHandler: (response: DataResponse<TResponse>) => void;
			errorHandler: (error: DataError<TResponse>) => void;
		};

		try {
			const response = await dataSource.request<TRequest, TResponse>({
				url,
				method,
				data,
				headers: headersFactory(headers),
			});

			listener.successHandler(response);
			return Promise.resolve(response.data);
		} catch (error: unknown) {
			listener.errorHandler(error as DataError<TResponse>);
			return Promise.reject(error);
		}
	};

const makeUrl = (url: string | RoutesGetter, p?: RoutesParam) => (isFunction<RoutesGetter>(url) ? url(p) : url);

// eslint-disable-next-line @typescript-eslint/naming-convention
const GettingEndpoint =
	<TResponse>(url: string | RoutesGetter, headers: Record<string, string> = {}) =>
	(p?: RoutesParam) =>
		Endpoint<never, TResponse>(DataRequestMethod.GET, makeUrl(url, p), headers)();

// eslint-disable-next-line @typescript-eslint/naming-convention
const DeletingEndpoint =
	<TResponse>(url: string | RoutesGetter, headers: Record<string, string> = {}) =>
	(p?: RoutesParam) =>
		Endpoint<never, TResponse>(DataRequestMethod.DELETE, makeUrl(url, p), headers)();

// eslint-disable-next-line @typescript-eslint/naming-convention
const PostingEndpoint =
	<TRequest, TResponse>(url: string | RoutesGetter, headers: Record<string, string> = {}) =>
	(p?: RoutesParam) =>
	(data?: TRequest) =>
		Endpoint<TRequest, TResponse>(DataRequestMethod.POST, makeUrl(url, p), headers)(data);

// eslint-disable-next-line @typescript-eslint/naming-convention
const PuttingEndpoint =
	<TRequest, TResponse>(url: string | RoutesGetter, headers: Record<string, string> = {}) =>
	(p?: RoutesParam) =>
	(data?: TRequest) =>
		Endpoint<TRequest, TResponse>(DataRequestMethod.PUT, makeUrl(url, p), headers)(data);

type Request<TRequest, TResponse = null> =
	| ((data?: TRequest) => Promise<TResponse>)
	| ((data: TRequest) => Promise<TResponse>);

export { Request, Endpoint, GettingEndpoint, DeletingEndpoint, PostingEndpoint, PuttingEndpoint };
