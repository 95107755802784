import { FirebaseOptions } from '@firebase/app';
import { useConfigDao } from '@/domain/dao/Config';

type RtConfig = {
	firebase: {
		firestoreDB?: string;
		throttleTime: number;
		encrypt: boolean;
		emulators: {
			useEmulator: boolean;
			auth: {
				host: string;
			};
			firestore: {
				host: string;
				port: number;
			};
		};
	};
	services: {
		firebase: FirebaseOptions;
	};
};

const useRtConfig = useConfigDao<RtConfig>;

export { useRtConfig, RtConfig };
