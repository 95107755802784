import { User } from '@/domain/models/User';

enum UserFaceSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

type UserAvatarData = Pick<User, 'firstName' | 'lastName' | 'img' | 'color' | 'initials'>;

type ChipStyle =
	| {
			backgroundColor: string;
			color: string;
	  }
	| {
			background: string;
			backgroundSize: string;
	  };

export { type ChipStyle, UserFaceSize, UserAvatarData };
