import { UserAvatarData } from '@/lib/components/User/UserFace.types';
import { color, HexColor } from '@/domain/models/Color';

const chipStyleForColor = (hexColor: HexColor) => ({
	backgroundColor: color(hexColor).value,
	color: color(hexColor).contrast,
});

const chipStyleForImage = (img: string) => ({
	background: `url("${img}") center center / contain no-repeat`,
	backgroundSize: 'contain',
});

const chipStyleForUser = (user: Pick<UserAvatarData, 'img' | 'color'>) =>
	user.img ? chipStyleForImage(user.img) : chipStyleForColor(user.color);

const chipStyle = (user: Pick<UserAvatarData, 'img' | 'color'>, colorOverwrite: HexColor | null = null) =>
	colorOverwrite ? chipStyleForColor(colorOverwrite) : chipStyleForUser({ ...user, img: null });

const faceStyle = (user: Pick<UserAvatarData, 'img' | 'color'>, colorOverwrite: HexColor | null = null) =>
	colorOverwrite ? chipStyleForColor(colorOverwrite) : chipStyleForUser(user);

export { chipStyle, faceStyle };
