import { collaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { consumerService } from '@/domain/services/ConsumerService';
import { entryFormService } from '@/modules/entry-form/EntryForm.service';
import store from '@/lib/store';
import { useServiceProvider } from '@/domain/services/Container';

const { boot, use } = useServiceProvider<{
	Submittable: () => ReturnType<typeof entryFormService>;
	Collaborators: () => ReturnType<typeof collaboratorsService>;
	Consumer: () => ReturnType<typeof consumerService>;
}>({
	Submittable: () => entryFormService(store),
	Collaborators: () => collaboratorsService(),
	Consumer: () => consumerService(store),
});

export { use as useEntryFormContainer, boot as bootEntryFormContainer };
