<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import FormBox from './FormBox.vue';
import CollaboratorsListModal from '@/lib/components/Collaboration/CollaboratorsListModal.vue';
import CollaboratorsInviteModal from '@/lib/components/Collaboration/CollaboratorsInviteModal.vue';

export default {
	components: {
		Deletism,
		Undeletism,
		ListAction,
		FormBox,
		CollaboratorsListModal,
		CollaboratorsInviteModal,
	},
	extends: ResourceList,
};
</script>
