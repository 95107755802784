<!--	eslint-disable vue/no-v-html -->
<template>
	<div class="judge-selector">
		<div :class="{ row: useColumns }">
			<div :class="{ 'col-xs-12 col-md-4': useColumns }">
				<radio-group
					v-if="showTypeSelector"
					id="type-selector"
					v-model="typeSelector"
					name="typeSelector"
					:radio-values="radioValues"
					:labels="judgingTypeLabels"
					:class="judgingTypeClass"
					@input="onTypeSelected"
				/>
				<input type="hidden" name="appliedJudges" :value="typeSelector === 'roles' ? 'roles' : 'invited'" />
				<div v-show="typeSelector === 'roles'">
					<multiselect
						id="judges-roles-selector"
						name="roleIds[]"
						:options="roles"
						:selected-options="selectedRoles"
						:placeholder="lang.get('panels.form.judges.placeholder.roles')"
						:select-all-label="lang.get('multiselect.select_all')"
						value-popover-placement="right"
						@selected="(roles) => onRolesSelected(roles)"
					/>
					<p
						v-if="guestRoleWarning"
						v-html="
							lang.get('panels.form.judges.guest_role_warning', {
								url: roleSettingsUrl,
							})
						"
					></p>
				</div>
				<div v-show="typeSelector === 'invited'">
					<component
						:is="searchComponent"
						id="judge-search"
						name="judge-search"
						:filters="judgeFilters"
						:label="searchLabel"
						:search-label="lang.get('buttons.search')"
						:reset-label="lang.get('panels.form.reset')"
						:url="url"
						:on-search="judgeSearchResults"
						:advanced-search="true"
						:advanced-search-label="lang.get('panels.form.advanced')"
						:search-keywords="keywords"
						@update:searchKeywords="keywords = $event"
						@reset="resetJudges"
					>
						<div class="form-group form-group-subordinate">
							<label for="role">
								{{ lang.get('panels.form.role.label') }}
							</label>
							<select-field id="role" name="role" :empty-option="true" :items="roles" @selected="selectJudgeFilter">
							</select-field>
						</div>
					</component>
				</div>
			</div>
		</div>
		<div :class="{ row: useColumns }">
			<div :class="{ 'col-xs-12 col-md-4': useColumns }">
				<div v-show="typeSelector === 'invited'" class="form-group">
					<multiselect
						:filter="false"
						:options="judgesForSearch"
						:selected-options="selectedJudgesForSearch"
						:select-all-label="lang.get('multiselect.select_all')"
						value-property="name"
						value-popover-placement="right"
						value-popover-property="title"
						:remember-selection="true"
						:counter="false"
						:randomizer="true"
						:randomizer-show="lang.get('multiselect.randomizer.show')"
						:randomizer-how-many="lang.get('multiselect.randomizer.how_many')"
						:randomizer-help-text="lang.get('multiselect.randomizer.helpicon-judges')"
						:randomizer-button="lang.get('multiselect.randomizer.button')"
						help-icon-class="af-icons af-icons-help"
						:show-nothing-found="searchWasRun && !judges.length"
						:nothing-found-label="lang.get('search.nothing-found')"
						@selected="changeSelectedJudges"
					/>
					<p v-if="judgesForSearch.length >= 1000" class="help-text">
						{{ lang.get('assignments.form.judges.capped') }}
					</p>
				</div>
				<div v-show="typeSelector === 'new'" class="form-group">
					<emails-box :show-button="true" @add="onAdd">
						<div class="mbl">
							<p>{{ lang.get('panels.form.judges.account_roles') }}</p>
							<div v-for="role in roles" :key="'role-' + role.id" class="checkbox styled">
								<input
									:id="`role-${role.id}`"
									v-model="selectedJudgesRoleIds"
									:name="`role[${role.id}]`"
									:value="role.id"
									type="checkbox"
									@change="onRolesChange"
								/>
								<label :for="`role-${role.id}`">
									{{ role.name }}
								</label>
							</div>
						</div>
					</emails-box>
				</div>
			</div>
			<div
				v-if="(typeSelector === 'invited' || typeSelector === 'new') && selectedJudges.length"
				:class="[useColumns ? 'col-xs-12 col-md-4' : 'form-group']"
			>
				<div class="selected-header">
					<label for="judgeIds">
						{{ lang.get('panels.form.selected-judges.header') }}
					</label>
					<div class="list-counter">
						{{ selectedJudges.length }}
					</div>
				</div>
				<panel :class="['selected-judges']">
					<removable-list
						:initial-items="selectedJudges"
						item-name="judgeIds[]"
						value-property="id"
						value-popover-placement="left"
						@change="selectJudges"
						@removed="judgeUnselected"
					>
					</removable-list>
				</panel>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'underscore';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import RadioGroup from '@/lib/components/Shared/RadioGroup';
import EmailsBox from '@/lib/components/Shared/EmailsBox';
import { Multiselect, Panel, SelectField } from 'vue-bootstrap';
import Judge from './Judge';
import RemovableList from './RemovableList';
import SearchPanel from './SearchPanel';
import SearchField from './SearchField';

export default {
	components: {
		EmailsBox,
		Judge,
		Multiselect,
		Panel,
		RadioGroup,
		RemovableList,
		SearchField,
		SearchPanel,
		SelectField,
	},
	mixins: [langMixin],
	props: {
		url: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'roles',
			validator: (value) => ['roles', 'invited'].indexOf(value) !== -1,
		},
		existingOrNew: {
			type: String,
			default: 'existing',
			validator: (value) => ['existing', 'new'].indexOf(value) !== -1,
		},
		initSelectedJudges: {
			type: Array,
			default: () => [],
		},
		judgingTypeClass: {
			type: String,
			default: null,
		},
		recommendRoles: {
			type: Boolean,
			default: false,
		},
		roles: {
			type: Array,
			default: () => [],
		},
		selectedRoles: {
			type: Array,
			default: () => [],
		},
		createNewJudges: {
			type: Boolean,
			default: false,
		},
		guestRoleWarning: {
			type: Boolean,
			default: false,
		},
		showTypeSelector: {
			type: Boolean,
			default: true,
		},
		searchLabel: {
			type: String,
			default: () => '',
		},
		showPanel: {
			type: Boolean,
			default: true,
		},
		roleSettingsUrl: {
			type: String,
			default: '/role',
		},
		useColumns: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isLoaded: false,
			searchWasRun: false,
			typeSelector: null,
			judgeFilters: {},
			judges: [],
			roleIds: [],
			selectedJudges: [],
			selectedJudgesRoleIds: [],
			keywords: '',
		};
	},
	computed: {
		radioValues() {
			return this.createNewJudges ? ['roles', 'invited', 'new'] : ['roles', 'invited'];
		},
		selectedJudgesIds() {
			return _.pluck(this.selectedJudges, 'id');
		},
		judgesForSearch() {
			return JSON.parse(JSON.stringify(this.judges));
		},
		selectedJudgesForSearch() {
			return JSON.parse(JSON.stringify(this.selectedJudgesIds));
		},
		judgingTypeLabels() {
			return {
				roles:
					this.lang.get('panels.form.judges.options.roles') +
					(this.recommendRoles ? ` (${this.lang.get('panels.form.judges.recommended')})` : ''),
				invited: this.lang.get('panels.form.judges.options.invited'),
				new: this.lang.get('panels.form.judges.options.new'),
			};
		},
		searchComponent() {
			return this.showPanel ? SearchPanel : SearchField;
		},
	},
	created() {
		this.typeSelector = this.existingOrNew === 'new' ? 'new' : this.type;
		this.selectedJudges = this.initSelectedJudges;
		this.roleIds = [...this.selectedRoles];

		// Fix: Prevent selected judges from getting emptied just after mounted
		this.$nextTick(() => {
			this.isLoaded = true;
		});
	},
	methods: {
		changeSelectedJudges(selectedJudgeIds) {
			if (!this.isLoaded) {
				return;
			}

			let newSelectedJudges = [];
			let oldSelectedJudges = JSON.parse(JSON.stringify(this.selectedJudges));
			for (let judge of oldSelectedJudges) {
				if (!this.isJudgeInSearchResult(judge.id)) {
					newSelectedJudges.push(judge);
				}
			}

			for (let judgeId of selectedJudgeIds) {
				newSelectedJudges.push(this.getJudgeById(judgeId));
			}

			this.$set(this, 'selectedJudges', this.sortJudges(newSelectedJudges));

			this.$emit('judges-selected', this.selectedJudges, this.selectedJudgesRoleIds);
		},
		sortJudges(judges) {
			return judges.sort((a, b) => ((a.name ? a.name : a.title) + '').localeCompare(b.name ? b.name : b.title));
		},
		judgeUnselected(judgeId) {
			this.selectedJudges = this.selectedJudges.filter((judge) => judge.id !== judgeId);
		},
		isJudgeInSearchResult(judgeId) {
			return this.judges.filter((judge) => judge.id === judgeId).length > 0;
		},
		isJudgeSelected(judgeId) {
			return this.selectedJudges.contains(judgeId);
		},
		judgeSearchResults(data) {
			this.isLoaded = false;

			this.judges = data;

			// Don't lose selected judges upon new search results
			this.$nextTick(() => {
				this.isLoaded = true;
				this.searchWasRun = true;
			});
		},
		getJudgeById(judgeId) {
			return this.judges.find((judge) => judge.id === judgeId);
		},
		resetJudges() {
			this.judges = [];
			this.judgeFilters = {};
			this.searchWasRun = false;
		},
		selectJudges(judges) {
			this.$set(this, 'selectedJudges', this.sortJudges(judges));

			this.$emit('judges-selected', this.selectedJudges, this.selectedJudgesRoleIds);
		},
		selectJudgeFilter(name, value) {
			this.$set(this.judgeFilters, name, value);
		},
		onAdd(emails) {
			let selectedJudges = JSON.parse(JSON.stringify(this.selectedJudges));

			emails.forEach((email) => {
				if (!selectedJudges.find((judge) => judge.title === email)) {
					selectedJudges.push({
						id: 'judge-' + Date.now(),
						title: email,
						name: null,
					});
				}
			});

			this.$set(this, 'selectedJudges', this.sortJudges(selectedJudges));

			this.$emit('judges-selected', this.selectedJudges, this.selectedJudgesRoleIds);
		},
		onRolesChange() {
			this.$emit('judges-selected', this.selectedJudges, this.selectedJudgesRoleIds);
		},
		onRolesSelected(roles) {
			this.roleIds = roles;
			this.$emit('roles-selected', this.roleIds);
		},
		onTypeSelected(type) {
			switch (type) {
				case 'roles':
					this.$emit('type-selected', 'roles', null);
					break;
				case 'invited':
					this.$emit('type-selected', 'invited', 'existing');
					break;
				case 'new':
					this.$emit('type-selected', 'invited', 'new');
					break;
				default:
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.judge-selector {
	.multiselect-option span.af-icons {
		vertical-align: middle;
		padding-bottom: 3px;
	}

	.search-panel {
		margin-bottom: 20px;
	}

	.selected-judges {
		overflow-y: auto;
		max-height: 202px;
		width: 100%;
		margin-top: 5px;
		margin-bottom: 0;
	}

	.selected-header {
		display: flex;
		justify-content: space-between;
	}

	.selected-header > * {
		display: inline-block;
		align-self: flex-end;
	}

	.list-counter {
		height: 38px;
		min-width: 40px;
		padding: 5px 10px;
		background: #333;
		border-radius: 2px;
		color: #fff;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
	}

	.multiselect-options {
		label {
			div {
				display: inline-block;
			}

			.glyphicon {
				top: 2px;
			}
		}
	}
}
</style>
