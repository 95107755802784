const promiseQueue = <T>(execute: (d: T) => Promise<unknown>) => {
	const queue: T[] = [];

	const queueWasEmpty = (): boolean => queue.length === 1;
	const queueIsEmpty = (): boolean => queue.length === 0;

	const runExecutor = () => {
		if (queueIsEmpty()) {
			return;
		}

		execute(queue[0]).then(
			() => {
				queue.shift();
				runExecutor();
			},
			() => {
				runExecutor();
			}
		);
	};

	return {
		push: (item: T) => {
			queue.push(item);
			if (queueWasEmpty()) {
				runExecutor();
			}
		},
	};
};

export { promiseQueue };
