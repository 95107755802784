import $ from 'jquery';
import { commentUIBus, CommentUISignals } from '@/lib/components/Comments/signals/Comments';

const emitSaveComment = () => commentUIBus.emit(CommentUISignals.SAVE_COMMENT);

const registerAutosaveCommentListeners = (elements = []) => {
	// Save comments on browser close or page refresh
	window.addEventListener('beforeunload', emitSaveComment, { once: true });

	// Save comments on pjax navigation
	$(document).one('pjax:beforeSend', () => emitSaveComment());

	// Add event listeners to passed elements
	elements.forEach((element) => element?.addEventListener('click', () => emitSaveComment()));

	// Clean up listeners after navigation ends (PJAX event)
	$(document).one('pjax:end', () => {
		commentUIBus.off(CommentUISignals.TYPING);
		window.removeEventListener('beforeunload', emitSaveComment);
		elements.forEach((element) => element.removeEventListener('click', () => emitSaveComment()));
	});
};

export { registerAutosaveCommentListeners, emitSaveComment };
