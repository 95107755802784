<template>
	<div>
		<fieldset>
			<legend class="sr-only">{{ field.title }}</legend>
			<div v-for="(option, index) in field.options" :key="optionId(option, index)" class="radio styled">
				<input
					:id="optionId(option, index)"
					v-model="selectedOptions"
					type="radio"
					:value="option.id"
					:disabled="disabledOrCollaborationLocked"
					:aria-required="field.required ? 'true' : 'false'"
					:aria-invalid="hasError"
					:aria-describedby="ariaDescribedby"
					@change="onInput(selectedOptions, true)"
				/>
				<label :for="optionId(option, index)">
					{{ option.text }}
				</label>
			</div>
		</fieldset>
	</div>
</template>

<script>
import Field from './Field.vue';

export default {
	extends: Field,
	data() {
		return {
			selectedOptions: this.value,
		};
	},
	watch: {
		value(value) {
			this.selectedOptions = value;
		},
	},
	methods: {
		optionId(option, index) {
			return `option-${this.field.slug}-${this._uid}-${index}`;
		},
	},
};
</script>
