import { getTrans } from '@/services/global/translations.interface';
import { computed, ComputedRef } from 'vue';

type Props = {
	disabled: boolean;
	downloadLink: string | null;
};

const actionMenuController = (props: Props) => {
	const lang = getTrans();

	const items: ComputedRef = computed(() => [
		{
			label: lang.get('files.actions.delete'),
			id: 'delete',
			disabled: props.disabled,
		},
		{
			label: lang.get('files.download'),
			id: 'download',
			url: props.downloadLink,
			class: 'ignore',
			disabled: props.downloadLink === null,
		},
		{
			label: lang.get('files.actions.replace'),
			id: 'replace',
			disabled: props.disabled,
		},
	]);

	return {
		items,
	};
};

export { actionMenuController, Props };
