<template>
	<select-field
		ref="zoneSelector"
		class="timezones"
		:value="value"
		:items="timezones"
		:empty-option="false"
		:disabled="disabled"
		@selected="valueChanged"
		@focus="onFocus"
		@blur="onBlur"
	></select-field>
</template>

<script>
import { SelectField } from 'vue-bootstrap';
import { runUnique } from '@/domain/utils/Decorator';

export default {
	components: { SelectField },
	data() {
		return {
			updateValue: null,
		};
	},
	props: {
		/**
		 * Timezones in format [{id: 1, name: 'Pacific/Midway'}, {id: 2, name: 'Pacific/Tahiti'}]
		 */
		timezones: {
			type: Array,
			required: true,
		},
		/**
		 * Initial timezone to use
		 */
		value: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		valueChanged(name, selected) {
			this.updateValue(selected);
		},
		onFocus() {
			this.$emit('focus');
		},
		onBlur() {
			this.$emit('blur');
		},
	},
	created() {
		this.updateValue = runUnique((selected) => {
			this.$emit('blur');
			this.$emit('update:value', selected);
		}, this.value);
	},
};
</script>
