import { Collaborator } from '@/domain/models/Collaborator';
import { CollaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { emit, off, on, EventCallback as SignalCallback } from '@/domain/services/LocalEventBus';

enum CollaborationUISignals {
	OPEN_COLLABORATORS_LIST = 'collaboration.open-collaborators-list',
	OPEN_INVITER = 'collaboration.open-inviter',
	LOCK = 'collaboration.lock',
	UPDATED_COLLABORATORS = 'collaboration.updated-collaborators',
	RELOAD = 'collaboration.firestore reconnect',
}

/**
 * These are contextual locking sugnal:
 * - collaboration.lock.on-<lockableId> is emitted when a lock is set for a particular lockable
 * - collaboration.lock.off-<lockableId> is emitted when a lock is released for a particular lockable
 *
 * these allow us to easily listen on locking signals in places they refer too ( lockables )
 */
type LockSignal<T extends string> = `${CollaborationUISignals.LOCK}.on-${T}`;
type UnlockSignal<T extends string> = `${CollaborationUISignals.LOCK}.off-${T}`;

const lockSignalFor = (lockableId: string): LockSignal<string> => `${CollaborationUISignals.LOCK}.on-${lockableId}`;

const unlockSignalFor = (lockableId: string): UnlockSignal<string> =>
	`${CollaborationUISignals.LOCK}.off-${lockableId}`;

type CollaborationUIPayloads = {
	[CollaborationUISignals.OPEN_COLLABORATORS_LIST]: {
		formSlug: string;
		submittableSlug: string;
		rebootFirebase: boolean;
	};
	[CollaborationUISignals.OPEN_INVITER]: CollaboratorsService;
	[CollaborationUISignals.LOCK]: { lockableId: string; locker: Collaborator | null };
	[CollaborationUISignals.UPDATED_COLLABORATORS]: { collaborators: Collaborator[]; myself: Collaborator | null };
	[CollaborationUISignals.RELOAD]: undefined;
} & {
	[K in LockSignal<string>]: { locker: Collaborator };
} & {
	[K in UnlockSignal<string>]: undefined;
};

type CollaborationUISignal = keyof CollaborationUIPayloads;

const collaborationUIBus = {
	emit: <T extends CollaborationUISignal>(signal: T, payload: CollaborationUIPayloads[T]) =>
		emit<CollaborationUIPayloads, T>(signal, payload),

	on: <T extends CollaborationUISignal>(signal: T, callback: SignalCallback<CollaborationUIPayloads[T]>) =>
		on<CollaborationUIPayloads, T>(signal, callback),

	off: <T extends CollaborationUISignal>(signal: T, id?: number) => off<T>(signal, id),
};

export {
	collaborationUIBus,
	CollaborationUISignal,
	CollaborationUISignals,
	CollaborationUIPayloads,
	lockSignalFor,
	unlockSignalFor,
};
