import { emptyDocumentService } from '@/domain/services/Collaboration/Document';
import { valueDataSource } from '@/domain/services/Collaboration/MutableDataSource';

/**
 * ValuesService works analogically to LockableService:
 * ( resources/assets/js/src/domain/services/Collaboration/Lockable.ts )
 *
 * it is used to manage values of lockables
 */
const valueServiceFactory = (submittableSlug?: string, formSlug?: string) => (lockableId: string) => {
	if (!submittableSlug || !formSlug) {
		return emptyDocumentService();
	}

	const values = valueDataSource(formSlug, submittableSlug, lockableId);

	const destroy = () => {
		values.destroy();
	};

	const subscribe = values.subscribe;

	const set = (value: unknown) => {
		values.set(value);
	};

	return { subscribe, set, destroy, init: set };
};

export { valueServiceFactory };
