const { mountVueComponent } = require('@/lib/mount-vue-component.js');

/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoThumbnails = require('../../lib/video-thumbnails.js');
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();
	videoThumbnails.setup('preview-container.video');

	import('@/lib/components/Video/VideoPlayer').then((videoPlayer) => {
		mountVueComponent('video-player', videoPlayer);
	});

	import('@/lib/components/Video/VideoModal.vue').then((videoModal) => {
		mountVueComponent('video-modal', videoModal);
	});

	/** Comments */
	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});
};
