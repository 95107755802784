import { LockableData } from '@/domain/services/Collaboration/MutableLogic';
import { User } from '@/domain/models/User';

type Lock = {
	user: string;
	deadline: number;
};

const lockableDatSourceMapper = {
	extractData: (lockable: LockableData | null) => lockable?.lock || null,
	prepareData: (lockable: LockableData, lock: Lock) => ({ ...lockable, lock }),
	rawProps: ['lock'] as (keyof LockableData)[],
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEADLINE = 1000 * 15;

const isExpired = (data: Lock) => data.deadline + DEADLINE < new Date().getTime();

const isLock = (data: unknown): data is Lock => {
	if (typeof data !== 'object' || data === null) {
		return false;
	}

	return 'user' in data && 'deadline' in data && typeof data.user === 'string' && typeof data.deadline === 'number';
};

const isLocked = (data?: Lock | null) => isLock(data) && !isExpired(data);

const belongsToUser = (consumer: User) => (data?: Lock | null) =>
	data ? isLocked(data) && data.user === consumer.slug : false;

const saveOrDelete = (consumer: User, lock: boolean) =>
	lock
		? {
				user: consumer.slug,
				deadline: new Date().getTime() + DEADLINE,
		  }
		: null;

/**
 * Transaction is used on every lock write to firebase
 * it checks if the lock is expired, belongs to the user or is a valid lock
 * and sets / deletes the lock accordingly
 */
const transactionForUser = (consumer: User) => (lock: boolean) => (data: Lock | null) => {
	if (!isLock(data)) {
		return saveOrDelete(consumer, lock);
	}

	if (belongsToUser(consumer)(data)) {
		return saveOrDelete(consumer, lock);
	}

	if (isExpired(data)) {
		return saveOrDelete(consumer, lock);
	}

	return data;
};

export { transactionForUser, isLocked, isExpired, isLock, belongsToUser, Lock, DEADLINE, lockableDatSourceMapper };
