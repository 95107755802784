import { Category, CategoryOption } from '@/domain/models/Category';

type Value<T = unknown> = T;

enum FieldResource {
	RESOURCE_ATTACHMENTS = 'Attachments',
	RESOURCE_FORMS = 'Entries',
	RESOURCE_USERS = 'Users',
	RESOURCE_CONTRIBUTORS = 'Contributors',
	RESOURCE_REFEREES = 'Referees',
}

type Field = {
	id: number;
	slug: string;
	categoryOption: CategoryOption;
	categories: Category[];
	resource: FieldResource;
};

export { type Field, type Value, FieldResource };
