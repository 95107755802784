<template>
	<div :id="id" :class="[type, 'loader']">
		<div class="spinner spinner-centered">
			<div class="cube1"></div>
			<div class="cube2"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'basic',
			validator: (value) => ['fullpage', 'basic'].indexOf(value) >= 0,
		},
		top: {
			type: Number,
			default: 50,
		},
	},
};
</script>

<style scoped>
.spinner-centered {
	margin: 0;
	left: 45%;
	top: v-bind(top + '%');
	/*left: 0;*/
}
</style>
