import { getCurrentTimestamp } from '@/domain/utils/Timer';

let lastActive = getCurrentTimestamp();
[
	'mousemove',
	'mousedown',
	'mouseup',
	'keydown',
	'keyup',
	'touchstart',
	'touchmove',
	'wheel',
	'mousewheel',
	'blur',
	'focus',
].forEach((event) => document.addEventListener(event, () => (lastActive = getCurrentTimestamp())));

const wasActiveIn = (timeout: number) => getCurrentTimestamp() - lastActive < timeout;

export { wasActiveIn };
