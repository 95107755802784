const { mountVueComponent } = require('@/lib/mount-vue-component.js');

var $ = require('jquery');
/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('@/lib/audio.js');
var Cards = require('@/lib/cards.js');
require('@/vendor/stickybits/jquery.stickybits.js');
require('tectoastr');
var VideoThumbnails = require('@/lib/video-thumbnails.js');
var isMobile = require('@/lib/navigation-is-mobile.js');
var store = require('@/lib/store/index.js').default;
const { registerAutosaveCommentListeners } = require('@/lib/autosaver-comment');

const Vue = require('vue');
const Preferences = require('./Preferences/Preferences.vue').default;
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards:not(.vertical-gallery)', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();
	videoThumbnails.setup('preview-container.video');

	const rows = document.querySelectorAll('.preferences');

	[].forEach.call(rows, (el) => {
		new Vue({
			el: el,
			name: 'PreferencePickApp',
			components: {
				preferences: Preferences,
			},
			store,
		});
	});

	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});
	}

	/**
	 * Autosave comments
	 */
	registerAutosaveCommentListeners(document.querySelectorAll('button.top-pick-preference'));

	import('@/lib/components/Video/VideoPlayer').then((videoPlayer) => {
		mountVueComponent('video-player', videoPlayer);
	});

	import('@/lib/components/Video/VideoModal.vue').then((videoModal) => {
		mountVueComponent('video-modal', videoModal);
	});

	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});
};
