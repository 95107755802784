import { collaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { provide } from 'vue';
import { useServiceProvider } from '@/domain/services/Container';

const { boot, use } = useServiceProvider({
	provide: () => provide,
	Collaborators: () => collaboratorsService(),
});

export { use as useManageCollaboratorsContainer, boot as bootManageCollaboratorsContainer };
