<template>
  <div>
    <popover :content="judge.title" trigger="hover">
      {{ judge.name }}
    </popover>
  </div>
</template>
<script>
import Popover from 'vue-bootstrap/src/Popover';

export default {
  components: {
    Popover
  },
  props: {
    judge: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('clicked', this.item.id);
    }
  }
};
</script>
