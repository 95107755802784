
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import {
	collaboratorsListModalController,
	Props,
	View,
} from '@/lib/components/Collaboration/CollaboratorsListModal.controller';
import { Modal, Dropdown } from 'vue-bootstrap';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import Reinvite from '@/lib/components/Collaboration/Reinvite.vue';
import UserFace from '@/lib/components/User/UserFace.vue';
import PrivilegeSelector from '@/lib/components/Collaboration/PrivilegeSelector.vue';
import CollaboratorActions from '@/lib/components/Collaboration/CollaboratorActions.vue';
import ConfirmationSimpleModal from '@/lib/components/Shared/ConfirmationSimpleModal.vue';
import Loader from '@/lib/components/Loader';

export default defineComponent<Props, View>({
	components: {
		CollaboratorActions,
		UserFace,
		CloseIcon,
		Modal,
		Dropdown,
		PrivilegeSelector,
		ConfirmationSimpleModal,
		Reinvite,
		Loader,
	},

	props: {},

	setup: useController(collaboratorsListModalController, 'collaboratorsListModalController'),
});
