import Vue from 'vue';
import _ from 'underscore';
import { generateColumnId, generateRowId } from './helpers';

const defaultConfiguration = () => {
  let columns = [];
  let rows = [];

  // Generate ids for a 3x3 table
  for (let i = 0; i < 3; i++) {
    columns.push(generateColumnId(columns));
    rows.push(generateRowId(rows));
  }

  return {
    columns: columns,
    rows: rows,
    filters: [
      {
        column: columns[0],
        row: null,
        rules: {
          type: 'label'
        }
      }
    ],
    dynamicRowsEnabled: false,
    calculations: []
  };
};

export default {
  setConfiguration(state, configuration) {
    if (_.isEmpty(configuration)) {
      configuration = defaultConfiguration();
    }

    state.columns = !_.isEmpty(configuration.columns)
      ? configuration.columns
      : [];
    state.rows = !_.isEmpty(configuration.rows) ? configuration.rows : [];
    state.filters = !_.isEmpty(configuration.filters)
      ? configuration.filters
      : [];
    state.dynamicRowsEnabled = configuration.dynamicRowsEnabled || false;
    state.calculations = !_.isEmpty(configuration.calculations) ? configuration.calculations : [];
  },

  setInput(state, input) {
    state.dynamicRows = !_.isEmpty(input.dynamicRows) ? input.dynamicRows : [];
    state.values = !_.isEmpty(input.values) ? input.values : {};
  },

  setColumns(state, columns) {
    state.columns = columns;
  },

  setRows(state, rows) {
    state.rows = rows;
  },

  setFilters(state, filters) {
    state.filters = filters;
  },

  enableDynamicRows(state, enabled) {
    state.dynamicRowsEnabled = enabled;
  },

  setCalculations(state, calculations) {
    state.calculations = calculations;
  },

  setTranslations(state, translations) {
    state.translations = translations;
  },

  addRow(state) {
    const existingRows = state.rows.concat(state.dynamicRows);
    state.rows.push(generateRowId(existingRows));
  },

  deleteRow(state, row) {
    state.rows = state.rows.filter(id => id !== row);
  },

  addDynamicRow(state) {
    const existingRows = state.rows.concat(state.dynamicRows);
    state.dynamicRows.push(generateRowId(existingRows));
  },

  deleteDynamicRow(state, row) {
    state.dynamicRows = state.dynamicRows.filter(id => id !== row);
  },

  /**
   * Expects payload:
   *
   * {
   *   position: 'left',
   *   from: 'column-dUWgF'
   * }
   */
  insertColumn(state, payload) {
    const index =
      payload.position === 'left'
        ? state.columns.indexOf(payload.from)
        : state.columns.indexOf(payload.from) + 1;

    state.columns.splice(index, 0, generateColumnId(state.columns));
  },

  deleteColumn(state, column) {
    state.columns = state.columns.filter(id => id !== column);
    state.filters = state.filters.filter(filter => filter.column !== column);
  },

  /**
   * Expects payload:
   *
   * {
   *   column: 'column-dUWgF',
   *   row: null,
   *   rules: { type: 'label' }
   * }
   */
  updateFilters(state, payload) {
    state.filters = state.filters.filter(
      filter => filter.column !== payload.column
    );
    state.filters.push(payload);
  },

  /**
   * Expects payload:
   *
   * {
   *   enable: true,
   *   column: 'column-dUWgF',
   *   calculation: 'sum'
   * }
   */
  updateCalculations(state, payload) {
    state.calculations = state.calculations.filter(filter => filter.column !== payload.column) || [];
    state.calculations.push(payload);
  },

  /**
   * Expects payload:
   *
   * {
   *   key: 'column-dUWgF',
   *   translated: { en_GB: 'Column A', de_DE: 'Spalte A' }
   * }
   */
  updateTranslations(state, payload) {
    for (const language in payload.translated) {
      if (!state.translations[language]) {
        Vue.set(state.translations, language, {});
      }

      Vue.set(
        state.translations[language],
        payload.key,
        payload.translated[language]
      );
    }
  },

  /**
   * Expects payload:
   *
   * {
   *   key: 'column-dUWgF',
   *   translated: { en_GB: 'Sum', de_DE: 'Summe' }
   * }
   */
  updateCalculationTranslations(state, payload) {
    for (const language in payload.translated) {
      if (!state.translations[language]) {
        Vue.set(state.translations, language, {});
      }

      Vue.set(state.translations[language], 'calculation:' + payload.key, payload.translated[language]);
    }
  },

  /**
   * Expects payload:
   *
   * {
   *   row: 'row-fDBaz',
   *   column: 'column-dUWgF',
   *   value: 'value'
   * }
   */
  setCellValue(state, payload) {
		if (!state.values[payload.row]) {
			Vue.set(state.values, payload.row, {});
		}

    if (!payload.value) {
      Vue.delete(state.values[payload.row], payload.column);

      if (Object.keys(state.values[payload.row]).length === 0) {
        Vue.delete(state.values, payload.row);
      }

      return;
    }

    Vue.set(state.values[payload.row], payload.column, payload.value);
  }
};
