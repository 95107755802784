import { Collaborator } from '@/domain/models/Collaborator';
import { User } from '@/domain/models/User';
import { useTimer } from '@/domain/utils/Timer';
import { vueData } from '@/domain/services/VueData';
import { wasActiveIn } from '@/domain/utils/Activity';
import { belongsToUser, DEADLINE, isLocked, transactionForUser } from '@/domain/services/Collaboration/LockableLogic';
import { collaborationUIBus, lockSignalFor, unlockSignalFor } from '@/domain/signals/Collaboration';
import dataSources, { removeCacheDataSource } from '@/domain/services/Rt/DataSource';

type Subscriber = (lockState: boolean, collaborator?: Collaborator) => void;

let unlocker = () => {};

const emptyLockableService = () => ({
	subscribe: () => {},
	set: () => {},
	destroy: () => {},
	onSetLock: () => {},
});

/**
 * LockableService is used as a property to lockable components i.e.:
 * - fields
 * - selectors
 * - resource name
 *
 * It is contextual i.e. and instance is created for each lockable component
 * and it exposes a common interface to:
 * - set a lock on the component it was instantiated for
 * - subscribe to lock changes
 * - destroy itself.
 *
 * On lock change it emits two domain signals:
 * - collaboration.lock.on-<lockableId>
 * - collaboration.lock.off-<lockableId>
 *
 * Internally it runs two concurrent timers:
 * - ping: tries to release someone elses lock
 * - refresh: keeping own lock alive
 */
const lockableServiceFactory = (submittableSlug?: string, formSlug?: string) => (lockableId: string) => {
	if (!submittableSlug || !formSlug) {
		return emptyLockableService();
	}

	const collaborators = dataSources.collaborators(formSlug, submittableSlug);
	const locks = dataSources.locks(formSlug, submittableSlug, lockableId);

	const transaction = transactionForUser(vueData.consumer as unknown as User);
	const isMine = belongsToUser(vueData.consumer as unknown as User);

	const ping = useTimer(() => {
		locks.set(null, true);
	});

	let alwaysActive = false;

	const refresh = useTimer(() => {
		if (alwaysActive || wasActiveIn(DEADLINE)) {
			locks.set(transaction(true), true);
			return true;
		}
	}, 0.8 * DEADLINE);

	const subs: Record<'me' | 'others', Subscriber[]> = {
		me: [],
		others: [],
	};

	locks.subscribe(async (lock) => {
		ping.stop();

		if (isLocked(lock)) {
			if (isMine(lock)) {
				subs.me.forEach((sub) => sub(true));
				return;
			}

			/**
			 * if lock was rised by someone other than me
			 * start ping timer trying to release it ( unless refreshed by locker )
			 *
			 * + emit domain lock signal
			 * + notify subscribers ( if registered with .subscribe() )
			 */
			ping.start((1.2 + Math.random() / 10) * DEADLINE);

			const locker = Object.values<Collaborator>(await collaborators.get()).find(
				(collaborator: Collaborator) => collaborator.user === lock?.user
			);

			if (locker !== undefined) {
				collaborationUIBus.emit(lockSignalFor(lockableId), { locker });
				subs.others.forEach((sub) => sub(true, locker));
			}

			return;
		}

		collaborationUIBus.emit(unlockSignalFor(lockableId), undefined);
		subs.others.forEach((sub) => sub(false));
		subs.me.forEach((sub) => sub(false));
	});

	const destroy = () => {
		ping.stop();
		refresh.stop();
		locks.set(transaction(false), true);
		locks.destroy();
		collaborationUIBus.off(lockSignalFor(lockableId));
		collaborationUIBus.off(unlockSignalFor(lockableId));
		subs.me = [];
		subs.others = [];
		removeCacheDataSource(locks.id);
	};

	const subscribe = (subscriber: (lockState: boolean, collaborator?: Collaborator) => void, forMyself = false) => {
		if (locks.isReadonly()) {
			return;
		}

		subs[forMyself ? 'me' : 'others'].push(subscriber);
	};


	/**
	 * store the lock to firebase using transaction
	 * ( see LockableLogic.transactionForUser )
	 *
	 * if the lock is being set to true, start refresh timer that will keep it up
	 * if the lock is being set to false, stop refresh timer so ping timer can take over
	 * alwaysActive flag is used to ignore activity check on file browse window open
	 */
	const set = (lockState: boolean, ignoreActivity = false) => {
		alwaysActive = ignoreActivity;
		refresh[lockState ? 'start' : 'stop']();
		locks.set(transaction(lockState), true);

		if (lockState === true) {
			unlocker();
			unlocker = () => locks.set(transaction(false), true);
			return;
		}

		unlocker = () => {};
	};

	return { subscribe, set, destroy };
};

type LockableService = {
	subscribe: (subscriber: (lockState: boolean, collaborator?: Collaborator) => void) => void;
	set: (lockState: boolean) => void;
	destroy: () => void;
};

export { LockableService, lockableServiceFactory, emptyLockableService };
