import { Consumer } from '@/domain/models/Consumer';
import { isString } from '@/domain/utils/TypePredicates';
import { Primitive } from '@/domain/utils/UtilityTypes';
import { truthy } from '@/legacy/Equality';
import { VueData, VueDataProvider } from '@/domain/services/VueDataProvider';

type Feature = {
	feature: string;
	enabled: boolean;
};

export const vueData = {
	get config() {
		return VueDataProvider.getGlobalData('config') as VueData<Primitive>;
	},

	get variables() {
		return VueDataProvider.getGlobalData('variables') as VueData;
	},

	get consumer() {
		return VueDataProvider.getGlobal('consumer', true, true) as Consumer;
	},

	// eslint-disable-next-line @typescript-eslint/naming-convention
	get CSRF_TOKEN() {
		const token = VueDataProvider.getGlobalData('CSRF_TOKEN', null);
		if (isString(token)) {
			return token;
		}

		return null;
	},

	get features() {
		const features = VueDataProvider.getGlobalData('features', []) as Feature[];
		const featuresRepo = {} as VueData<boolean>;
		features.forEach((feature: Feature) => {
			featuresRepo[feature.feature] = truthy(feature.enabled);
		});

		return featuresRepo;
	},

	get globals() {
		return VueDataProvider.getAllGlobalData() as VueData;
	},

	get links() {
		return VueDataProvider.getGlobalData('links') as VueData<string>;
	},

	get routes() {
		return VueDataProvider.getGlobalData('routes') as VueData<string>;
	},

	get settings() {
		return VueDataProvider.getGlobalData('settings', undefined) as VueData;
	},

	get translations() {
		return VueDataProvider.getGlobalData('translations') as VueData<string>;
	},

	get language() {
		return VueDataProvider.getGlobalData('language') as {
			locale: string;
			fallback: string;
		};
	},

	get defaultLanguage() {
		return VueDataProvider.getGlobal('defaultLanguage') as string;
	},

	get supportedLanguages() {
		return VueDataProvider.getGlobal('supportedLanguages', true) as string[];
	},
};
