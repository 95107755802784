import { Collaborator } from '@/domain/models/Collaborator';
import { EventEmitters } from '@/domain/utils/Events';

enum CollaboratorsListEvents {
	WantsUpdate = 'wants-update',
	Updated = 'updated',
}

type WantsUpdatePayload = {
	prompt: string;
	action: () => Promise<void>;
	cancel: () => void;
};

type CollaboratorsListEmiters = EventEmitters<{
	[CollaboratorsListEvents.WantsUpdate]: (p: WantsUpdatePayload) => void;
	[CollaboratorsListEvents.Updated]: (collaborators: Collaborator[]) => void;
}>;

export { CollaboratorsListEvents, CollaboratorsListEmiters, WantsUpdatePayload };
