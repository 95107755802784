import dataSources from '@/domain/services/Rt/DataSource';
import { vueData } from '@/domain/services/VueData';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { Collaborator, CollaboratorAccess, collaboratorAccess } from '@/domain/models/Collaborator';
import { InviteCollaboratorsForm, useApi } from '@/domain/services/Collaboration/Api';

type OnUpdate = () => void;

type CollaboratorsService = {
	getFormSlug: () => string;
	getSubmittableSlug: () => string;
	getAll: () => Collaborator[];
	getBySlug: (slug: string) => Collaborator | null;
	getMyself: () => Collaborator | null;
	owner: () => Collaborator | null;
	update: (collaborators: Collaborator[]) => void;
	onUpdate: (cb: OnUpdate) => void;
	refreshCollaborators: () => Promise<void>;
	destroy: () => void;
	invite: (form: InviteCollaboratorsForm) => Promise<Collaborator[]>;
	remove: (collaboratorSlug: string) => Promise<Collaborator[]>;
	setOwner: (collaboratorUserSlug: string) => Promise<Collaborator[]>;
	myAccess: () => Promise<CollaboratorAccess>;
};

const collaboratorsServices: Record<string, CollaboratorsService> = {};

collaborationUIBus.on(CollaborationUISignals.RELOAD, () => {
	Object.values(collaboratorsServices).forEach((service) => service.destroy());
	Object.keys(collaboratorsServices).forEach((key) => delete collaboratorsServices[key]);
});

const collaboratorsService =
	(reinstantiate = false) =>
	(formSlug: string, submittableSlug: string) => {
		if (reinstantiate === false && collaboratorsServices[`${formSlug}-${submittableSlug}`]) {
			return collaboratorsServices[`${formSlug}-${submittableSlug}`];
		}

		const consumer = vueData.consumer;
		let consumerAccess: CollaboratorAccess | null = null;

		const {
			getCollaborators: getCollaboratorsApi,
			inviteCollaborators,
			removeCollaborator,
			setOwner,
			clearCache,
		} = useApi(formSlug, submittableSlug);

		const collaboratorsDS = dataSources.collaborators(formSlug, submittableSlug);

		let callback: OnUpdate = () => {};

		const collaboratorsRepo = [] as Collaborator[];

		const getMyself = () => collaboratorsRepo.find((collaborator) => collaborator.user === consumer.slug) || null;

		const obCollaboratorsUpdate = (updated: Collaborator[] = []) => {
			collaboratorsRepo.splice(0, collaboratorsRepo.length, ...Object.values(updated || {}));
			clearCache();

			const myself = getMyself();
			consumerAccess = collaboratorAccess(myself);

			collaborationUIBus.emit(CollaborationUISignals.UPDATED_COLLABORATORS, {
				collaborators: updated,
				myself,
			});

			callback();
		};

		collaboratorsDS.get().then(obCollaboratorsUpdate);

		const destroy = collaboratorsDS.subscribe(obCollaboratorsUpdate);

		const getCollaborators = (fresh = false) =>
			getCollaboratorsApi(fresh).then((collaborators) => collaboratorsDS.set(collaborators));
		getCollaborators();

		const myAccess = async () => {
			if (consumerAccess !== null) {
				return consumerAccess;
			}

			const myself = (await getCollaboratorsApi()).find(
				(collaborator: Collaborator) => collaborator.user === vueData.consumer.slug
			);

			return collaboratorAccess(myself);
		};

		collaboratorsServices[`${formSlug}-${submittableSlug}`] = {
			getFormSlug: () => formSlug,
			getSubmittableSlug: () => submittableSlug,
			getAll: () => collaboratorsRepo,
			getBySlug: (slug: string) => collaboratorsRepo.find((collaborator) => collaborator.slug === slug) || null,
			getMyself: () => getMyself(),
			owner: () => collaboratorsRepo.find((collaborator) => collaborator.owner === true) || null,
			update: (c: Collaborator[]) => {
				collaboratorsDS.set(c);
			},
			onUpdate: (cb: OnUpdate) => {
				callback = cb;
			},
			// Force to fetch all collaborators to update the list and its data across all sessions.
			refreshCollaborators: () => getCollaborators(true),
			invite: inviteCollaborators,
			remove: removeCollaborator,
			setOwner,
			destroy,
			myAccess,
		};

		return collaboratorsServices[`${formSlug}-${submittableSlug}`];
	};

export { collaboratorsService, CollaboratorsService };
