/* eslint-disable @typescript-eslint/naming-convention */
var Vue = require('vue');
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoThumbnails = require('../../lib/video-thumbnails.js');
const { mountVueComponent } = require('@/lib/mount-vue-component');
var QuickManager = require('../../lib/components/QuickManager/QuickManager.vue').default;
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards:not(.vertical-gallery)', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();
	videoThumbnails.setup('preview-container.video');

	/**
	 * Quick Manager
	 */
	const quickManager = document.getElementById('quickManager');

	if (quickManager) {
		new Vue({
			el: quickManager,
			name: 'QuickManagerApp',
			components: {
				'quick-manager': QuickManager,
			},
		});
	}

	import('@/lib/components/Video/VideoPlayer').then((videoPlayer) => {
		mountVueComponent('video-player', videoPlayer);
	});

	import('@/lib/components/Video/VideoModal.vue').then((videoModal) => {
		mountVueComponent('video-modal', videoModal);
	});
};
