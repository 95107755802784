import { transactionForUser } from '@/domain/services/Collaboration/MutableLogic';
import { User } from '@/domain/models/User';
import { vueData } from '@/domain/services/VueData';
import dataSources, { removeCacheDataSource } from '@/domain/services/Rt/DataSource';

const valueDataSource = (formSlug: string, submittableSlug: string, lockableId: string) => {
	const datasource = dataSources.lockables(formSlug, submittableSlug, lockableId);
	const transaction = transactionForUser(vueData.consumer as unknown as User);

	return {
		subscribe: datasource.subscribe,
		get: datasource.get,
		set: (value: unknown) => {
			datasource.set(transaction(value));
		},
		destroy: () => {
			datasource.destroy();
			removeCacheDataSource(datasource.id);
		},
		remove: datasource.remove,
	};
};

export { valueDataSource };
