<template>
  <input
    v-model.number="localInput"
    type="number"
    :step="step"
    :disabled="disabled"
    :aria-label="label"
    @focusout="localInput = formattedInput"
		@focus="$emit('toggle', true)"
		@blur="$emit('toggle', false)"
  />
</template>

<script>
import validNumberMixin from './mixins/table-field-valid-number-mixin.js';

export default {
  mixins: [validNumberMixin],
  props: {
    input: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      required: true,
      validator: value =>
        ['integer', 'decimal', 'decimal-precise'].indexOf(value) !== -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localInput: this.input,
      numberFormats: {
        integer: { precision: 0, step: '1' },
        decimal: { precision: 1, step: '0.1' },
        'decimal-precise': { precision: 2, step: '0.01' }
      }
    };
  },
  computed: {
    precision() {
      return this.numberFormats[this.type].precision;
    },
    step() {
      return this.numberFormats[this.type].step;
    },
    formattedInput() {
      if (!this.validNumber(this.localInput)) {
        return null;
      }

      return parseFloat(this.localInput).toFixed(this.precision);
    }
  },
  watch: {
    localInput() {
      this.$emit('update:input', this.formattedInput);
    },
		input() {
			this.localInput = this.input;
		},
  }
};
</script>
