import { bootFirebase } from '@/domain/services/Rt/Firebase';
import { Collaborator } from '@/domain/models/Collaborator';
import { CollaboratorsService } from '@/domain/services/Collaboration/Collaborators';
import { featureEnabled } from '@/domain/dao/Features';
import { SubmittableTokens } from '@/domain/services/Rt/SubmittableTokens';
import { useApi } from '@/domain/services/Collaboration/Api';
import { useManageCollaboratorsContainer } from '@/lib/components/ListActions/ManageCollaboratorsProvider';
import { WantsUpdatePayload } from '@/lib/components/Collaboration/CollaboratorsListModal.events';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { computed, ComputedRef, Ref, ref, SetupFunction } from 'vue';
import { Trans, trans } from '@/domain/dao/Translations';

type Props = unknown;

type Confirmation = {
	isOpen: boolean;
	prompt: WantsUpdatePayload['prompt'];
	action: WantsUpdatePayload['action'] | null;
	cancel: WantsUpdatePayload['cancel'] | null;
};

type View = {
	visible: boolean;
	lang: Trans;
	isModalOpen: Ref<boolean>;
	onClose: () => void;
	collaborators: Ref<Collaborator[]>;
	canManage: (collaborator: Collaborator) => boolean;
	isOwnerOrManager: ComputedRef<boolean>;
	onUpdated: (updatedCollaborators: Collaborator[]) => void;
	onWantsUpdate: (payload: WantsUpdatePayload) => void;
	onInviteMore: () => void;
	confirmation: Ref<Confirmation>;
	collaboratorsService: Ref<CollaboratorsService | null>;
	showLoader: Ref<boolean>;
};

const collaboratorsListModalController: SetupFunction<Props, View> = () => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { Collaborators, onBeforeUnmount } = useManageCollaboratorsContainer();

	const collaboratorsService = ref<CollaboratorsService | null>(null);

	const isModalOpen = ref(false);

	const collaborators = ref<Collaborator[]>([]);
	const myself = ref<Collaborator | null>(null);

	const confirmation = ref<Confirmation>({
		isOpen: false,
		prompt: '',
		action: null,
		cancel: null,
	});

	const showLoader = ref(false);

	// eslint-disable-next-line no-unused-vars
	const openSignal = collaborationUIBus.on<CollaborationUISignals.OPEN_COLLABORATORS_LIST>(
		CollaborationUISignals.OPEN_COLLABORATORS_LIST,
		(payload) => {
			isModalOpen.value = true;
			showLoader.value = true;
			useApi(payload.formSlug, payload.submittableSlug)
				.getSubmittableTokens()
				.then(async (submittableTokens: SubmittableTokens) => {
					if (payload.rebootFirebase === true) {
						await bootFirebase(submittableTokens);
					}

					const cService = Collaborators(payload.formSlug, payload.submittableSlug);

					collaboratorsService.value = cService;

					collaborators.value = collaboratorsService.value.getAll();
					myself.value = collaboratorsService.value.getMyself();
					showLoader.value = false;
					collaboratorsService.value.onUpdate(() => {
						if (collaboratorsService.value !== null) {
							collaborators.value = collaboratorsService.value.getAll();
							myself.value = collaboratorsService.value.getMyself();
						}
					});
				});
		}
	);

	onBeforeUnmount(() => {
		collaborationUIBus.off(CollaborationUISignals.OPEN_COLLABORATORS_LIST, openSignal);
	});

	const isOwnerOrManager: ComputedRef<boolean> = computed(
		() => myself.value !== null && (myself.value?.owner || myself.value?.manager)
	);

	const canManage = (collaborator: Collaborator) => {
		if (collaborator.owner) {
			return false;
		}

		if (myself.value && myself.value.user === collaborator.user) {
			return false;
		}

		return isOwnerOrManager.value;
	};

	const onUpdated = (updatedCollaborators: Collaborator[]) => {
		if (collaboratorsService.value !== null) {
			collaboratorsService.value.update(updatedCollaborators);
		}
	};

	const onClose = () => {
		isModalOpen.value = false;
	};

	const onInviteMore = () => {
		onClose();
		if (collaboratorsService.value !== null) {
			collaborationUIBus.emit(CollaborationUISignals.OPEN_INVITER, collaboratorsService.value);
		}
	};

	// eslint-disable-next-line no-unused-vars
	const onWantsUpdate = ({ prompt, action, cancel }: WantsUpdatePayload) => {
		confirmation.value.isOpen = true;
		confirmation.value.prompt = prompt;
		confirmation.value.action = action || (() => {});
		confirmation.value.cancel = () => {
			confirmation.value.isOpen = false;
			if (cancel) {
				cancel();
			}
		};
	};

	return {
		visible: featureEnabled('collaboration'),
		lang: trans(),
		isModalOpen,
		onClose,
		collaborators,
		canManage,
		isOwnerOrManager,
		onUpdated,
		onWantsUpdate,
		onInviteMore,
		confirmation,
		collaboratorsService,
		showLoader,
	};
};

export { type Props, type View, collaboratorsListModalController };
