<template>
	<div class="panel panel-default search-panel">
		<div class="panel-body">
			<div v-if="label" class="panel-title">
				<h4>
					<label :for="id">{{ label }}</label>
				</h4>
			</div>
			<div class="keyword-search">
				<a
					v-if="advancedSearch"
					:class="['advanced-search', { active: filtersVisible }]"
					@click.prevent="filtersVisible = !filtersVisible"
				>
					{{ advancedSearchLabel }}
				</a>
				<input v-model="keywords" :name="name" class="form-control" @keydown.enter.prevent="search" />
			</div>
			<div v-show="filtersVisible">
				<slot></slot>
			</div>
			<button class="btn btn-secondary" @click.prevent="search">
				{{ searchLabel }}
			</button>
			<button class="btn btn-link reset" @click.prevent="reset">
				{{ resetLabel }}
			</button>
		</div>
	</div>
</template>

<script>
import Search from '@/lib/components/Judging/Search';

export default {
	extends: Search,
};
</script>
