import { AuthenticationStore } from '@/lib/store/modules/authentication';

const consumerService = (store: AuthenticationStore) => ({
	get consumer() {
		return store.state.authentication.consumer;
	},
	updateImage(image: string | null) {
		store.commit('authentication/updateConsumerImage', image);
	},
});

export { consumerService };
