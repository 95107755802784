import _ from 'lodash';
import dataSources, { Callback, Collections, DataSource } from '@/domain/services/Rt/DataSource';

const emptyDocumentService = <T>() => {
	const subscribers: Callback<T>[] = [];

	const subscribe = (callback: Callback<T>) => {
		subscribers.push(callback);
	};

	const set = (val: T | null) => {
		subscribers.forEach((callback) => callback(val));
	};

	const destroy = () => {
		subscribers.splice(0, subscribers.length);
	};

	return { subscribe, set, destroy, init: set };
};

const nullDocumentService = <T>() => ({
	/* eslint-disable no-unused-vars */
	set: (data?: T) => {},
	init: (data?: T) => {},
	/* eslint-enable no-unused-vars */
	subscribe: () => {},
	destroy: () => {},
});

type DocumentService = ReturnType<typeof emptyDocumentService>;

type DataSourceFactory = () => <T>(
	formSlug: string,
	submittableSlug: string,
	collectionId: Collections
) => DataSource<T>;

/**
 * Documents and collections are simple, non-lockable, non-transactional structures.
 * They are used to store:
 * - attachments ( w/o fields )
 * - links ( w/o fields )
 * - contributors ( w/o fields )
 * - collaborators
 */
const genericServiceFactory =
	(datasourceFactory: DataSourceFactory) =>
	<T>(submittableSlug?: string, formSlug?: string) =>
	(documentId: Collections) => {
		if (!submittableSlug || !formSlug) {
			return emptyDocumentService();
		}

		const document = datasourceFactory()(formSlug, submittableSlug, documentId);

		const destroy = document.destroy;
		const subscribe = document.subscribe;

		const set = _.debounce((newDocument: T) => {
			document.set(newDocument);
		}, 50);

		return { subscribe, set, destroy, init: set };
	};

const collectionServiceFactory = genericServiceFactory(() => dataSources.collection);
const documentServiceFactory = genericServiceFactory(() => dataSources.document);

export { collectionServiceFactory, documentServiceFactory, emptyDocumentService, nullDocumentService, DocumentService };
