/**
 * make a form data out of nested js object
 * this is used top preserve backend compatibility
 *
 * @param data
 * @param formData
 * @param parentKey
 * @returns {*}
 */
const buildFormData = function (data, formData, parentKey) {
  if(formData === undefined) formData = new FormData
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		if(parentKey !== undefined && parentKey.startsWith('values') && Object.keys(data).length === 0) {
			formData.append(parentKey, data);
			return formData;
		}
    Object.keys(data).forEach(key => {
      buildFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    let value;

    if (data == null) {
      value = '';
    } else if (data === true) {
      value = 1;
    } else if (data === false) {
      value = 0;
    } else {
      value = data;
    }

    formData.append(parentKey, value);
  }

  return formData
};

export default buildFormData;
