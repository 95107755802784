import { Contributor } from '@/domain/models/Contributor';
import { Link } from '@/domain/models/Link';
import { SubmittableUserRole } from '@/domain/models/Submittable';
import { Tab } from '@/domain/models/Tab';
import { Field, FieldResource } from '@/domain/models/Field';
import { PuttingEndpoint, Request } from '@/domain/services/Api/Endpoint';

enum SubmittableType {
	Entry = 'entry',
	GrantReport = 'grant-report',
}

type UpdateValueResponse<T> = {
	success: boolean;
	value: T;
};

type UrlSet = {
	updateField: (field: Field) => string;
	updateAttachment: (field: Field, id: string | number) => string;
	updateContributor: (field: Field, id: string | number) => string;
	updateRefereeField: (field: Field, id: string | number) => string;
	createLink: (tab: Tab) => string;
	deleteLink: (link: Link) => string;
	updateLink: (link: Link) => string;
	createContributor: (tab: Tab) => string;
	deleteContributor: (contributor: Contributor) => string;
	updateTitle: () => string;
	updateCategory: () => string;
	updateChapter: () => string;
};

type UrlSetFactory = (userRole: SubmittableUserRole, submittableSlug: string) => UrlSet;

const urlSetFactories: Record<SubmittableType, UrlSetFactory> = {
	[SubmittableType.Entry]: (userRole: SubmittableUserRole, submittableSlug: string) => ({
		updateField: (field: Field) => `/entry-form/${userRole}/entry/${submittableSlug}/update-field/${field.slug}`,
		updateAttachment: (field: Field, id: string | number) =>
			`/entry-form/${userRole}/entry/${submittableSlug}/update-attachment/${id}/${field.slug}`,
		updateContributor: (field: Field, id: string | number) =>
			`/entry-form/${userRole}/entry/${submittableSlug}/update-contributor/${id}/${field.slug}`,
		updateRefereeField: (field: Field, id: string | number) =>
			`/entry-form/${userRole}/entry/${submittableSlug}/update-referee/${id}/${field.slug}`,
		createLink: (tab: Tab) => `/entry-form/${userRole}/entry/${submittableSlug}/create-link/${tab.slug}`,
		deleteLink: (link: Link) => `/entry-form/${userRole}/entry/${submittableSlug}/delete-link/${link.id}`,
		updateLink: (link: Link) => `/entry-form/${userRole}/entry/${submittableSlug}/update-link/${link.id}`,
		createContributor: (tab: Tab) => `/entry-form/${userRole}/entry/${submittableSlug}/create-contributor/${tab.slug}`,
		deleteContributor: (contributor: Contributor) =>
			`/entry-form/${userRole}/entry/${submittableSlug}/remove-contributor/${contributor.id}`,
		updateTitle: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-title`,
		updateCategory: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-category`,
		updateChapter: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-chapter`,
	}),
	[SubmittableType.GrantReport]: (userRole: SubmittableUserRole, submittableSlug: string) => ({
		updateField: (field: Field) => `/grant-report/${userRole}/grant-report/${submittableSlug}/update-field/${field.slug}`,
		updateAttachment: (field: Field, id: string | number) =>
			`/grant-report/${userRole}/grant-report/${submittableSlug}/update-attachment/${id}/${field.slug}`,
		updateContributor: (field: Field, id: string | number) =>
			`/grant-report/${userRole}/grant-report/${submittableSlug}/update-contributor/${id}/${field.slug}`,
		updateRefereeField: (field: Field, id: string | number) =>
			`/grant-report/${userRole}/grant-report/${submittableSlug}/update-referee//${id}/${field.slug}`,
		createLink: (tab: Tab) => `/grant-report/${userRole}/grant-report/${submittableSlug}/create-link/${tab.slug}`,
		deleteLink: (link: Link) => `/grant-report/${userRole}/grant-report/${submittableSlug}/delete-link/${link.id}`,
		updateLink: (link: Link) => `/grant-report/${userRole}/grant-report/${submittableSlug}/update-link/${link.id}`,
		createContributor: (tab: Tab) =>
			`/grant-report/${userRole}/grant-report/${submittableSlug}/create-contributor/${tab.slug}`,
		deleteContributor: (contributor: Contributor) =>
			`/grant-report/${userRole}/grant-report/${submittableSlug}/remove-contributor/${contributor.id}`,
		updateTitle: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-title`,
		updateCategory: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-category`,
		updateChapter: () => `/entry-form/${userRole}/entry/${submittableSlug}/update-chapter`,
	}),
};

const updateField =
	<T>(urlSet: UrlSet) =>
	(field: Field, id?: string | number) => {
		const uri =
			{
				[FieldResource.RESOURCE_FORMS]: urlSet.updateField(field),
				[FieldResource.RESOURCE_ATTACHMENTS]: id === undefined ? null : urlSet.updateAttachment(field, id),
				[FieldResource.RESOURCE_CONTRIBUTORS]: id === undefined ? null : urlSet.updateContributor(field, id),
				[FieldResource.RESOURCE_REFEREES]: id === undefined ? null : urlSet.updateRefereeField(field, id),
				[FieldResource.RESOURCE_USERS]: null,
			}[field.resource] || null;

		return uri
			? (value: T) => PuttingEndpoint<{ value: T }, UpdateValueResponse<T>>(uri)()({ value })
			: () => Promise.resolve(null);
	};

const createLink = (urlSet: UrlSet) => (tab: Tab) =>
	PuttingEndpoint<never, UpdateValueResponse<number>>(urlSet.createLink(tab))()();

const deleteLink = (urlSet: UrlSet) => (link: Link) =>
	PuttingEndpoint<never, UpdateValueResponse<never>>(urlSet.deleteLink(link))()();

const updateLink = (urlSet: UrlSet) => (link: Link) =>
	PuttingEndpoint<Link, UpdateValueResponse<Link>>(urlSet.updateLink(link))()(link);

const createContributor = (urlSet: UrlSet) => (tab: Tab) =>
	PuttingEndpoint<never, UpdateValueResponse<number>>(urlSet.createContributor(tab))()();

const deleteContributor = (urlSet: UrlSet) => (contributor: Contributor) =>
	PuttingEndpoint<never, UpdateValueResponse<number>>(urlSet.deleteContributor(contributor))()();

const updateTitle = (urlSet: UrlSet) => (title: string) =>
	PuttingEndpoint<{ title: string }, UpdateValueResponse<string>>(urlSet.updateTitle())()({ title });

const updateCategory = (urlSet: UrlSet) => (categoryId: number) =>
	PuttingEndpoint<{ categoryId: number }, UpdateValueResponse<number>>(urlSet.updateCategory())()({ categoryId });

const updateChapter = (urlSet: UrlSet) => (chapterId: number) =>
	PuttingEndpoint<{ chapterId: number }, UpdateValueResponse<number>>(urlSet.updateChapter())()({ chapterId });

const useApi = (urlSetFactory: UrlSetFactory) => (submittableSlug: string, userRole: SubmittableUserRole) => {
	const urlSet = urlSetFactory(userRole, submittableSlug);

	return {
		updateField: updateField(urlSet),

		createLink: createLink(urlSet),
		deleteLink: deleteLink(urlSet),
		updateLink: updateLink(urlSet),

		createContributor: createContributor(urlSet),
		deleteContributor: deleteContributor(urlSet),

		updateTitle: updateTitle(urlSet),
		updateCategory: updateCategory(urlSet),
		updateChapter: updateChapter(urlSet),
	};
};

const useEntryFormApi = useApi(urlSetFactories[SubmittableType.Entry]);
const useGrantReportApi = useApi(urlSetFactories[SubmittableType.GrantReport]);

const emptyEndpoint = (value: unknown) => Promise.resolve({ success: true, value });

const emptyApi = new Proxy({} as ReturnType<typeof useEntryFormApi>, {
	get: () => emptyEndpoint,
});

type UpdateFieldEndpoint<T> = Request<T, UpdateValueResponse<T>>;

export { useEntryFormApi, useGrantReportApi, emptyApi, UpdateFieldEndpoint, UpdateValueResponse, emptyEndpoint };
