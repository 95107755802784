<template>
  <currency-input
    v-model="localInput"
    :currency="currency"
    :locale="currentLocale"
    :disabled="disabled"
    :aria-label="label"
    class="text-right"
    :placeholder="placeHolder"
    :distraction-free="false"
    @focusout="onFocusOut"
    @blur="onFocusOut"
		@focus="$emit('toggle', true)"
  />
</template>

<script>
import { CurrencyInput } from 'vue-currency-input';
import { calculateValue } from '@/lib/currencyUtils';

export default {
  inject: ['lang'],
  components: { CurrencyInput },
  props: {
    input: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localInput: calculateValue(this.input),
      currentLocale: this.lang.locale.replace('_', '-')
    };
  },
  computed: {
    placeHolder() {
      return Number(0)
        .toLocaleString(this.currentLocale, {
          style: 'currency',
          currency: this.currency,
          minimumFractionDigits: 0
        })
        .replace('0', '');
    }
  },
  watch: {
    localInput() {
      this.$emit(
        'update:input',
        this.localInput === null ? '' : this.localInput.toString()
      );
    },
		input() {
			this.localInput = calculateValue(this.input);
		},
  },
  methods: {
    onFocusOut() {
      this.localInput = calculateValue(this.localInput);
			this.$emit('toggle', false);
    },
  }
};
</script>
