type EventCallback<T> = (payload: T, id?: number) => void;

let nextId = 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener<T = any> = {
	id: number;
	callback: EventCallback<T>;
};

const listeners: Record<string, Listener[]> = {};

/**
 * Registers event listener. *
 * 	typed with event payloads and event name like so for example:
 * 	on<EventPayloads, Events.event1>(Events.event1, (payload) => { ... });
 *
 * 	where:
 * 		Events is enum of event names
 * 		EventPayloads is type of event payload types keyed by event names
 * 		i.e.:
 *
		enum Events {
			event1 = 'event-1',
			event2 = 'event-2',
			event3 = 'event-3',
		}

		type EventPayloads = {
			[Events.event1]: string;
			[Events.event2]: number;
			[Events.event3]: { foo: string, bar: number };
		};
 *
 * @param event - event name
 * @param callback - callback being registered ( inline with payload type )
 * @returns id of the listener
 */
const on = <E, N extends keyof E>(event: N, callback: EventCallback<E[N]>) => {
	if (!listeners[event as string]) {
		listeners[event as string] = [];
	}

	const id = nextId++;
	listeners[event as string].push({ id, callback: callback as EventCallback<E[N]> });

	return id;
};

/**
 * unregisters event listener based on
 * 		- event name
 * 		- listener id
 *
 * 	if listener id is not provided all listeners for given event are unregistered
 *
 * @param event - name of event to unregister
 * @param id - id of listener to unregister
 */
const off = <N>(event: N, id?: number) => {
	const eventListeners = listeners[event as string] as Listener[];

	if (id !== undefined && eventListeners !== undefined) {
		listeners[event as string] = eventListeners.filter((listener) => listener.id !== id);
	} else {
		delete listeners[event as string];
	}
};

/**
 * emits event with payload
 * 	typed with event payloads and event name like so for example:
 * 	emit<EventPayloads, Events.event1>(Events.event1, (payload) => { ... }); *
 *
 * callback is called with two parameters
 * 		- payload
 * 		- listener id
 *
 * @param event - name of event to emit
 * @param payload - payload to emit ( inline with payload type )
 */
const emit = <E, N extends keyof E>(event: N, payload: E[N]) => {
	const eventListeners = listeners[event as string] as Listener<E[N]>[];

	if (eventListeners !== undefined) {
		eventListeners.forEach((listener) => listener.callback(payload, listener.id));
	}
};

export { on, off, emit, EventCallback };
