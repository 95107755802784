import Base64 from '@/lib/base64.js';
import { isString } from '@/domain/utils/TypePredicates';

type VueData<T = unknown> = Record<string, T>;

let vueDataRepository: VueData;

const clarify = (data: string) => JSON.parse(Base64.decode(data));

const clarifyDecode = (data: string) => JSON.parse(decodeURIComponent(Base64.decode(data).replace(/\+/g, ' ')));

const bootGlobalData = (data?: VueData | undefined) => {
	vueDataRepository = data || {};
};

const getGlobal = (key: string, obfuscated = false, urlEncoded = false) => {
	const data = vueDataRepository?.[key];
	if (obfuscated && isString(data)) {
		return urlEncoded ? clarifyDecode(data) : clarify(data);
	}

	return data;
};

const getAllGlobalData = (): VueData | undefined => {
	const globalData = getGlobal('obfuscatedData');

	if (globalData != null) {
		try {
			return clarify(globalData);
		} catch (e) {
			console.warn(e);
		}
	}

	return undefined;
};

const getGlobalData = <T>(key: string, fallback?: T) => {
	const clarified = getAllGlobalData();

	if (clarified !== undefined) {
		if (clarified[key] === undefined) {
			if (fallback !== undefined) {
				return fallback;
			}

			console.error(`No ${key} registered in VueData`);
		} else {
			return clarified[key];
		}
	}

	return fallback || [];
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const VueDataProvider = {
	bootGlobalData,
	getGlobal,
	getGlobalData,
	getAllGlobalData,
};

export { VueDataProvider, VueData, clarify };
