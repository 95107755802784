<template>
	<currency-input
		:id="getId()"
		v-model="inputValue"
		class="form-control"
		:currency="field.currency"
		:locale="currentLocale"
		:distraction-free="false"
		:placeholder="placeHolder"
		:disabled="disabledOrCollaborationLocked"
		:aria-invalid="hasError"
		:aria-describedby="ariaDescribedby"
		:aria-required="field.required ? 'true' : 'false'"
		@input="onInput(inputValue)"
		@focusout="onFocusOut"
		@blur="onBlur"
		@focus="onFocus"
	/>
</template>

<script>
import Field from '@/lib/components/Fields/Field.vue';
import { CurrencyInput } from 'vue-currency-input';
import { calculateValue } from '@/lib/currencyUtils';

export default {
	inject: ['lang'],
	components: { CurrencyInput },
	extends: Field,
	data() {
		return {
			inputValue: calculateValue(this.value),
			currentLocale: this.lang.locale.replace('_', '-'),
		};
	},
	watch: {
		value() {
			this.inputValue = calculateValue(this.value);
		},
	},
	computed: {
		placeHolder() {
			if (this.field.currency) {
				return Number(0)
					.toLocaleString(this.currentLocale, {
						style: 'currency',
						currency: this.field.currency,
						minimumFractionDigits: 0,
					})
					.replace('0', '');
			}

			return null;
		},
	},
	methods: {
		onFocusOut() {
			this.inputValue = calculateValue(this.inputValue);
		},
		onBlur() {
			this.fieldService.locks.set(false);
		},
		onFocus() {
			this.fieldService.locks.set(true);
		},
	},
};
</script>
