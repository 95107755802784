<template>
  <div
    v-if="attachment && tab"
    :class="{
      fields: visibleAttachmentFields(attachment.tabId, attachment).length
    }"
  >
    <draggable-fields
      v-if="configurationMode"
      :fields="attachmentFields(attachment.tabId)"
      :visible-fields="visibleAttachmentFields(attachment.tabId, attachment)"
      :resource-name="resourceName"
      :resource="attachment"
      :group="group"
      :tab="tab"
      :lock-tabs="true"
    />
    <fields
      v-else
      :fields="visibleAttachmentFields(attachment.tabId, attachment)"
      :resource-name="resourceName"
      :resource="attachment"
      :tab="tab"
			:disabled="disabled"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    Fields: () => import('../../../modules/entry-form/Fields/Fields.vue'),
    DraggableFields: () =>
      import('../../../modules/entry-form/Configuration/DraggableFields')
  },
  props: {
    attachment: {
      type: Object,
      required: true
    },
		disabled: {
			type: Boolean,
			default: false
    }
  },
  computed: {
    ...mapGetters('global', ['lastModifiedField']),
    tab() {
      return this.tabById(this.attachment.tabId);
    },
    resourceName() {
      return 'Attachments';
    },
    group() {
      return 'attachment-' + this.attachment.id;
    },
    ...mapState('entryFormConfiguration', ['configurationMode']),
    ...mapGetters('entryForm', [
      'visibleAttachmentFields',
      'attachmentFields',
      'tabById'
    ])
  },
  mounted() {
    this.$nextTick(() => {
      let fields = Array.from(this.$el.querySelectorAll('[id^=' + this.lastModifiedField + ']:not([id$="-label"])'));
      if (fields.length) {
        fields[0].focus();
      }
    });
  }
};
</script>
