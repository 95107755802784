import { flipTranslations } from '@/lib/translations';
import { isNull } from '@/domain/utils/TypePredicates';
import { mapOptionsOrdered } from '@/lib/options';
import { transformFileTypes } from './helpers';

export default {
	isValid: (state) => Object.keys(state.errors).length === 0,

	allChapters: (state) =>
		state.chapters
			.map((chapter) => ({
				id: chapter.id,
				name: chapter.name,
				slug: chapter.slug,
			}))
			.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),

	activeChapters: (state) => state.chapters.filter((chapter) => (chapter.active || state.isManager) && chapter.inForm),

	isSubmittable: (state, getters) =>
		!!getters.userId && !state.locks.readOnly && !state.locks.previewMode && state.collaboratorAccess.canSubmit,

	isSaveable: (state, getters) =>
		(!state.visibleTitle || state.title) &&
		(!state.visibleSelectors || (state.chapterId && getters.categoryId && getters.rootCategoryId)) &&
		getters.userId &&
		getters.rootCategoryId &&
		!state.locks.readOnly &&
		!state.locks.previewMode &&
		state.collaboratorAccess.canSave,

	tempFile: (state) => (id) => state.tempFiles[id] || null,

	tabById: (state) => (tabId) => state.tabs.find((tab) => tab.id === tabId),

	isDirty: (state) => state.formEdited,

	entrySlug: (state) => (state.entry && state.entry.slug ? state.entry.slug : null),

	entryId: (state) => (state.entry && state.entry.id ? state.entry.id : null),

	entryUpdatedAt: (state) => (state.entry && state.entry.updatedAt ? state.entry.updatedAt : ''),

	userId: (state) => (state.user ? state.user.id : null),

	entryFieldValues: (state) => state.values['Entries'],

	getFields: (state) => state.fields,

	entryFieldTitles: (state) => Object.fromEntries(state.fields.map((field) => [field.slug, field.title])),

	isSelectedTabReadonly: (state, getters) => getters.selectedTab.readonly,

	tabs: (state, getters) =>
		state.tabs.map((tab, i) => ({
			...tab,
			invalidFields: getters.tabErrors(tab.id) > 0,
			disabled: (!(getters.entrySlug || i === 0) || (state.awaitingPayment && i > 0)) && !state.form.browsable,
			readonly: !(getters.entrySlug || i === 0) || (state.awaitingPayment && i > 0),
		})),

	tabErrors: (state) => (tabId) =>
		Object.keys(state.tabFieldsErrors[tabId] || {}).length > 0 || state.tabApiErrors.includes(tabId),

	detailsTab: (state, getters) => getters.tabs.find((tab) => tab.type === 'Details'),

	data: (state, getters) => ({
		...getters.formData,
		updatedAt: state.updatedAt || getters.entryUpdatedAt,
		entrytab: getters.selectedTab.slug,
	}),

	currentForm: (state) => ({ ...state.form }),

	formData: (state, getters) => ({
		seasonId: state.season.id,
		formId: state.form.id,
		formSessionUuid: state.formSessionUuid,
		userId: getters.userId,
		chapterId: state.chapterId,
		rootCategoryId: getters.rootCategoryId,
		categoryId: getters.categoryId,
		title: state.title,
		values: getters.entryFieldValues,
		...getters.attachmentsData,
		...getters.contributorsData,
		...getters.linksData,
		...getters.refereesData,
	}),

	fieldData: (state) => (field) => ({
		...field,
		formId: state.form.id,
		conditionalField: field.conditionalFieldId,
		options: field.options ? mapOptionsOrdered(field.options, 'id', 'score', 0) : '{}',
		seasonId: state.season.id,
		translated: flipTranslations(field.translated),
		translatedConfiguration: field.configurationTranslated
			? flipTranslations(JSON.parse(field.configurationTranslated))
			: null,
	}),

	tabData: (state) => (tab) => ({
		...tab,
		formId: state.form.id,
		setting: {
			/* eslint-disable @typescript-eslint/naming-convention */
			'max-filesize': tab.maxFilesize,
			'min-filesize': tab.minFilesize,
			'min-video-length': tab.minVideoLength,
			'max-video-length': tab.maxVideoLength,
			'max-attachments': tab.maxAttachments,
			'min-attachments': tab.minAttachments,
			'accept-attachment-links': tab.acceptAttachmentLinks,
			'ineligible-hide-tabs': tab.ineligibleHideTabs,
			'eligible-content-block': tab.eligibleContentBlock,
			'ineligible-content-block': tab.ineligibleContentBlock,
			'eligible-notification': tab.eligibleNotification,
			'ineligible-notification': tab.ineligibleNotification,
			'min-eligibility-score': tab.minEligibilityScore,
			'review-stage': tab.reviewStage,
			'min-referees': tab.minReferees,
			'max-referees': tab.maxReferees,
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		extensions: transformFileTypes(tab.attachmentAllowedFileTypes),
		seasonId: state.season.id,
		translated: flipTranslations(tab.translated),
	}),

	newCategoryData: (state) => (category, parent) => ({
		formId: state.form.id,
		chapterSlugs: category.chapterSlugs,
		parent: parent ? parent.slug : null,
		seasonId: state.season.id,
		status: category.active,
		translated: flipTranslations(category.translated),
		fileTokens: category.fileTokens,
		attachmentTypes: category.attachmentTypes || [],
		divisions: category.divisions,
		entrantMaxEntries: category.entrantMaxEntries,
		fillEntryName: category.fillEntryName,
		maxImageWidth: category.maxImageWidth,
		packingSlip: category.packingSlip,
		reassign: category.reassign,
		locked: category.locked,
		promoted: category.promoted,
	}),

	categoryData: (state) => (category, parent) => ({
		formId: state.form.id,
		attachmentTypes: category.attachmentTypes || [],
		chapterSlugs: category.chapterSlugs,
		divisions: category.divisions,
		entrantMaxEntries: category.entrantMaxEntries,
		fillEntryName: category.fillEntryName,
		maxImageWidth: category.maxImageWidth,
		parent: parent ? parent.slug : null,
		packingSlip: category.packingSlip,
		reassign: category.reassign,
		seasonId: state.season.id,
		status: category.active,
		locked: category.locked,
		translated: flipTranslations(category.translated),
		promoted: category.promoted,
	}),

	chapterData: (state) => (chapter) => ({
		active: chapter.active,
		managerIds: chapter.managerIds,
		maxImageWidth: chapter.maxImageWidth,
		seasonId: state.season.id,
		translated: flipTranslations(chapter.translated),
		formId: state.form.id,
		inForm: chapter.inForm,
	}),

	currentFormData: () => (form) => ({
		...form,
		translated: flipTranslations(form.translated),
	}),

	nextTabSlug: (state, getters) =>
		getters.visibleTabs[
			(state.tabId ? getters.visibleTabs.findIndex((tab) => tab.id === state.tabId) + 1 : 0) % getters.visibleTabs.length
		].slug,

	/**
	 * Prepares attachments data to be sent to server
	 */
	attachmentsData: (state) => {
		let data = {
			attachments: {},
			attachmentFields: {},
		};

		if (state.attachments) {
			Object.values(state.attachments).forEach((tabAttachments) =>
				tabAttachments.forEach((attachment) => {
					data.attachments[attachment.fileId] = attachment.id;
					data.attachmentFields[attachment.fileId] = {
						...(state.values['Attachments'][attachment.id] || []),
						tab: attachment.tabId,
					};
				})
			);
		}

		return data;
	},

	/**
	 * Prepares contributors data to be sent to server
	 */
	contributorsData: (state) => {
		let data = {};

		for (let tabId in state.contributors) {
			state.contributors[tabId].forEach((contributor) => {
				let contributorValues = {
					...state.values['Contributors'][contributor.id],
					tab: tabId,
				};

				if (`${contributor.id}`.match(/^new-.*$/)) {
					if (data['new'] === undefined) data['new'] = {};
					if (data['new'][tabId] === undefined) data['new'][tabId] = [];
					data['new'][tabId].push(contributorValues);
				} else {
					data[contributor.id] = contributorValues;
				}
			});

			const tabsContributor = state.tabs.find((item) => item.type === 'Contributors' && String(item.id) === String(tabId));

			const contributorTabLength = state.contributors[tabId].length;

			if (contributorTabLength === 0 || contributorTabLength < tabsContributor?.minContributors) {
				data[tabId] = { new: '' };
			}
		}

		return { contributors: data };
	},

	/**
	 * Prepares entry links data
	 */
	linksData: (state) => {
		const regex = /^new-.*$/;
		const links = { new: {} };
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const newLinks = { '-': { tabId: null, url: '', extra: '' } };

		let i = 1;

		Object.keys(state.links).forEach((tabId) => {
			state.links[tabId].forEach((link) => {
				if (!`${link.id}`.match(regex)) {
					links[link.id] = { tabId: tabId, url: link.url, extra: link.extra };
				}
			});

			state.links[tabId].forEach((link) => {
				if (`${link.id}`.match(regex) && link.url) {
					newLinks[i++] = { tabId: tabId, url: link.url, extra: link.extra };
				}
			});

			links['new'] = {
				...links['new'],
				...newLinks,
			};
		});

		return { links: links };
	},

	sponsors: (state, getters) => [...getters.chapterLogos, ...getters.categoryLogos],

	chapterLogos: (state) => {
		const chapter = state.chapters.find((c) => c.id === state.chapterId);

		if (!chapter) {
			return [];
		}

		const images = chapter.files.map((file) => ({
			key: `chapter-${chapter.id}-logo-${file.id}`,
			src: file.image,
			maxWidth: chapter.maxImageWidth,
		}));

		return [{ id: chapter.id, heading: chapter.heading, images }];
	},

	categoryLogos: (state, getters) => {
		const selectedCategory = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);

		let logos = [];

		const getLogos = (id) => {
			const category = getters.categoriesIndex.find((c) => c.id === id);

			if (category.parentId) {
				getLogos(category.parentId);
			}

			const images = category.files.map((file) => ({
				key: `category-${category.id}-logo-${file.id}`,
				src: file.image,
				maxWidth: category.maxImageWidth,
			}));

			logos = [...logos, { id: category.id, heading: category.heading, images }];
		};

		if (selectedCategory) {
			getLogos(selectedCategory.id);
		}

		return logos;
	},

	categoryEntryNameLabel: (state, getters) => {
		let category = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);
		return category && category.entryNameLabel ? category.entryNameLabel : '';
	},

	/**
	 * Get conditional fields.
	 */
	conditionalFields: (state) => (field) =>
		state.fields.filter(
			(conditionalField) => conditionalField.conditional && conditionalField.conditionalFieldId === field.id
		),

	/**
	 * Get categories.
	 */
	categories: (state, getters) => {
		let selectors = [];
		let sort = (c) => c.sort((a, b) => a.name.localeCompare(b.name));

		if (state.selectedCategoryId !== null && state.loadedChapters) {
			let leaf = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);
			if (leaf && leaf.hasChildren) {
				selectors = [
					{
						items: sort(getters.categoriesIndex.filter((c) => c.parentId === state.selectedCategoryId)),
					},
				];
			}

			while (leaf) {
				selectors.unshift({
					items: sort(getters.categoriesIndex.filter((c) => c.parentId === leaf.parentId)),
					current: leaf.id,
				});
				leaf = getters.categoriesIndex.find((c) => c.id === leaf.parentId);
			}
		} else {
			selectors = [{ items: sort(getters.categoriesIndex.filter((c) => c.parentId == null)) }];
		}

		selectors = selectors
			.filter((s) => s.items.length)
			.map((s, i) => ({
				key: i + '.' + Math.random(),
				...s,
				isChild: i > 0,
				isRoot: i === 0,
			}));

		if (selectors.length === 0) {
			selectors = [
				{
					key: 'placeholder',
					isRoot: true,
					items: [{ id: 1, name: '-' }],
					current: 1,
				},
			];
		}

		return selectors;
	},

	categoriesIndex: (state) => {
		if (state.chapterId && state.loadedChapters) {
			let chapterCategories = state.loadedChapters.find((c) => c.chapterId === state.chapterId);
			if (chapterCategories) {
				return chapterCategories.categories;
			}
		}

		return [];
	},

	/**
	 * Get selected category id or null if none has been selected yet
	 */
	categoryId: (state, getters) => {
		if (state.selectedCategoryId !== null) {
			let leaf = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);
			if (!leaf) {
				return null;
			}

			if (!leaf.hasChildren) return state.selectedCategoryId;
		}

		return null;
	},

	/**
	 * Get the selected category
	 */
	selectedCategory: (state, getters) =>
		getters.categoriesIndex.find((category) => category.id === state.selectedCategoryId),

	/**
	 * Get the selected chapter
	 */
	selectedChapter: (state) => state.chapters.find((chapter) => chapter.id === state.chapterId),

	/**
	 * Get root for selected category or null if none has been selected yet
	 */
	rootCategoryId: (state, getters) => {
		if (state.selectedCategoryId !== null && state.loadedChapters) {
			let leaf = getters.categoriesIndex.find((c) => c.id === state.selectedCategoryId);
			if (!leaf) {
				return null;
			}

			while (leaf.parentId) {
				leaf = getters.categoriesIndex.find((c) => c.id === leaf.parentId);
			}

			return leaf.id;
		}

		return null;
	},

	/**
	 * Get field value for a field.
	 */
	fieldValue: (state) => (field, resourceId) => {
		if (resourceId) {
			return (state.values[field.resource][resourceId] || {})[field.slug];
		} else {
			return state.values[field.resource][field.slug];
		}
	},

	/**
	 * Get compatible field types.
	 */
	compatibleTypes: (state) => (fieldType) => {
		const type = state.fieldCompatibility[fieldType];

		return type ? Object.keys(state.fieldCompatibility).filter((key) => state.fieldCompatibility[key] === type) : [];
	},

	/**
	 * Get visible tabs.
	 *
	 * Visibility determined by:
	 *
	 *   - selected category
	 */
	visibleTabs: (state, getters) => {
		const tabs = getters.tabs.filter((tab) => getters.tabIsVisible(tab));

		if (getters.eligibilityQuizActive && !getters.isEligible) {
			const eligibilityTabs = getters.eligibilityTabs.filter((tab) => tab.ineligibleHideTabs && !tab.eligible);
			if (eligibilityTabs.length) {
				return tabs.filter((tab) => tab.order <= eligibilityTabs[0].order);
			}
		}

		return tabs;
	},

	firstVisibleTab: (state, getters) => getters.visibleTabs[0],

	tabIsVisibleToManager: (state) => (tab) =>
		tab.categoryOption === 'all' ||
		(tab.categoryOption === 'select' && tab.categories.includes(state.selectedCategoryId)),

	visibleTabsForManager: (state, getters) =>
		getters.tabs
			.filter((tab) => getters.tabIsVisibleToManager(tab))
			.map((tab) => ({
				...tab,
				hidden: !tab.visibleToEntrants || !getters.visibleTabs.some((visibleTab) => visibleTab.id === tab.id),
				disabled: false,
			})),

	/*
    Loading an attachments tab without having an entry won't work
    as we wont have uploaderOptions set (where to assign the uploaded attachment to)
  */
	tabCanBeLoaded: (state) => (tab) => tab.type !== 'Attachments' || state.entry,

	tabIsVisible: (state) => (tab) =>
		tab.visibleToEntrants &&
		(tab.categoryOption === 'all' ||
			(tab.categoryOption === 'select' && tab.categories.includes(state.selectedCategoryId))),

	hasMultipleTabs: (state, getters) => getters.visibleTabs.length > 1,

	hasExistingEntry: (state, getters) => getters.entrySlug !== null,

	/**
	 * Return the selected tab.
	 */
	selectedTab: (state, getters, rootState) => {
		if (rootState['entryFormConfiguration'].configurationMode || rootState['entryForm'].isManager) {
			return state.tabs.find((tab) => tab.id === state.tabId) || state.tabs[0];
		}

		return getters.visibleTabs.find((tab) => tab.id === state.tabId) || getters.visibleTabs[0];
	},

	/**
	 * Check conditional visibility of a field.
	 */
	isVisible: (state) => (field, resourceId) => {
		if (!field.conditional) {
			return true;
		}

		if (resourceId) {
			return (state.conditionalVisibility[field.resource][resourceId] || {})[field.slug];
		} else {
			return state.conditionalVisibility[field.resource][field.slug];
		}
	},

	/**
	 * Check if the field is attached to the currently selected category.
	 */
	attachedToCategory: (state) => (field) =>
		field.categoryOption === 'all' ||
		(field.categoryOption === 'select' && field.categories.includes(state.selectedCategoryId)),

	/**
	 * Get visible entry fields.
	 *
	 * Visibility determined by:
	 *
	 *   - entrant read access
	 *   - conditional visibility
	 *   - selected tab
	 *   - selected category
	 */
	visibleEntryFields: (state, getters) => (tabId) =>
		state.fields.filter(
			(field) =>
				field.resource === 'Entries' &&
				(field.entrantReadAccess || state.isManager) &&
				getters.isVisible(field) &&
				field.tabId === tabId &&
				getters.attachedToCategory(field)
		),

	/**
	 * Get visible attachment fields.
	 */
	visibleAttachmentFields: (state, getters) => (tabId, attachment) =>
		state.fields.filter(
			(field) => field.resource === 'Attachments' && getters.isVisible(field, attachment.id) && field.tabId === tabId
		),

	/**
	 * Get visible contributor fields.
	 */
	visibleContributorFields: (state, getters) => (tabId, contributor) =>
		state.fields.filter(
			(field) =>
				field.resource === 'Contributors' &&
				getters.isVisible(field, contributor.id) &&
				field.tabId === tabId &&
				getters.attachedToCategory(field)
		),

	/**
	 * Get entry fields.
	 */
	entryFields:
		(state) =>
		(tabId = null) =>
			state.fields.filter((field) =>
				tabId !== null ? field.resource === 'Entries' && field.tabId === tabId : field.resource === 'Entries'
			),

	/**
	 * Get attachments for a tab.
	 */
	attachmentsByTab: (state) => (tabId) => (state.attachments || [])[tabId] || [],

	/**
	 * Get entry links for a tab.
	 */
	linksByTab: (state) => (tabId) => (state.links || [])[tabId] || [],

	/**
	 * Get attachment by file id.
	 */
	attachmentByFile: (state) => (fileId) => {
		for (let attachments of Object.values(state.attachments)) {
			for (let attachment of attachments) {
				if (attachment.fileId === fileId) {
					return attachment;
				}
			}
		}

		return null;
	},

	/**
	 * Get attachment fields.
	 */
	attachmentFields:
		(state) =>
		(tabId = null) =>
			state.fields.filter((field) =>
				tabId !== null ? field.resource === 'Attachments' && field.tabId === tabId : field.resource === 'Attachments'
			),

	/**
	 * Get contributor fields.
	 */
	contributorFields:
		(state) =>
		(tabId = null) =>
			state.fields.filter((field) =>
				tabId !== null ? field.resource === 'Contributors' && field.tabId === tabId : field.resource === 'Contributors'
			),

	/**
	 * Get contributors for a tab.
	 */
	contributors: (state) => (tabId) => state.contributors[tabId] || [],

	/**
	 * Get flattened contributors.
	 */
	flatContributors: (state) =>
		Object.keys(state.contributors).reduce((result, i) => result.concat(state.contributors[i]), []),

	/**
	 * Get the form loading status.
	 */
	isLoaded: (state, getters) => {
		if (getters.selectedTab === undefined || !state.loadedTabFields.includes(getters.selectedTab.id)) {
			return false;
		}

		if (state.entry) {
			return (
				state.loadedTabFields.includes(getters.selectedTab.id) &&
				state.tabsLoaded &&
				(getters.selectedTab.resource !== 'Attachments' || state.attachmentsLoaded) &&
				(getters.selectedTab.resource !== 'Contributors' || state.contributorsLoaded) &&
				(getters.selectedTab.resource !== 'Referees' || state.refereesLoaded)
			);
		} else {
			return state.tabsLoaded && state.loadedTabFields.includes(getters.selectedTab.id);
		}
	},

	/**
	 * Determine whether the user selector should be visible
	 */
	userSelectorVisible: (state) => state.isManager && !state.entry,

	/**
	 * Returns 'entrant' or 'manager' based on the value of the isManager flag
	 */
	formType: (state) => (state.isManager ? 'manager' : 'entrant'),

	/**
	 * Returns the type of submittable that is being managed by the form
	 */
	submittable: () => (window.location.href.search('grant-report') > 0 ? 'grant-report' : 'entry-form'),

	/**
	 * Checks if any Attachments tabs exist.
	 */
	hasAttachments: (state) => state.tabs.filter((tab) => tab.type === 'Attachments' && tab.createdAt).length > 0,

	/**
	 * Checks if any Contributors tabs exist.
	 */
	hasContributors: (state) => state.tabs.filter((tab) => tab.type === 'Contributors').length > 0,

	hasReferees: (state) => state.tabs.filter((tab) => tab.type === 'Referees').length > 0,

	allTabsLoaded: (state, getters) => getters.nextPendingTab === undefined,

	nextPendingTab: (state, getters) => {
		const tabs = state.isManager ? getters['visibleTabsForManager'] : getters['visibleTabs'];
		return tabs.filter((t) => getters.tabCanBeLoaded(t)).filter((t) => !state.loadedTabFields.includes(t.id))[0];
	},

	eligibilityTabs: (state, getters) =>
		state.tabs
			? state.tabs
					.filter((tab) => tab.type === 'Eligibility' && getters.tabIsVisible(tab))
					.sort((a, b) => {
						if (a.order < b.order) {
							return -1;
						}

						if (a.order > b.order) {
							return 1;
						}

						return 0;
					})
			: [],

	eligibilityQuizActive: (state, getters) =>
		getters.eligibilityTabs && getters.eligibilityTabs.some((tab) => tab.visibleToEntrants),

	isEligible: (state) => (state.entry ? state.entry.isEligible : false),

	hasEligibilityTab: (state, getters) => !!getters.eligibilityTabs.length,

	getEligibilityContentBlock: (state) => state.eligibilityContentBlock,

	lastTab: (state, getters) => {
		const tabs = state.tabs.filter((tab) => getters.tabIsVisible(tab));

		return tabs[tabs.length - 1];
	},

	lastEligibilityTab: (state, getters) =>
		getters.eligibilityTabs ? getters.eligibilityTabs.find((tab) => !tab.eligible) : null,

	tabEligibilityReadOnly: (state, getters) => (tab) =>
		getters.lastEligibilityTab !== undefined && tab.order > getters.lastEligibilityTab.order,

	isLastTab: (state, getters) => {
		const visibleTabs = state.tabs.filter((tab) => getters.tabIsVisible(tab));
		return visibleTabs.length > 0 && visibleTabs[visibleTabs.length - 1].id === getters.selectedTab.id;
	},

	isEligibilityTab: (state, getters) => getters.selectedTab.type === 'Eligibility',

	isEntryForm: (state) => state.form.type === 'entry',

	submittableRoute: (state) => (state.form.type === 'entry' ? 'entry-form' : 'grant-report'),

	inPreviewMode: (state) => state.locks.previewMode,

	/**
	 * Get Referee fields.
	 */
	refereeFields:
		(state) =>
		(tabId = null) =>
			state.fields.filter((field) =>
				!isNull(tabId) ? field.resource === 'Referees' && field.tabId === tabId : field.resource === 'Referees'
			),

	/**
	 * Get visible referee fields.
	 */
	visibleRefereeFields: (state, getters) => (tabId, referee) =>
		state.fields.filter(
			(field) =>
				field.resource === 'Referees' &&
				getters.isVisible(field, referee.id) &&
				field.tabId === tabId &&
				getters.attachedToCategory(field)
		),

	referees: (state) => (tabId) => state.referees[tabId] || [],

	refereesData: (state) =>
		Object.keys(state.referees).reduce(
			(result, tabId) => {
				const referees = state.referees[tabId];
				const tabReferees = referees.reduce((tabAcc, referee) => {
					const refereeFieldValues = state.values.Referees[referee.id] || {};
					const refereeData = {
						...referee,
						fields: { ...refereeFieldValues, tab: tabId },
						tabId: tabId,
					};

					tabAcc[referee.id] = `${referee.id}`.includes('new-') ? { ...refereeData, id: null } : refereeData;

					return tabAcc;
				}, {});

				result.referees[tabId] = tabReferees;
				result.referees.total[tabId] = referees.length; // Count of referees for each tabId

				return result;
			},
			{ referees: { total: {} } }
		),

	collaboratorAccess: (state) => state.collaboratorAccess,
};
