<template>
	<video-modal
		:source="file.source"
		:poster="file.image"
		:caption="file.caption?.source"
		:video-player-height="file.videoHeight"
	>
		<template #modal-trigger="{ open }">
			<div v-show="loading" class="preview">
				<div class="icon">
					<i class="af-icons-md af-icons-md-circle-processing af-icons-animate-rotate"></i><br />
					<span class="transcoding-status">
						{{ lang.get('files.transcoding.labels.ready') }}
					</span>
				</div>
			</div>
			<a v-show="!loading" target="_blank" rel="noopener noreferrer" class="preview" @click.prevent="open">
				<img v-if="!error" :src="file.image" alt="error" />
				<div class="icon">
					<i class="af-icons-md af-icons-md-circle-video"></i>
				</div>
			</a>
		</template>
	</video-modal>
</template>

<script>
import VideoModal from '@/lib/components/Video/VideoModal';

export default {
	inject: ['lang'],
	components: {
		VideoModal,
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
			error: false,
			height: 0,
		};
	},
	mounted() {
		this.preloadImage();
	},
	methods: {
		preloadImage() {
			const img = new Image();

			// Preload image
			img.onload = () => {
				this.height = img.height;
				this.loading = false;
			};

			img.onerror = () => {
				this.loading = false;
				this.error = true;
			};

			img.src = this.file.image;
		},
	},
};
</script>

<style scoped>
a {
	background-color: #fff !important;
}
</style>
