import { createDao } from '@/domain/utils/DataAccessor';
import { FeatureNames } from '@/domain/models/Feature';
import { vueData } from '@/domain/services/VueData';

/**
 * Gives access to features data passed from the backend
 * all features for an account are automatically passed
 * there's no need to register them manually on backend
 *
 * Features are non-nested, flat objects with boolean values
 * therefore type should always narrow Record<string, boolean>
 *
 * This DAO is typed with @/domain/models/Feature
 * to add new feature append to that type
 *
 * usage:

 const features = useFeaturesDao();
 if (features.saml === true){
 }
 */
const useFeaturesDao = () => createDao<FeatureNames>(vueData.features, false);

const featureEnabled = (feature: keyof FeatureNames) => {
	const dao = useFeaturesDao();
	if (dao[feature] === true) return true;
	if (dao[feature] === false) return false;
	throw new Error(`Feature ${feature as string} is not defined`);
};

export { useFeaturesDao, featureEnabled };
