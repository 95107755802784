<template>
	<div class="table-field-input-container">
		<table-field
			id
			name
			:configuration="field.configuration ? JSON.parse(field.configuration) : {}"
			:configuration-translated="field.configurationTranslated ? JSON.parse(field.configurationTranslated) : {}"
			:input="value ? JSON.parse(value) : {}"
			:language="field.language"
			:default-language="field.defaultLanguage"
			:labels="field.labels ? JSON.parse(field.labels) : {}"
			:disabled="disabledOrCollaborationLocked"
			@changed="onInput($event)"
			@rebuilt="onInput($event, true)"
			@toggle="fieldService.locks.set"
		></table-field>
	</div>
</template>

<script>
import Field from './Field.vue';
import TableField from './TableField.vue';

export default {
	components: {
		TableField,
	},
	extends: Field,
	data() {
		return {
			locked: false,
		};
	},
	created() {
		this.fieldService.locks.subscribe((lock) => (this.locked = lock));
	},
};
</script>
