<template>
	<div>
		<text-editor
			v-if="enableMarkdown"
			:id="getId()"
			:value="value"
			:markdown-guide-label="lang.get('miscellaneous.markdown_guide.label')"
			:disabled="disabledOrCollaborationLocked"
			:in-active-tab="inActiveTab"
			@input="onInput"
			@blur="onBlur"
			@focus="onFocus"
		/>
		<resizable-textarea
			v-else
			:id="getId()"
			:value="value"
			:disabled="disabledOrCollaborationLocked"
			:aria-invalid="hasError"
			:aria-describedby="ariaDescribedby"
			:required="field.required"
			@input="onInput"
			@blur="onBlur"
			@focus="onFocus"
		></resizable-textarea>
	</div>
</template>

<script>
import Field from '@/lib/components/Fields/Field.vue';
import ResizableTextarea from '@/lib/components/Shared/ResizableTextarea.vue';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';
import { useTimer } from '@/domain/utils/Timer';

export default {
	inject: ['lang'],
	components: {
		TextEditor,
		ResizableTextarea,
	},
	extends: Field,
	data() {
		return {
			blurTimer: useTimer(() => {
				this.validate();
				if (!this.disabledOrCollaborationLocked) {
					this.fieldService.locks.set(false);
				}

				this.focused = false;
			}, 300),
			focused: false,
		};
	},
	props: {
		enableMarkdown: {
			type: Boolean,
			default: false,
		},
		autosaveInterval: {
			type: Number,
			default: 5000, // value will be autosaved every 5 seconds
		},
	},
	methods: {
		onBlur() {
			this.blurTimer.start();
		},
		onFocus() {
			this.blurTimer.stop();

			// Block to prevent locking it twice
			if (this.focused) return;

			if (!this.disabledOrCollaborationLocked) {
				this.fieldService.locks.set(true);
			}

			this.focused = true;
		},
	},
};
</script>
