<script>
import ResourceList from '@/lib/components/ResourceList.vue';
import Deletism from '@/lib/components/ListActions/Deletism.vue';
import Undeletism from '@/lib/components/ListActions/Undeletism.vue';
import Archiver from '@/lib/components/ListActions/Archiver.vue';
import Unarchiver from '@/lib/components/ListActions/Unarchiver.vue';
import ListAction from '@/lib/components/ListActions/ListAction.vue';
import Moderator from '@/lib/components/ListActions/Moderator.vue';
import Tagger from '@/lib/components/ListActions/Tagger.vue';
import Reviewer from '@/lib/components/ListActions/Reviewer.vue';
import DuplicateArchiver from '@/lib/components/ListActions/DuplicateArchiver.vue';
import ContractCreator from '@/lib/components/ListActions/ContractCreator.vue';
import FundAllocator from '@/lib/components/ListActions/FundAllocator';
import Resubmission from '@/lib/components/ListActions/Resubmission';
import ScheduleGrantReport from '@/lib/components/ListActions/ScheduleGrantReport';
import GrantStatusSelector from '@/lib/components/ListActions/GrantStatusSelector';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin';
import AssignJudges from '@/lib/components/ListActions/AssignJudges';
import CreateDocument from '@/lib/components/ListActions/CreateDocument';
import CollaboratorsListModal from '@/lib/components/Collaboration/CollaboratorsListModal.vue';
import CollaboratorsInviteModal from '@/lib/components/Collaboration/CollaboratorsInviteModal.vue';

export default {
	components: {
		Deletism,
		Undeletism,
		Archiver,
		Unarchiver,
		ListAction,
		Moderator,
		Reviewer,
		Tagger,
		DuplicateArchiver,
		AssignJudges,
		CreateDocument,
		ContractCreator,
		FundAllocator,
		Resubmission,
		GrantStatusSelector,
		ScheduleGrantReport,
		CollaboratorsListModal,
		CollaboratorsInviteModal,
	},
	extends: ResourceList,
	mixins: [langMixin],
	computed: {
		taggerRevealed() {
			return this.reveal === 'tagger';
		},
		untaggerRevealed() {
			return this.reveal === 'untagger';
		},
		reviewerRevealed() {
			return this.reveal === 'reviewer';
		},
		duplicateArchiverRevealed() {
			return this.reveal === 'duplicate-archiver';
		},
		assignJudgesRevealed() {
			return this.reveal === 'assign-judges';
		},
		createDocumentRevealed() {
			return this.reveal === 'create-document';
		},
		contractCreatorRevealed() {
			return this.reveal === 'contract-creator';
		},
		fundAllocatorRevealed() {
			return this.reveal === 'fund-allocator';
		},
		grantStatusSelectorRevealed() {
			return this.reveal === 'grant-status-selector';
		},
		scheduleGrantReportRevealed() {
			return this.reveal === 'schedule-grant-report';
		},
	},
};
</script>
