import { useTimer } from '@/domain/utils/Timer';

const useMultiFocus = (callback: (state: boolean) => void) => {
	const focus: Record<string, boolean> = {};

	let oldState = false;

	const hysteresis = useTimer(() => {
		const newState = Object.values(focus).includes(true);
		if (newState === oldState) return false;
		oldState = newState;
		callback(oldState);
		return false;
	}, 100);

	const set = (state: boolean, key: string) => {
		focus[key] = state;
		hysteresis.start();
	};

	return {
		set,
		reset: () => Object.keys(focus).forEach((key) => delete focus[key]),
	};
};

export { useMultiFocus };
