
import { Collaborator } from '@/domain/models/Collaborator';
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { reinviteController, Props, View } from '@/lib/components/Collaboration/Reinvite.controller';

export default defineComponent<Props, View>({
	props: {
		collaborator: {
			type: Object as Collaborator,
			required: true,
		},
	},

	setup: useController(reinviteController, 'reinviteController') as View,
});
