<template>
	<textarea
		v-model="currentValue"
		:class="['form-control', 'ignore']"
		:placeholder="placeholder"
		:aria-required="required ? 'true' : 'false'"
		@blur="onBlur"
		@focus="onFocus"
		@input="onInput"
	></textarea>
</template>

<script>
import autosize from 'autosize';

export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			currentValue: this.value,
		};
	},
	watch: {
		value(value) {
			this.currentValue = value;
		}
	},
	mounted() {
		autosize(this.$el);
	},
	methods: {
		onInput(event) {
			autosize(event.target);
			this.$emit('input', this.currentValue);
		},
		onBlur() {
			this.$emit('blur');
		},
		onFocus() {
			this.$emit('focus');
		},
	},
};
</script>

<style scoped>
textarea {
	max-height: none;
}
</style>
