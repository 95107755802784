import DOMPurify from 'dompurify';

function stripTags(string) {
	return DOMPurify.sanitize(string, { ALLOWED_TAGS: ['b', 'i'] });
}

function toCamelCase(sentence) {
	return sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (camelCaseMatch, i) {
		if (+camelCaseMatch === 0) return '';
		return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
	});
}

function toSnakeCase(sentence) {
	return sentence
		.replace(/([A-Z])/g, (match, uppercaseLetter, index) => (index > 0 ? '_' : '') + uppercaseLetter)
		.toLowerCase();
}

export { stripTags, toCamelCase, toSnakeCase };
