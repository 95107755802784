<template>
	<div>
		<slot name="modal-trigger" :open="open" />
		<modal
			id="video-player-modal"
			v-model="isModalVisible"
			:header="false"
			:footer="false"
			:header-close-button="false"
			@closed="onClose"
		>
			<close-icon slot="before-content" @click.prevent="onClose"></close-icon>
			<video-player
				ref="videoPlayer"
				:source="source"
				:caption="caption"
				:poster="poster"
				:style-object="styleObject"
			></video-player>
		</modal>
	</div>
</template>

<script>
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon';
import { Modal } from 'vue-bootstrap';
import VideoPlayer from '@/lib/components/Video/VideoPlayer.vue';

export default {
	components: {
		VideoPlayer,
		CloseIcon,
		Modal,
	},
	props: {
		source: {
			type: String,
			required: true,
		},
		poster: {
			type: String,
			default: '',
		},
		modalVisible: {
			type: Boolean,
			default: false,
		},
		caption: {
			type: String,
			default: null,
		},
		videoPlayerHeight: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			isModalVisible: false,
		};
	},
	computed: {
		videoId() {
			return 'modal-video-' + this.fileId;
		},
		styleObject() {
			return {
				display: 'block',
				margin: 'auto',
				maxHeight: `${this.videoPlayerHeight}px`,
				minHeight: `min(${this.videoPlayerHeight}px, 95vh)`,
				width: 'auto',
				maxWidth: '100vw',
			};
		},
	},
	methods: {
		open() {
			this.isModalVisible = true;
		},
		onClose() {
			this.$refs.videoPlayer.player.pause();
			this.isModalVisible = false;
		},
	},
};
</script>
