import { User } from '@/domain/models/User';
import { vueData } from '@/domain/services/VueData';

enum CollaboratorPrivilege {
	VIEWER = 'viewer',
	EDITOR = 'editor',
	SUBMITTER = 'submitter',
}

type CollaboratorAccess = {
	canEdit: boolean;
	canSave: boolean;
	canSubmit: boolean;
};

type Collaborator = User & {
	user: string;
	manager: boolean;
	owner: boolean;
	privilege: CollaboratorPrivilege;
};

const defaultCollaboratorAccess: CollaboratorAccess = { canEdit: true, canSave: true, canSubmit: true };

const fullCollaboratorAccess: CollaboratorAccess = { canEdit: true, canSave: true, canSubmit: true };

const collaboratorAccess = (collaborator?: Collaborator | null): CollaboratorAccess => {
	if (!collaborator) {
		return defaultCollaboratorAccess;
	}

	if (collaborator.owner) {
		return fullCollaboratorAccess;
	}

	if (collaborator.manager && vueData.variables?.selectedContext === 'manage') {
		return fullCollaboratorAccess;
	}

	const canEdit: boolean = collaborator.privilege !== CollaboratorPrivilege.VIEWER;
	const canSubmit: boolean = collaborator.privilege === CollaboratorPrivilege.SUBMITTER;
	const canSave: boolean = canEdit;

	return { canEdit, canSave, canSubmit } as CollaboratorAccess;
};

export { Collaborator, CollaboratorPrivilege, CollaboratorAccess, collaboratorAccess, defaultCollaboratorAccess };
