type HexColor = `#${string}`;

type RGB = {
	r: number;
	g: number;
	b: number;
};

type Color = {
	value: HexColor;
	contrast: '#000000' | '#ffffff';
	rgb: RGB;
};

const hexColor2RGB = (hex: string): RGB => ({
	r: parseInt(hex.slice(0, 2), 16),
	g: parseInt(hex.slice(2, 4), 16),
	b: parseInt(hex.slice(4, 6), 16),
});

const hexColor2Contrast = (hexColor: string): '#000000' | '#ffffff' => {
	const rgb: RGB = hexColor2RGB(hexColor);
	if (!rgb) {
		return '#000000';
	}

	const brightness = (299 * rgb.r + 587 * rgb.g + 114 * rgb.b) / 1000;

	return brightness > 128 ? '#000000' : '#ffffff';
};

const normalize = (hexColor: HexColor) => {
	const hex = hexColor.replace(/[^a-f0-9]/gi, '');

	if (hex.length === 6) {
		return hex;
	}

	if (hex.length === 3) {
		return `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	throw new Error(`Invalid hex color: ${hexColor}`);
};

const color = (hexColor: HexColor): Color => {
	const value = normalize(hexColor);
	const contrast = hexColor2Contrast(value);
	const rgb = hexColor2RGB(value);
	return { value: `#${value}`, contrast, rgb };
};

export { color, Color, HexColor, RGB, hexColor2Contrast, hexColor2RGB };
