import { emit, off, on, EventCallback as SignalCallback } from '@/domain/services/LocalEventBus';

enum SubmittableFormSignal {
	SUBMITTED = 'submittable-form.submitted',
}

type SubmittableFormSignalsPayloads = {
	[SubmittableFormSignal.SUBMITTED]: {
		submittedUserSlug: string;
		refreshPage: boolean;
	};
};

const submittableFormBus = {
	emit: <T extends SubmittableFormSignal>(signal: T, payload: SubmittableFormSignalsPayloads[T]) =>
		emit<SubmittableFormSignalsPayloads, T>(signal, payload),

	on: <T extends SubmittableFormSignal>(signal: T, callback: SignalCallback<SubmittableFormSignalsPayloads[T]>) =>
		on<SubmittableFormSignalsPayloads, T>(signal, callback),

	off: <T extends SubmittableFormSignal>(signal: T, id?: number) => off<T>(signal, id),
};

export { submittableFormBus, SubmittableFormSignal, SubmittableFormSignalsPayloads };
