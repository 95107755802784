<template>
	<div v-if="showDeleteButton || showRetryButton">
		<div>
			<span class="pull-left">
				<slot name="label"></slot>
			</span>
			<span class="upload-actions">
				<a v-if="fieldRequired" class="delete" @click.prevent="$emit('replaced', file)">
					{{ lang.get('files.actions.replace') }}
				</a>
				<a v-else-if="!captionMode" class="delete" @click.prevent="$emit('deleted', file)">
					{{ lang.get('files.actions.delete') }}
				</a>
				<span v-if="showRetryButton">
					|
					<a class="delete" @click.prevent="$emit('retry', file)">
						{{ lang.get('files.actions.retry') }}
					</a>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
import ActionMenu from '@/modules/document-templates/components/Uploader/ActionMenu.vue';

export default {
	components: { ActionMenu },
	inject: ['lang'],
	props: {
		file: {
			type: Object,
			required: true,
		},
		captionMode: {
			type: Boolean,
			default: false,
		},
		fieldRequired: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showDeleteButton() {
			return (
				['completed', 'ok'].includes(this.file.status) ||
				(this.file.status === 'failed' && this.captionMode) ||
				this.file.statusMessage === 'virus-found'
			);
		},
		showRetryButton() {
			return this.file.status === 'failed';
		},
	},
};
</script>
