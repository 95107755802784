import { featureEnabled } from '@/domain/dao/Features';
import { Form } from '@/domain/models/Form';
import { SetupFunction } from 'vue';
import { Submittable } from '@/domain/models/Submittable';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { trans, Trans } from '@/domain/dao/Translations';

type Props = {
	submittable: Submittable;
	form: Form;
};

type View = {
	visible: boolean;
	lang: Trans;
	openCollaboratorsModal: () => void;
};

const manageCollaboratorsController: SetupFunction<Props, View> = (props: Props): View => ({
	lang: trans(),
	visible: props.submittable.form.collaborative && featureEnabled('collaboration'),
	openCollaboratorsModal: () => {
		collaborationUIBus.emit(CollaborationUISignals.OPEN_COLLABORATORS_LIST, {
			formSlug: props.form.slug,
			submittableSlug: props.submittable.slug,
			rebootFirebase: true,
		});
	},
});

export { Props, View, manageCollaboratorsController };
