
import { defineComponent } from 'vue';
import EmailsBox from '@/lib/components/Shared/EmailsBox.vue';
import TextEditor from '@/lib/components/Shared/editor/TextEditor.vue';
import { useController } from '@/domain/services/Composer';
import { collaboratorsInviteModalController, Props, View } from './CollaboratorsInviteModal.controller';
import { Modal, SelectField } from 'vue-bootstrap';
import CloseIcon from '@/lib/components/ListActions/Partials/CloseIcon.vue';
import ValidationErrors from '@/lib/components/Shared/ValidationErrors.vue';

export default defineComponent<Props, View>({
	components: {
		ValidationErrors,
		Modal,
		TextEditor,
		SelectField,
		EmailsBox,
		CloseIcon,
	},
	props: {},
	setup: useController(collaboratorsInviteModalController, 'collaboratorsInviteModalController') as () => View,
});
