import { useApi } from '@/lib/components/Collaboration/PrivilegeSelector.api';
import { Collaborator, CollaboratorPrivilege } from '@/domain/models/Collaborator';
import {
	CollaboratorsListEmiters,
	CollaboratorsListEvents,
} from '@/lib/components/Collaboration/CollaboratorsListModal.events';
import { computed, ComputedRef, Ref, ref, SetupFunction } from 'vue';
import { Trans, trans } from '@/domain/dao/Translations';

type Props = {
	collaborator: Collaborator;
	canManage: boolean;
};

type View = {
	lang: Trans;
	loading: Ref<boolean>;
	privileges: {
		id: CollaboratorPrivilege;
		name: string;
	}[];
	currentPrivilege: ComputedRef<string>;
	changePrivilege: (privilege: CollaboratorPrivilege) => void;
};

type Ctx = {
	emit: CollaboratorsListEmiters;
};

const privilegeSelectorController: SetupFunction<Props, View> = (props, { emit }: Ctx) => {
	const lang = trans();

	const loading = ref(false);

	const privileges = Object.values(CollaboratorPrivilege).map((privilege: CollaboratorPrivilege) => ({
		id: privilege,
		name: trans().get(`collaboration.privileges.${privilege}`),
	}));

	const currentPrivilege = computed(() => {
		if (props.collaborator.owner) {
			return lang.get('collaboration.privileges.owner');
		}

		return lang.get(`collaboration.privileges.${props.collaborator.privilege}`);
	});

	const { updatePrivilege } = useApi(props.collaborator);

	const changePrivilege = async (privilege: CollaboratorPrivilege) => {
		loading.value = true;
		const updated = await updatePrivilege(privilege);
		emit(CollaboratorsListEvents.Updated, updated.collaborators);
		loading.value = false;
	};

	return {
		lang,
		loading,
		privileges,
		currentPrivilege,
		changePrivilege,
	};
};

export { type Props, type View, privilegeSelectorController };
