<script>
import validatorMixin from '@/lib/components/Fields/validator/mixin.js';
import errorMixin from '@/lib/components/Fields/validator/error';
import { validationRules } from '@/lib/components/Fields/validator';
import { mapMutations } from 'vuex';
import { useTimer } from '@/domain/utils/Timer';
import { emptyFieldService } from '@/modules/entry-form/Collaboration/services/Field.service';
import { collaboratorAccess, defaultCollaboratorAccess } from '@/domain/models/Collaborator';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';

/**
 * The base Field component, all field types should extend it.
 */
export default {
	mixins: [validatorMixin, errorMixin],
	props: {
		field: {
			type: Object,
			required: true,
		},
		fieldService: {
			type: Object,
			default: emptyFieldService,
		},
		value: {
			type: [String, Number, Boolean, Array, Object],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isManager: {
			type: Boolean,
			default: false,
		},
		foreignId: {
			type: Number,
			default: null,
		},
		inActiveTab: {
			type: Boolean,
			default: false,
		},
		elementId: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: null,
		},
		autosaveInterval: {
			type: Number,
			default: 0,
		},
		initService: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			collaborationLocked: false,
			access: defaultCollaboratorAccess,
			...validationRules(this.field.type, this.rules, this.required),
			/**
			 * based on fact that component gets rerendered
			 * when its :key changes this is used to enforce component rerender
			 * used in some complicated fields like callendar / time
			 */
			dKey: true,
		};
	},
	created() {
		this.fieldService.locks.subscribe((lock) => {
			this.collaborationLocked = lock;
		});

		this.access = defaultCollaboratorAccess;

		this.fieldService.myAccess().then((access) => {
			this.access = access;
		});

		collaborationUIBus.on(CollaborationUISignals.UPDATED_COLLABORATORS, (payload) => {
			this.access = collaboratorAccess(payload.myself);
		});

		if (this.initService) {
			this.fieldService.values.init(this.value);
		}

		this.onValue(this.value);
		this.fieldService.values.subscribe(this.onValue);

		if (this.autosaveInterval > 0 && this.fieldService.endpoint) {
			const autosaveTimer = useTimer(() => {
				if (!this.disabledOrCollaborationLocked) {
					this.fieldService.endpoint(this.value);
				}

				return true;
			}, this.autosaveInterval);

			/**
			 * If the field was passed nopn-zero autosave-interval
			 * its value will be autosaved in specified intervals
			 * when user works on it ( i.e. when its lock was grabbed and utill its released )
			 */
			this.fieldService.locks.subscribe((lockState) => {
				autosaveTimer[lockState ? 'start' : 'stop']();
			}, true);
		}
	},
	beforeDestroy() {
		this.fieldService.destroy();
	},
	computed: {
		disabledOrCollaborationLocked() {
			return this.disabled || this.collaborationLocked || !this.access.canEdit;
		},
	},
	watch: {
		value() {
			if (this.disabledOrCollaborationLocked) {
				this.dKey = !this.dKey;
			}
		},
	},
	methods: {
		onValue(value) {
			this.$emit('input', value);
			this.setLastModifiedField(this.field.slug);
		},
		...mapMutations('global', ['setLastModifiedField']),
		// Every time the value changes emit an 'input' event - add support for v-model
		onInput(value, save = false, skipValueUpdate = false) {
			if (value === this.value) {
				return;
			}

			if (!skipValueUpdate && !this.collaborationLocked) {
				this.fieldService.values.set(value, save);
			}

			this.onValue(value);
		},
		getId() {
			return this.id || this.field.slug + '-' + this.elementId;
		},
	},
};
</script>
