import { collaboratorAccess } from '@/domain/models/Collaborator';
import { EntryFormStore } from '@/lib/store/modules/entry-form';
import { featureEnabled } from '@/domain/dao/Features';
import { useEntryFormContainer } from '@/modules/entry-form/EntryFormProvider';
import { collaborationUIBus, CollaborationUISignals } from '@/domain/signals/Collaboration';
import { submittableFormBus, SubmittableFormSignal } from '@/modules/entry-form/Signals';

const getType = (store: EntryFormStore) => (store.state.entryForm.isManager ? 'manager' : 'entrant');

const getSubmittable = (store: EntryFormStore) => store.state.entryForm.entry;

const getForm = (store: EntryFormStore) => store.state.entryForm.form;

const entryIsSaved = (store: EntryFormStore) => getSubmittable(store) !== null;

let blockCollaboration = false;

const isCollaborative = (store: EntryFormStore) => {
	if (getSubmittable(store) === null || blockCollaboration) {
		return false;
	}

	return getForm(store).collaborative && featureEnabled('collaboration');
};

const supportsRealTimeUpdates = (store: EntryFormStore) => isCollaborative(store) || isUpdatable(store);

const isUpdatable = (store: EntryFormStore) => {
	if (getSubmittable(store) === null || blockCollaboration) {
		return false;
	}

	return getForm(store).allowApiUpdates && featureEnabled('api_submission_updates');
};

const entryFormService = (store: EntryFormStore) => {
	collaborationUIBus.on(CollaborationUISignals.UPDATED_COLLABORATORS, (payload) => {
		store.commit('entryForm/setCollaboratorAccess', collaboratorAccess(payload.myself));
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { Consumer } = useEntryFormContainer();
	const consumerSlug = Consumer.consumer?.slug;

	submittableFormBus.on(SubmittableFormSignal.SUBMITTED, (payload) => {
		store.commit('entryForm/setReadOnly', payload.submittedUserSlug !== consumerSlug);
	});

	return {
		getSubmittable: () => getSubmittable(store),
		getForm: () => getForm(store),
		isSaved: () => entryIsSaved(store),
		isCollaborative: () => isCollaborative(store),
		supportsRealTimeUpdates: () => supportsRealTimeUpdates(store),
		isUpdatable: () => isUpdatable(store),
		toggleCollaborative: (state: boolean) => (blockCollaboration = state === false),
		getType: () => getType(store),
		isGrantReport: () => window.location.href.includes('grant-report'),
	};
};

export { entryFormService, getSubmittable, getForm };
