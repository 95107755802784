import { debounce } from 'underscore';
import { getFirebase } from '@/domain/services/Rt/Firebase';
import { ignoreRepeatedCalls } from '@/domain/utils/Cache';
import { isFunction } from '@/domain/utils/TypePredicates';
import { promiseQueue } from '@/domain/utils/Queue';
import { selectiveEncrypter } from '@/domain/services/Encrypter';
import { useRtConfig } from '@/domain/services/Rt/Config';
import { vueData } from '@/domain/services/VueData';
import {
	cacheDataSource,
	Callback,
	Collections,
	getCachedDataSource,
	Setter,
	Value,
} from '@/domain/services/Rt/DataSource';
import { doc, getDoc, onSnapshot, runTransaction, setDoc, Timestamp } from 'firebase/firestore';

const subscriberFactory = <T>() => {
	const callbacks: Callback<T>[] = [];
	let callbackId = 0;

	return {
		subscribe: (callback: Callback<T>, ignoreRepetitions = false) => {
			callbackId++;
			const newId = callbackId;
			callbacks[newId] = ignoreRepetitions ? ignoreRepeatedCalls(callback) : callback;
			return () => {
				delete callbacks[newId];
			};
		},
		callAll: (data: T | null) => {
			Object.values(callbacks).forEach((callback) => callback(data));
		},
		removeAll: () => {
			Object.keys(callbacks).forEach((key) => delete callbacks[parseInt(key)]);
		},
	};
};

type DataMapper<T, R> = {
	extractData?: (data: T | null) => R | null;
	prepareData?: (data: T, updated: R) => T;
	rawProps?: (keyof T)[];
};

const dataSourceFactory = <T, R = unknown>(
	dataSourceId: string,
	collection: Collections,
	documentId: string,
	strategy: DataMapper<T, R> = {},
	nullableRecords = true,
	reinitialize = false
) => {
	if (!reinitialize) {
		const cachedDataSource = getCachedDataSource(dataSourceId);
		if (cachedDataSource) {
			return cachedDataSource;
		}
	}

	const { getFirestore, getEncrypter, isReadonly } = getFirebase();

	const db = getFirestore();
	const docRef = doc(db, `${collection}/${documentId}`);

	const { encrypt, decrypt } = selectiveEncrypter<T>(getEncrypter(), strategy.rawProps || []);

	const extract = (data: T) => (strategy.extractData ? strategy.extractData(data) : data);

	const prepare = (data: T, updated: R) => (strategy.prepareData ? strategy.prepareData(data, updated) : updated);

	const withTTL = (data: any) => ({
		data: data.data,
		expireAt: Timestamp.fromDate(new Date(vueData.variables?.firebaseTTL as Date)),
	});

	const { callAll, removeAll, subscribe } = subscriberFactory<T>();
	const removeListener = onSnapshot(docRef, (docSnap) => {
		if (docSnap.exists()) {
			const snap = docSnap.data()?.data || null;
			const decryptedRecord = snap !== null ? decrypt(snap) : null;
			const extractedData = extract(decryptedRecord as T);

			if (extractedData !== null) {
				callAll(extractedData as T);
				return;
			}
		}

		if (nullableRecords) {
			callAll(null);
		}
	});

	const sendTransaction = (transactionOrData: Setter<T | null> | Value<T | null>) => {
		if (isFunction<Setter<T | null>>(transactionOrData)) {
			return runTransaction(db, async (transaction) => {
				const docSnap = await transaction.get(docRef);
				if (docSnap.exists()) {
					const decryptedRecord = decrypt(docSnap.data()?.data);
					const extractedData = extract(decryptedRecord);
					const updated = transactionOrData(extractedData as T) as R;
					const newData = prepare(decryptedRecord, updated);
					transaction.update(docRef, withTTL({ data: encrypt(newData as T) } as T) || null);
					return;
				}

				const updated = transactionOrData(null) as R;
				const newData = prepare({} as T, updated);
				transaction.set(docRef, withTTL({ data: encrypt(newData as T) } as T) || null);
			});
		}

		setDoc(docRef, withTTL({ data: encrypt(transactionOrData) } as T));
		return Promise.resolve();
	};

	const transactionQueue = promiseQueue<Setter<T | null> | Value<T | null>>(sendTransaction);

	const { firebase } = useRtConfig();

	return cacheDataSource(dataSourceId, {
		id: dataSourceId,
		set: debounce(async (transactionOrData: Setter<T | null> | Value<T | null>, forceSet = false) => {
			if (!forceSet && isReadonly()) {
				return;
			}

			transactionQueue.push(transactionOrData);
		}, firebase?.throttleTime || 400),
		get: async () => {
			const docSnap = await getDoc(docRef);
			const decryptedRecord = decrypt(docSnap.data()?.data);
			const extractedData = extract(decryptedRecord);
			return extractedData;
		},
		subscribe,
		destroy: () => {
			removeListener();
			removeAll();
		},
		remove: () => {},
		isReadonly,
	});
};

export { dataSourceFactory, DataMapper };
