import { bootProfileContainer } from '@/modules/users/Profile/ProfileServiceProvider';
import { mountVueComponent, mountVueWithComponents } from '@/lib/mount-vue-component';

const fileUploadFieldWrapper = require('../fields/components/FileUploadWrapper.vue').default;

var $ = require('jquery');

export default function () {
	bootProfileContainer();

	var darkmode = require('../../common/darkmode.js');
	darkmode.mount();

	$('#toggle-authenticator').on('change', function () {
		$('#authenticator-link').click();
	});

	import('@/lib/components/Fields/validator/Validator.vue').then((validator) => {
		mountVueWithComponents('validator', validator, { fileUploadFieldWrapper });
	});

	import('@/modules/users/components/UserFields.vue').then((userFields) => {
		mountVueComponent('user-fields', userFields);
	});

	import('@/modules/users/Profile/Profile.vue').then((profile) => {
		mountVueComponent('profile', profile);
	});
}
