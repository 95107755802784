import { HexColor } from '@/domain/models/Color';
import { isString } from '@/domain/utils/TypePredicates';
import { chipStyle, faceStyle } from '@/lib/components/User/UserFace.service';
import { ChipStyle, UserAvatarData, UserFaceSize } from '@/lib/components/User/UserFace.types';
import { computed, ComputedRef, SetupFunction } from 'vue';

type Props = {
	user: UserAvatarData;
	color: HexColor | null;
	size: UserFaceSize;
	text: string | null;
};

type View = {
	chipClass: string[];
	chipStyle: ComputedRef<ChipStyle>;
	faceStyle: ComputedRef<ChipStyle>;
	userText: string | null;
	text: string | null;
};

const userFaceController: SetupFunction<Props, View> = (props) => ({
	chipClass: ['user-face', `user-face-${props.size}`],
	chipStyle: computed(() => chipStyle(props.user, props.color)),
	faceStyle: computed(() => faceStyle(props.user, props.color)),
	userText: isString(props.user.img) ? '' : props.user.initials,
	alt: `Profile photo for ${props.user.firstName} ${props.user.lastName}`,
	text: props.text,
});

export { type Props, type View, userFaceController };
