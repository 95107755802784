
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { manageCollaboratorsController } from '@/lib/components/ListActions/ManageCollaborators.controller';
import { Submittable } from '@/domain/models/Submittable';
import { Form } from '@/domain/models/Form';

export default defineComponent({
	props: {
		submittable: {
			type: Object as Submittable,
			required: true,
		},
		form: {
			type: Object as Form,
			required: true,
		},
	},

	setup: useController(manageCollaboratorsController, 'manageCollaboratorsController'),
});
