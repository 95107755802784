import { useEntryFormContainer } from '@/modules/entry-form/EntryFormProvider';
import { emptyApi, useEntryFormApi, useGrantReportApi } from '@/modules/entry-form/Collaboration/services/Api';

const useCollaborativeSubmittable = () => {
	const entryFormContainer = useEntryFormContainer();

	if (!entryFormContainer) {
		return { isCollaborative: false, api: emptyApi };
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { Consumer, Submittable, Collaborators } = useEntryFormContainer();

	if (Submittable === undefined || (!Submittable.isCollaborative() && !Submittable.isUpdatable())) {
		return {
			isCollaborative: false,
			isUpdatable: false,
			api: emptyApi,
			owner: null,
			refreshCollaborators: () => {},
		};
	}

	const api = (Submittable.isGrantReport() ? useGrantReportApi : useEntryFormApi)(
		Submittable.getSubmittable().slug,
		Submittable.getType()
	);

	const owner = Submittable.isCollaborative()
		? Collaborators(Submittable.getForm().slug, Submittable.getSubmittable().slug).owner
		: null;

	const collaborators = Collaborators(Submittable.getForm().slug, Submittable.getSubmittable().slug);

	return {
		consumer: Consumer.consumer,
		submittableSlug: Submittable.getSubmittable().slug,
		formSlug: Submittable.getForm().slug,
		isCollaborative: Submittable.isCollaborative(),
		isUpdatable: Submittable.isUpdatable(),
		api,
		owner,
		refreshCollaborators: collaborators.refreshCollaborators,
	};
};

export { useCollaborativeSubmittable };
