import { bootManageCollaboratorsContainer } from '@/lib/components/ListActions/ManageCollaboratorsProvider';
import MyEntryList from '../entries/components/MyEntryList';
import Vue from 'vue';

export default function () {
	bootManageCollaboratorsContainer();

	const myEntryListDOM = document.getElementById('my-entry-list');

	const store = require('@/lib/store/index').default;
	if (myEntryListDOM) {
		const myEntryList = new Vue({
			el: '#my-entry-list',
			components: {
				'my-entry-list': MyEntryList,
			},
			store,
		});

		$(document).one('pjax:end', () => {
			myEntryList.$destroy();
		});
	}
}
