import allocationPayments from './modules/allocation-payment';
import authentication from './modules/authentication';
import comments from './modules/comments';
import entryForm from './modules/entry-form';
import entryFormApi from './modules/entry-form-api';
import entryFormButtons from './modules/entry-form-buttons';
import entryFormConfiguration from './modules/entry-form-configuration';
import featureIntro from './modules/feature-intro';
import formulaField from './modules/formula-field';
import global from './modules/global';
import judgingFastStart from './modules/judging-fast-start';
import menu from './modules/menu';
import savedView from './modules/saved-view';
import splashWithMenu from '@/lib/store/modules/splash-with-menu';
import tableField from './modules/table-field';
import theme from './modules/theme';
import topPick from './modules/top-pick';
import validation from '@/lib/store/modules/validation';
import Vue from 'vue';
import Vuex from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line import/exports-last
export function getOptions() {
  return {
    modules: {
      allocationPayments,
      comments,
      entryForm,
      entryFormApi,
      entryFormButtons,
      entryFormConfiguration,
      featureIntro,
      global,
      judgingFastStart,
      savedView,
      tableField,
      formulaField,
      topPick,
      menu,
      theme,
      authentication,
      splashWithMenu,
      validation,
		},
		strict: debug,
	};
}

let store = {};

try {
	store.vuex = new Vuex.Store(getOptions());
} catch (e) {
	Vue.use(Vuex);
	store.vuex = new Vuex.Store(getOptions());
}

export default store.vuex;
