type TimerId = ReturnType<typeof setTimeout> | NodeJS.Timeout;

const useTimer = (callback: () => boolean | void = () => false, interval = 100) => {
	let timer: TimerId;

	const stop = () => {
		if (timer) {
			global.clearTimeout(timer);
		}
	};

	const start = (int = 0) => {
		stop();
		timer = global.setTimeout(() => {
			if (callback()) {
				start(int || interval);
			}
		}, Math.ceil(int || interval));
	};

	return {
		stop,
		start,
	};
};

const getCurrentTimestamp = () => new Date().getTime();

export { useTimer, getCurrentTimestamp };
