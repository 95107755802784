import { Collaborator } from '@/domain/models/Collaborator';
import { useApi } from '@/lib/components/Collaboration/Reinvite.api';
import { Ref, ref } from 'vue';
import { trans, Trans } from '@/domain/dao/Translations';

type Props = {
	collaborator: Collaborator;
};

type View = {
	lang: Trans;
	reinvite: () => void;
	loading: Ref<boolean>;
	visible: Ref<boolean>;
};

const reinviteController = (props: Props): View => {
	const { reinviteUser } = useApi(props.collaborator);

	const visible = ref(true);
	const loading = ref(false);

	const reinvite = async () => {
		loading.value = true;
		await reinviteUser();
		visible.value = false;
	};

	return {
		lang: trans(),
		reinvite,
		loading,
		visible,
	};
};

export { reinviteController, Props, View };
