<template>
	<div :class="{ 'form-inline': includeTimezone }">
		<datepicker
			:id="id"
			:name="name"
			:value="datetime"
			:mode="mode"
			:highlight-today="preselectCurrentDate"
			:use-am-pm="useAmPm"
			:months="months"
			:months-short="monthsShort"
			:days-of-week="daysOfWeek"
			:timezones="timezones"
			:moment-locale="momentLocale"
			:moment-date-format="momentDateFormat"
			:moment-time-format="momentTimeFormat"
			:translations="translations"
			class="date-datepicker"
			:class="{ 'right-aligned': rightAligned }"
			:disabled="disabled"
			@changed="dateOrTimeChanged"
			v-on="$listeners"
			@blur.stop=""
		/>
		<localised-timezone
			v-if="includeTimezone"
			:aria-labelledby="labelId ? labelId : `${id}-label`"
			:timezones="timezones"
			:value.sync="timezone"
			:disabled="disabled"
			:unlockSignal="unlockSignal"
		/>
		<input v-if="hiddenFields" type="hidden" :name="id + 'Date'" :value="currentDate" />
		<input v-if="hiddenFields" type="hidden" :name="id + 'Timezone'" :value="currentTimezone" />
	</div>
</template>

<script>
import { Datepicker } from 'vue-bootstrap';
import { mapState } from 'vuex';
import moment from 'moment';
import LocalisedTimezone from './LocalisedTimezone.vue';

export default {
	components: {
		Datepicker,
		LocalisedTimezone,
	},
	inject: ['lang'],
	props: {
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			default: '',
		},
		labelId: {
			type: String,
			default: null,
		},
		includeTimezone: {
			type: Boolean,
			required: false,
		},
		preselectCurrentDate: {
			type: Boolean,
			default: false,
		},
		defaultTimezone: {
			type: String,
			default: null,
		},
		mode: {
			type: String,
			required: true,
			validator: (prop) => ['date', 'datetime', 'time'].indexOf(prop) !== -1,
		},
		value: {
			type: [String, Object],
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hiddenFields: {
			type: Boolean,
			default: false,
		},
		rightAligned: {
			type: Boolean,
			default: false,
		},
		unlockSignal: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			currentDate: null,
			currentTimezone: null,
		};
	},
	computed: {
		datetime: {
			get() {
				if (!this.value || this.mode === 'time') {
					return this.value;
				}

				return this.value.date || this.value.datetime;
			},
			set(newDate) {
				if (!newDate || this.mode === 'time') {
					this.currentDate = newDate;
					this.$emit('update:value', newDate);
				} else {
					let dateTimezone = Object.assign({}, this.value);

					// Setup dateTimeZone object in case of empty value
					if (!dateTimezone) {
						dateTimezone = {};
						dateTimezone[this.mode] = newDate;
						if (this.includeTimezone) {
							dateTimezone['timezone'] = '';
						}
					} else {
						dateTimezone[this.mode === 'date' ? 'date' : 'datetime'] = newDate;
					}

					this.currentDate = newDate;
					this.$emit('update:value', dateTimezone);
				}
			},
		},
		timezone: {
			get() {
				return this.value && this.value.timezone ? this.value.timezone : this.defaultTimezone;
			},
			set(newTimezone) {
				let dateTimezone = Object.assign({}, this.value);

				if (!dateTimezone) {
					dateTimezone = {};
					dateTimezone[this.mode] = '';
				}

				dateTimezone['timezone'] = newTimezone;

				this.currentTimezone = newTimezone;
				this.$emit('update:value', dateTimezone);
			},
		},
		months() {
			const january = moment().locale(this.momentLocale).month('January');
			return new Array(12).fill(0, 0, 12).map((item, i) => this.capitalize(january.month(i).format('MMMM')));
		},
		monthsShort() {
			const january = moment().locale(this.momentLocale).month('January');
			return new Array(12).fill(0, 0, 12).map((item, i) => this.capitalize(january.month(i).format('MMM')));
		},
		daysOfWeek() {
			const firstDay = moment().locale(this.momentLocale).day('Sunday');
			return new Array(7).fill(0, 0, 7).map((item, i) => firstDay.day(i).format('dd'));
		},
		useAmPm() {
			return this.momentTimeFormat.indexOf('A') !== -1;
		},
		translations() {
			const keys = [
				'today',
				'clear',
				'close',
				'select_month',
				'prev_month',
				'next_month',
				'select_year',
				'prev_year',
				'next_year',
				'select_decade',
				'prev_decade',
				'next_decade',
				'prev_century',
				'next_century',
				'pick_hour',
				'increment_hour',
				'decrement_hour',
				'pick_minute',
				'increment_minute',
				'decrement_minute',
				'pick_second',
				'increment_second',
				'decrement_second',
				'toggle_period',
				'select_time',
			];

			const translations = {};
			for (let index in keys) {
				const key = keys[index];
				translations[key] = this.lang.get('miscellaneous.datepicker.tooltips.' + key);
			}

			translations['am'] = this.amTranslation();
			translations['pm'] = this.pmTranslation();

			return translations;
		},
		includesDate() {
			return ['date', 'datetime'].indexOf(this.mode) !== -1;
		},
		includesTime() {
			return ['datetime', 'time'].indexOf(this.mode) !== -1;
		},
		...mapState('global', ['momentLocale', 'momentDateFormat', 'momentTimeFormat', 'timezones']),
	},
	created() {
		this.currentDate = this.datetime;
		this.currentTimezone = this.timezone;
	},
	methods: {
		dateOrTimeChanged(event) {
			if (this.datetime === event) {
				return;
			}

			this.datetime = event;
			this.$emit('changed', event);
		},
		capitalize(s) {
			return s && s[0].toUpperCase() + s.slice(1);
		},
		amTranslation() {
			const date = moment('06:00', 'HH:mm').locale(this.momentLocale);
			return date.format('hh:mm A').replace(date.format('hh:mm '), '').trim();
		},
		pmTranslation() {
			const date = moment('18:00', 'HH:mm').locale(this.momentLocale);
			return date.format('hh:mm A').replace(date.format('hh:mm '), '').trim();
		},
	},
};
</script>

<style>
div:not(.form-inline) > .date-datepicker {
	width: 100%;
}

.date-datepicker {
	display: inline-block;
	.separator {
		display: none;
	}
	.clock {
		height: 230px;
	}
}

.form-inline .date-datepicker,
.form-inline .form-control.date,
.form-inline .form-control.datetime,
.form-inline .form-control.timezones {
	width: 45%;
}

.date-datepicker .form-control,
.attachments .vue-datetimepicker-widget {
	width: 100%;
}

.date-datepicker.right-aligned .datepicker {
	left: auto;
	right: 0;
}
</style>
