import actions from './actions';
import { Entry } from '@/domain/models/Entry';
import { Form } from '@/domain/models/Form';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const vuexStore = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

type State = {
	entryForm: {
		entry: Entry;
		form: Form;
		isManager: boolean;
	};
};

/* eslint-disable @typescript-eslint/naming-convention */
type Getters = any;
type Mutations = any;
type Actions = any;
/* eslint-enable @typescript-eslint/naming-convention */

type EntryFormStore = {
	state: State;
	getters: Getters;
	commit: Mutations;
	dispatch: Actions;
};

export { vuexStore as default, EntryFormStore, State };
