import { isChanged } from '@/domain/utils/Cache';
import { UpdateFieldEndpoint } from '@/modules/entry-form/Collaboration/services/Api';
import { valueServiceFactory } from '@/domain/services/Collaboration/MutableValueService';
import { Callback, Setter, Value } from '@/domain/services/Rt/DataSource';
import { emptyDocumentService, nullDocumentService } from '@/domain/services/Collaboration/Document';
import { emptyLockableService, lockableServiceFactory } from '@/domain/services/Collaboration/Lockable';

type SignedData<T> = {
	value: T;
	sender?: string;
};

const signature =
	<T>(senderSlug?: string) =>
	(value: T): SignedData<T> => ({ value, sender: senderSlug });

type Subscriber<T> = (value: T | null) => void;

type DataService<T> = {
	set:
		| ((data: Setter<T | null> | Value<T | null>, save: boolean) => void)
		| ((data: Setter<T | null> | Value<T | null>) => void);
	init: (data: Setter<T | null> | Value<T | null>) => void;
	subscribe: (callback: Callback<unknown>) => void;
	destroy: () => void;
};

const muteFrom = <T>(service: DataService<T>, senderSlug?: string) => ({
	...service,
	subscribe: (subscriber: Subscriber<T>) =>
		service.subscribe((data: unknown) => {
			if ((data as SignedData<T>)?.sender !== senderSlug) {
				subscriber((data as SignedData<T>)?.value ?? null);
			}
		}),
});

/**
 * MutableService is composed from LockableService and ValuesService
 * it is used to manage locks and values of lockables
 *
 * By passing an oprional endpoint parameter we enable Mutables
 * to save themselves to the server on change or on unlock
 */
const mutableServiceFactory =
	<T>(submittableSlug?: string, formSlug?: string) =>
	(key: string, endpoint: UpdateFieldEndpoint<T> | null = null, dataMutator = (data: unknown) => data) => {
		const locks = lockableServiceFactory(submittableSlug, formSlug)(key);
		const values = valueServiceFactory(submittableSlug, formSlug)(key);

		let value: T;

		const alreadySent = isChanged<T>();

		const setValue = values.set;

		const setValueWithMutator = (value: unknown) => setValue(dataMutator(value));

		/**
		 * If endpoint is provided, we setup service proxies here
		 *
		 * values.set()
		 * will send the value to the endpoint on value update
		 * it is used on non-locking fields like checkboxes, radio buttons, etc.
		 *
		 * locks.set()
		 * will send the value to the endpoint on lock release
		 * */
		if (endpoint !== null && endpoint !== undefined) {
			const setLock = locks.set;

			values.set = (newValue: unknown, save = false) => {
				if (save === true && !alreadySent(newValue as T)) {
					endpoint(newValue as T).then((response) => {
						setValueWithMutator(response.value);
						value = response.value;
					});
					return;
				}

				value = newValue as T;
				setValueWithMutator(newValue);
			};

			/**
			 * saveAs is used to save the value as something else than the current value
			 * it is used on complex fields that we need extra data to be
			 * passed around but not saved.
			 *
			 * uploader is an example here where we want to propagate an actual image
			 * but save only the file identifier
			 */
			locks.set = (lock: boolean, ignoreActivity = false, saveAs?: T) => {
				if (lock === false && !alreadySent(value)) {
					endpoint(saveAs === undefined ? value : saveAs).then((response) => {
						setValueWithMutator(saveAs !== undefined ? value : response.value);
						setLock(lock, ignoreActivity);
					});
					return;
				}

				setLock(lock, ignoreActivity);
			};
		} else {
			values.set = (newValue: unknown) => {
				setValueWithMutator(newValue);
			};
		}

		values.init = setValueWithMutator;

		const destroy = () => {
			locks.destroy();
			values.destroy();
		};

		return {
			locks,
			values,
			endpoint,
			destroy,
		};
	};

const emptyMutableServiceFactory = (looped = true) => ({
	locks: emptyLockableService(),
	values: looped ? emptyDocumentService() : nullDocumentService(),
	endpoint: null,
	destroy: () => {},
});

export { emptyMutableServiceFactory, mutableServiceFactory, signature, SignedData, muteFrom };
